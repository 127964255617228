/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ErrorDialogService } from '../../modules/shared/errors/error-dialog.service';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private errorDialogService: ErrorDialogService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Error from error interceptor', error);
        console.error(' ---> ', error.status);
        // if (error.name !== 'HttpErrorResponse') {
        //   console.error(' ---> ', error.message);
        //   // if this is the header error response don't show the dialog
        //   // so that we can show the actuall error.
        //   throw new Error(error);
        // }

        switch (error.status) {
        case 404:
        case 401:
        case 400:
          this.errorDialogService.openDialog(error.error, error.status);
          break;

        default:
          this.errorDialogService.openDialog(error.message ? error.message : JSON.stringify(error), error.status);
          break;
        }
        throw new Error(error.message);
      }),
      finalize(() => {
        // Remove this commented out code.
      })
    );
  }
}
