import { Component } from '@angular/core';

@Component({
  selector: 'app-current-year-widget',
  templateUrl: './current-year-widget.component.html'
  })
export class CurrentYearWidgetComponent {
  currentYear: number;
  isLoggedIn: boolean;
  showPanel: boolean;

  constructor(
  ) {
    this.currentYear = 0;
    this.showPanel = false;
  }
}
