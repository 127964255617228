import { Component, OnInit } from '@angular/core';
import { MenuItem } from '../../../models/menuItem';
import { Router } from '@angular/router';
import { roles } from 'src/app/features/security/roles';
import { UserAccountService } from 'src/app/core/services/user-account.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  })
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  isLoggedIn = false;
  menuItems: MenuItem[];

  constructor(
    private userAccountService: UserAccountService,
    private router: Router) { }

  ngOnInit(): void {
    this.isLoggedIn = this.userAccountService.isLoggedIn();
    this.menuItems = this.getMenuItems();

    this.userAccountService.subscribeToLogin(() => {
      this.isLoggedIn = this.userAccountService.isLoggedIn();
      this.menuItems = this.getMenuItems();
    });
  }

  getMenuItems(): MenuItem[] {
    return this.router.config
      .filter(route => {
        let hasAccess = false;
        if (route.data && route.data.includeInMenu) {
          if (this.userAccountService.isLoggedIn() && route.data.expectedRoles) {

            route.data.expectedRoles.forEach((expectedRole: string) => {
              hasAccess = this.userAccountService.isAccountInGroup(expectedRole) || hasAccess;
            });

            if (this.userAccountService.isAccountInGroup(roles.superUser)) {
              console.debug('User has role. Allowing Menu');
              hasAccess = true;
            }
          }
          if (!route.data.expectedRoles) {
            hasAccess = true;
          }
        }
        return hasAccess;
      })
      .map(route => {
        return {
          path: route.path,
          title: route.data.title,
          icon: route.data.icon,
          role: route.data.expectedRole,
          sortOrder: route.data.sortOrder,
        };
      })
      .sort((a, b) => a.sortOrder < b.sortOrder ? -1 : a.sortOrder > b.sortOrder ? 1 : 0);
  }

  /**
   * Hide the nav menu. (on click)
   */
  hideMenu():void {
    if(screen.width < 1024){ //At tailwind lg breakpoint.
      const sidebar = document.getElementById('sidebar');
      sidebar.classList.toggle('lg:ml-0');
      sidebar.classList.toggle('-ml-72');
    }
  }
}
