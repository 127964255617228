<p-dialog [(visible)]="showModal" [focusOnShow]="true" showEffect="fade"
    [modal]="true" [style]="{ width: '75vw' }"
    styleClass="bg-jw-gray-100 text-jw-gray-900 dark:bg-jw-gray-800 dark:text-white max-w-6xl">
    <ng-template pTemplate="header">
        <h2 class="mb-2 text-lg font-bold tracking-tight text-jw-gray-900 dark:text-white">Add Volunteer Grading Record</h2>
    </ng-template>
    <div *ngIf="newGrade">
        <div class="p-2 mb-6 block w-full prose lg:prose-xl dark:prose-invert dark:lg:prose-xl-invert">
            <p>
                As you consider what grade should be assigned and any additional comments you may wish to share,
                please think about the answers to the following questions:
            </p>
            <ul>
                <li>Is the brother... Balanced and reasonable? Organized? Dependable?</li>
                <li>Is he known as being kind, patient, and loving in his dealings with others?</li>
                <li>Was there any need to provide the brother counsel, and if so, did he accept the counsel?
                </li>
                <li>Would you recommend the brother be used again?
                    <ul>
                        <li>If so, in what capacity would you recommend he be used?</li>
                        <li>If not, why would you recommend he not be used, was this communicated to the
                            brother, and how did he accept the counsel?</li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="mb-6">
            <label for="grade" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Grade:</label>
            <p-dropdown [options]="gradeList" [(ngModel)]="selectedGrade" aria-placeholder="Select a grade"
                inputStyleClass="py-2" styleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg 
                focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-jw-gray-700 
                dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white 
                dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select a grade" [required]="true" aria-required></p-dropdown>
        </div>
        <div class="mb-6">
            <label for="recommendation" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Recommended:</label>
            <p-dropdown [options]="recommendationList" [(ngModel)]="selectedRecommendation" aria-placeholder="Select a recommendation"
                inputStyleClass="py-2" styleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg 
                focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-jw-gray-700 
                dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white 
                dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select a recommendation" [required]="true" aria-required></p-dropdown>
        </div>
        <div class="mb-6">
            <label for="lastName" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Comments:</label>
            <p-editor [(ngModel)]="gradeNotes" [style]="{'height':'320px'}"
                styleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg 
                            focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-jw-gray-700 
                            dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white 
                            dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <p-header>
                    <span class="ql-formats">
                        <button class="ql-bold" aria-label="Bold"></button>
                        <button class="ql-italic" aria-label="Italic"></button>
                        <button class="ql-underline" aria-label="Underline"></button>
                    </span>
                    <span class="ql-formats">
                        <button class="ql-list" value="ordered" aria-label="list"></button>
                        <button class="ql-list" value="bullet" aria-label="bullet"></button>
                    </span>
                </p-header>
            </p-editor>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <app-button appPreventDoubleClick [disabled]="saveButtonClicked" [type]="buttonTypes.Save" label="Save Grade" (throttledClick)="save()" [throttleTime]="2000"></app-button>
        <app-button [type]="buttonTypes.Cancel" label="Cancel" (click)="cancel()"></app-button>
    </ng-template>
</p-dialog>