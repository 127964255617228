import { AfterContentInit, Component, ContentChildren, Directive, Input } from '@angular/core';

@Directive({selector: 'child-directive'})
class ChildDirective {
}

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
  })
export class HeadingComponent implements AfterContentInit {
  @Input() headingLevel = 'h1';
  @Input() cssClasses = '';
  @Input() label = '';

  innerHtml: string;
  mergedCss = '';

  constructor() {
    switch(this.headingLevel) {
    case 'h1':
      this.mergedCss += 'text-2xl font-bold mt-2 mb-3';
      break;
    case 'h2':
      this.mergedCss += 'text-xl font-bold my-2';
      break;
    case 'h3':
      this.mergedCss += 'text-xl font-bold my-1';
      break;
    case 'h4':
      this.mergedCss += 'text-lg font-bold my-1';
      break;
    case 'h5':
      this.mergedCss += 'text-lg font-bold';
      break;
    case 'h6':
      this.mergedCss += 'text-base font-bold';
      break;
    }
  }

  ngAfterContentInit(): void {
    if (this.cssClasses.length > 0 ){
      this.mergedCss = this.mergedCss + ' ' + this.cssClasses;
    }

    this.innerHtml = `<${this.headingLevel} class="${this.mergedCss}">${this.label}</${this.headingLevel}>`;
  }
}
