import { Pipe, PipeTransform } from '@angular/core';
import { WeekendRoleAssignment } from '../../../models/weekendRoleAssignment';

@Pipe({
  name: 'assignment'
})
export class AssignmentPipe implements PipeTransform {

  transform(wra: WeekendRoleAssignment): string {
    const initialGradeJobId = 20;
    let result = '';
    if (wra.conventionJob && wra.conventionJob !== null) {
      result = wra.conventionJob.jobTitle;
    }
    // For initial grades, we only need to show the job role title
    if (wra.conventionJob.conventionJobId !== initialGradeJobId) {
      if (wra.conventionJobRole && wra.conventionJobRole !== null) {
        result += ` ${wra.conventionJobRole.roleTitle}`;
      }
      if (wra.conventionJobSubRole && wra.conventionJobSubRole !== null) {
        result += ` ${wra.conventionJobSubRole.subRoleTitle}`;
      }
    }

    return result.trim();
  }
}
