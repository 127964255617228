/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

export abstract class BaseApiService {

  public myAppUrl: string;
  public myApiUrl: string;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(
    protected http: HttpClient,
    protected authService: MsalService,
    public apiVersion: string,
    public apiEndpoint: string,
    @Inject('BASE_URL') baseUrl: string
  )
  {
    this.myApiUrl = `/api/${apiVersion}/${apiEndpoint}/`;
  }

  protected get<T>(apiCall: string) : Promise<T> {
    const promise = this.http.get<T>(this.myApiUrl + apiCall);
    return lastValueFrom(promise);
  }

  protected put<T>(apiCall: string, body: any) : Promise<T> {
    const promise = this.http.put<T>(this.myApiUrl + apiCall, body);
    return lastValueFrom(promise);
  }

  protected post<T>(apiCall: string, body: any) : Promise<T> {
    const promise = this.http.post<T>(this.myApiUrl + apiCall, body);
    return lastValueFrom(promise);
  }

  protected delete<T>(apiCall: string) : Promise<T> {
    const promise = this.http.delete<T>(this.myApiUrl + apiCall);
    return lastValueFrom(promise);
  }
}
