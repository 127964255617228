import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { paths } from './congregation-paths';

import { roles } from '../../features/security/roles';
import { MsalGuardGroupService } from 'src/app/core/guards/msal-guard-group.service';

// Page Components
import { PageCircuitsComponent } from './pages/page-circuits/page-circuits.component';
import { PageCongregationsComponent } from './pages/page-congregations/page-congregations.component';
import { PageVolunteersComponent } from './pages/page-volunteers/page-volunteers.component';
import { PageAddCongregationsComponent } from './pages/page-add-congregations-component/page-add-congregations-component.component';
import { PageAddVolunteerComponent } from './pages/page-add-volunteer/page-add-volunteer.component';

const routes: Routes = [
  {
    path: paths.circuits,
    component: PageCircuitsComponent,
    data: {
      title: 'Circuits',
      icon: 'home-city-outline',
      sortOrder: 300,
      expectedRoles: [roles.superUser],
      includeInMenu: true,
    },
    canActivate: [MsalGuardGroupService],
  },
  {
    path: paths.congregation,
    component: PageCongregationsComponent,
    data: {
      title: 'Congregations',
      icon: 'account-group-outline',
      sortOrder: 400,
      expectedRoles: [
        roles.technicalContact,
        roles.committeeMember,
        roles.committeeAssistant,
      ],
      includeInMenu: true,
    },
    canActivate: [MsalGuardGroupService],
  },
  {
    path: paths.addCongregation,
    component: PageAddCongregationsComponent,
    data: {
      title: 'Add Congregation',
      icon: '',
      sortOrder: 400,
      expectedRoles: [
        roles.technicalContact,
        roles.committeeMember,
        roles.committeeAssistant,
      ],
      includeInMenu: false,
    },
    canActivate: [MsalGuardGroupService],
  },
  {
    path: paths.editCongregation,
    component: PageAddCongregationsComponent,
    data: {
      title: 'Edit Congregation',
      icon: '',
      sortOrder: 400,
      expectedRoles: [
        roles.technicalContact,
        roles.committeeMember,
        roles.committeeAssistant,
      ],
      includeInMenu: false,
    },
    canActivate: [MsalGuardGroupService],
  },
  {
    path: paths.volunteers,
    component: PageVolunteersComponent,
    data: {
      title: 'Congregation Volunteers',
      icon: '',
      sortOrder: 500,
      expectedRoles: [
        roles.technicalContact,
        roles.committeeMember,
        roles.committeeAssistant,
      ],
      includeInMenu: false,
    },
    canActivate: [MsalGuardGroupService],
  },
  {
    path: paths.addVolunteer,
    component: PageAddVolunteerComponent,
    data: {
      title: 'Add Congregation Volunteer',
      icon: '',
      sortOrder: 500,
      expectedRoles: [
        roles.technicalContact,
        roles.committeeMember,
        roles.committeeAssistant,
      ],
      includeInMenu: false,
    },
    canActivate: [MsalGuardGroupService],
  },
  {
    path: paths.editVolunteer,
    component: PageAddVolunteerComponent,
    data: {
      title: 'Edit Congregation Volunteer',
      icon: '',
      sortOrder: 500,
      expectedRoles: [
        roles.technicalContact,
        roles.committeeMember,
        roles.committeeAssistant,
      ],
      includeInMenu: false,
    },
    canActivate: [MsalGuardGroupService],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CongregationsRoutingModule {}
