import { Component, OnInit } from '@angular/core';
import { Weekend } from 'src/app/models/weekend';
import { WeekendsService } from '../services/weekends.service';
import { SelectItem } from 'primeng/api';
import { Languages } from 'src/app/modules/shared/languages';
import { ConventionYear } from 'src/app/models/conventionYear';
import { Facility } from 'src/app/models/facility';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonType } from '../../../features/components/button/buttonType';
import { ConventionYearService } from '../../../core/services/convention-year.service';
import { WeekendType } from 'src/app/models/weekendType';
import { WeekendTypes } from '../../shared/weekendTypes';
import posthog from 'posthog-js';

@Component({
  selector: "app-add-weekend-modal",
  templateUrl: "./add-weekend-modal.component.html",
  styleUrls: ["./add-weekend-modal.component.scss"],
  })
export class AddWeekendModalComponent implements OnInit {
  facility: Facility;
  newWeekend: Weekend;
  languages: SelectItem[];
  currentYear: number;
  weekendTypes: SelectItem[] = [];

  buttonTypes = ButtonType;

  constructor(
    private weekendsService: WeekendsService,
    private conventionYearService: ConventionYearService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.conventionYearService.getCurrent().subscribe((result) => {
      this.facility = this.config.data.facility;
      console.debug(`Facility Id: ${this.facility.facilityId}`);
      this.languages = Languages.toListValue();
      this.weekendTypes = WeekendTypes.toListValue();
      if (this.config.data.weekend) {
        this.newWeekend = this.config.data.weekend;
      } else {
        this.newWeekend = new Weekend();
        this.newWeekend.conventionYear = new ConventionYear();
        this.newWeekend.conventionYear.year = result.year;
        this.newWeekend.conventionYear.status = 1;
        this.newWeekend.facilityId = this.facility.facilityId;
        this.newWeekend.weekendType = new WeekendType();
      }
    });
  }

  isFeatureEnabled(flagName: string) {
    return posthog.isFeatureEnabled(flagName);
  }

  save(): void {
    if (this.newWeekend.weekendId > 0) {
      this.weekendsService.updateWeekend(this.newWeekend).then(() => {
        this.ref.close(true);
      });
    } else {
      this.weekendsService.createWeekend(this.newWeekend).then(() => {
        this.ref.close(true);
      });
    }
  }

  cancel(): void {
    this.ref.close(false);
  }
}
