import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from 'src/app/core/services/base-api.service';
import { MsalService } from '@azure/msal-angular';
import { ConventionJob } from '../../models/conventionJob';
import { ConventionJobRole } from '../../models/conventionJobRole';
import { ConventionJobSubRole } from '../../models/conventionJobSubRole';
import { CongregationResponsibility } from '../../models/congregationResponsibility';

@Injectable({
  providedIn: 'root'
  })
export class LookupsService
  extends BaseApiService {

  private jobs: ConventionJob[] = [];
  private roles: ConventionJobRole[] = [];
  private subroles: ConventionJobSubRole[] = [];
  private responsibilities: CongregationResponsibility[] = [];

  constructor(http: HttpClient, authService: MsalService, @Inject('BASE_URL') baseUrl: string) {
    super(http, authService, 'beta', 'lookups', baseUrl);
  }

  async getConventionJobs(): Promise<ConventionJob[]> {
    if (this.jobs.length === 0){
      this.jobs = await this.get<ConventionJob[]>('jobs');
    }
    return this.jobs;
  }

  async getConventionJobRoles(): Promise<ConventionJobRole[]> {
    if (this.roles.length === 0) {
      this.roles = await this.get<ConventionJobRole[]>('job-roles');
    }
    return this.roles;
  }

  async getConventionJobSubRoles(): Promise<ConventionJobSubRole[]> {
    if (this.subroles.length === 0) {
      this.subroles = await this.get<ConventionJobSubRole[]>('job-sub-roles');
    }
    return this.subroles;
  }

  async getCongregationResponsibilities(): Promise<CongregationResponsibility[]> {
    if (this.responsibilities.length === 0) {
      this.responsibilities = await this.get<CongregationResponsibility[]>('responsibilities');
    }
    return this.responsibilities;
  }
}
