/* eslint-disable @typescript-eslint/no-explicit-any */
import { Input, OnInit, Directive, ViewContainerRef, TemplateRef } from '@angular/core';
import { UserAccountService } from './core/services/user-account.service';
import { SecurityRole, getSecurityRole } from './features/security/securityRole';

@Directive({
  selector: '[ifRoles]'
  })
export class IfRolesDirective implements OnInit {
  // the role the user must have
  @Input() public ifRoles: Array<string>;

  /**
   * @param {ViewContainerRef} viewContainerRef -- the location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef -- the templateRef to be potentially rendered
   * @param {RolesService} rolesService -- will give us access to the roles a user has
   */
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private userAccountService: UserAccountService
  ) {
  }

  public ngOnInit(): void {
    console.debug('ifRoles ngOnInit');

    this.ifRoles.map((roleName) => {
      console.debug(`ifRoles::checking Role Name[${roleName}]`);
      const role = getSecurityRole(roleName);
      console.debug(`ifRoles::Role name mapped to [${role}]`);
      if (this.userAccountService.isAccountInGroup(SecurityRole.SuperUser) ||
          this.userAccountService.isAccountInGroup(role)) {
        console.debug('ifRoles::Enabling Element');
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        console.debug('ifRoles::Hiding Element');
        this.viewContainerRef.clear();
      }
    });
  }
}