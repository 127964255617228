<nav class="text-semibold text-jw-gray-900 dark:text-white bg-jw-gray-100 dark:bg-jw-gray-900">
    <div class="flex flex-wrap justify-between items-center mx-auto">
        <div
            class="flex items-start content-start bg-cpt-green-500 dark:bg-cpt-purple-500 w-auto lg:w-72 px-2 sm:px-4 py-2.5">

            <button data-collapse-toggle="mobile-menu-2" type="button" (click)="toggle()"
                class="inline-flex items-center p-2 text-sm text-jw-orange-500 rounded-lg hover:bg-jw-orange-100 dark:text-jw-orange-400 dark:hover:bg-jw-orange-700"
                aria-controls="mobile-menu-2" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <svg id="menuClosed" class="w-6 h-6" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" class="fill-white"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clip-rule="evenodd"></path>
                </svg>
            </button>
            <span class="self-center whitespace-nowrap text-white hidden lg:block">Menu</span>
        </div>
        <div class="flex items-center content-start sm:order-3 px-2 sm:px-4 py-2.5">
            <div class="relative mr-3">
                <label class="sr-only" id="userThemeLabel">Theme</label>
                <button type="button"
                    class="rounded py-1 px-2 flex items-center text-sm bg-jw-gray-100 dark:bg-jw-gray-900 focus:ring-1 focus:ring-jw-gray-300 dark:focus:ring-jw-gray-600"
                    id="userThemeButton" data-dropdown-toggle="dropdownTheme" (click)="toggleThemeMenu()"
                    (clickOutside)="closeThemeMenu()">
                    <span class="dark:hidden">
                        <svg viewBox="0 0 24 24" fill="black" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="w-6 h-6">
                            <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" class="fill-slate-400/20 stroke-slate-500">
                            </path>
                            <path
                                d="M12 4v1M17.66 6.344l-.828.828M20.005 12.004h-1M17.66 17.664l-.828-.828M12 20.01V19M6.34 17.664l.835-.836M3.995 12.004h1.01M6 6l.835.836"
                                class="stroke-slate-500"></path>
                        </svg>
                    </span>
                    <span class="hidden dark:inline">
                        <svg viewBox="0 0 24 24" fill="none" class="w-6 h-6">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M17.715 15.15A6.5 6.5 0 0 1 9 6.035C6.106 6.922 4 9.645 4 12.867c0 3.94 3.153 7.136 7.042 7.136 3.101 0 5.734-2.032 6.673-4.853Z"
                                class="fill-slate-400/20"></path>
                            <path
                                d="m17.715 15.15.95.316a1 1 0 0 0-1.445-1.185l.495.869ZM9 6.035l.846.534a1 1 0 0 0-1.14-1.49L9 6.035Zm8.221 8.246a5.47 5.47 0 0 1-2.72.718v2a7.47 7.47 0 0 0 3.71-.98l-.99-1.738Zm-2.72.718A5.5 5.5 0 0 1 9 9.5H7a7.5 7.5 0 0 0 7.5 7.5v-2ZM9 9.5c0-1.079.31-2.082.845-2.93L8.153 5.5A7.47 7.47 0 0 0 7 9.5h2Zm-4 3.368C5 10.089 6.815 7.75 9.292 6.99L8.706 5.08C5.397 6.094 3 9.201 3 12.867h2Zm6.042 6.136C7.718 19.003 5 16.268 5 12.867H3c0 4.48 3.588 8.136 8.042 8.136v-2Zm5.725-4.17c-.81 2.433-3.074 4.17-5.725 4.17v2c3.552 0 6.553-2.327 7.622-5.537l-1.897-.632Z"
                                class="fill-slate-500"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M17 3a1 1 0 0 1 1 1 2 2 0 0 0 2 2 1 1 0 1 1 0 2 2 2 0 0 0-2 2 1 1 0 1 1-2 0 2 2 0 0 0-2-2 1 1 0 1 1 0-2 2 2 0 0 0 2-2 1 1 0 0 1 1-1Z"
                                class="fill-slate-500"></path>
                        </svg>
                    </span>
                </button>
                <div id="dropdownTheme"
                    class="hidden absolute right-0 z-10 w-44 mt-1 rounded bg-jw-gray-100 dark:bg-jw-gray-900 ">
                    <ul aria-labelledby="userThemeButton" class="text-jw-gray-900 dark:text-jw-gray-400">
                        <li class="flex items-center cursor-pointer py-2 px-4 text-sm hover:bg-jw-blue-500 hover:text-jw-gray-50"
                            id="userThemeOptionsLight" role="option" tabindex="-1" (click)="setUserTheme('light')">
                            <svg viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="w-6 h-6 mr-4">
                                <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                    class="fill-slate-500/20 stroke-slate-500 set-stroke"></path>
                                <path
                                    d="M12 4v1M17.66 6.344l-.828.828M20.005 12.004h-1M17.66 17.664l-.828-.828M12 20.01V19M6.34 17.664l.835-.836M3.995 12.004h1.01M6 6l.835.836"
                                    class="stroke-slate-500 set-stroke"></path>
                            </svg>Light
                        </li>
                        <li class="flex items-center cursor-pointer py-2 px-4 text-sm hover:bg-jw-blue-500 hover:text-jw-gray-50"
                            id="userThemeOptionsDark" role="option" tabindex="-1" (click)="setUserTheme('dark')">
                            <svg viewBox="0 0 24 24" fill="none" class="w-6 h-6 mr-4">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M17.715 15.15A6.5 6.5 0 0 1 9 6.035C6.106 6.922 4 9.645 4 12.867c0 3.94 3.153 7.136 7.042 7.136 3.101 0 5.734-2.032 6.673-4.853Z"
                                    class="fill-slate-500/20 stroke-slate-500 set-stroke"></path>
                                <path
                                    d="m17.715 15.15.95.316a1 1 0 0 0-1.445-1.185l.495.869ZM9 6.035l.846.534a1 1 0 0 0-1.14-1.49L9 6.035Zm8.221 8.246a5.47 5.47 0 0 1-2.72.718v2a7.47 7.47 0 0 0 3.71-.98l-.99-1.738Zm-2.72.718A5.5 5.5 0 0 1 9 9.5H7a7.5 7.5 0 0 0 7.5 7.5v-2ZM9 9.5c0-1.079.31-2.082.845-2.93L8.153 5.5A7.47 7.47 0 0 0 7 9.5h2Zm-4 3.368C5 10.089 6.815 7.75 9.292 6.99L8.706 5.08C5.397 6.094 3 9.201 3 12.867h2Zm6.042 6.136C7.718 19.003 5 16.268 5 12.867H3c0 4.48 3.588 8.136 8.042 8.136v-2Zm5.725-4.17c-.81 2.433-3.074 4.17-5.725 4.17v2c3.552 0 6.553-2.327 7.622-5.537l-1.897-.632Z"
                                    class="fill-slate-500/20 "></path>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M17 3a1 1 0 0 1 1 1 2 2 0 0 0 2 2 1 1 0 1 1 0 2 2 2 0 0 0-2 2 1 1 0 1 1-2 0 2 2 0 0 0-2-2 1 1 0 1 1 0-2 2 2 0 0 0 2-2 1 1 0 0 1 1-1Z"
                                    class="fill-slate-500"></path>
                            </svg>Dark
                        </li>
                        <li class="flex items-center cursor-pointer py-2 px-4 text-sm hover:bg-jw-blue-500 hover:text-jw-gray-50"
                            id="userThemeOptionsSystem" role="option" tabindex="-1" (click)="setUserTheme('system')">
                            <svg viewBox="0 0 24 24" fill="none" class="w-6 h-6 mr-4">
                                <path d="M4 6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6Z"
                                    stroke-linejoin="round" class="fill-slate-500/20 stroke-slate-500 set-stroke">
                                </path>
                                <path d="M14 15c0 3 2 5 2 5H8s2-2 2-5" stroke-linecap="round" stroke-linejoin="round"
                                    class="fill-slate-500/20 stroke-slate-500 set-stroke"></path>
                            </svg>System
                        </li>
                    </ul>
                </div>
            </div>
            <div class="relative">
                <button type="button" *ngIf="!isLoggedIn" (click)="login()"
                    class="flex items-center mr-3 text-sm bg-jw-gray-200 dark:bg-jw-gray-900 rounded-full md:mr-0 focus:ring-4 focus:ring-jw-gray-300 dark:focus:ring-jw-gray-600"
                    id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
                    <span class="sr-only">Log In</span>
                    <span
                        class="inline-flex items-center w-8 h-8 rounded-full pl-3 mr-2 mdi mdi-login-variant fa-lg"></span>
                    <span class="whitespace-nowrap mr-6">Log In</span>
                </button>
            </div>
            <div class="relative">
                <button type="button" *ngIf="isLoggedIn" (click)="toggleUserMenu()" (clickOutside)="closeUserMenu()"
                    class="rounded py-1 px-2 flex items-center text-sm bg-jw-gray-100 dark:bg-jw-gray-900 focus:ring-1 focus:ring-jw-gray-300 dark:focus:ring-jw-gray-600"
                    id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdownUser">
                    <span class="sr-only">Open user menu</span>
                    <img class="w-6 h-6 rounded-full" loading="lazy"
                        src="https://storageconpertool.blob.core.windows.net/images/{{profileImage}}"
                        (error)="setDefaultProfileImage()" alt="user photo" />
                    <span class="whitespace-nowrap mx-2 hidden sm:block">{{userName}}</span>
                </button>
                <div id="dropdownUser"
                    class="hidden absolute right-0 z-10 w-44 mt-1 rounded bg-jw-gray-100 dark:bg-jw-gray-900">
                    <ul *ngIf="isLoggedIn" aria-labelledby="dropdownUserButton"
                        class="text-jw-gray-900 dark:text-white">
                        <li>
                            <div class="py-3 px-4">
                                <span class="block text-sm text-jw-gray-900 dark:text-white">{{userName}}</span>
                                <span
                                    class="block text-sm font-medium text-jw-gray-500 truncate dark:text-jw-gray-400">{{userEmail}}</span>
                            </div>
                        </li>
                        <li>
                            <a href="#" routerLink="/profile" routerLinkActive="active" i18n="Manage Profile"
                                class="block py-2 px-4 text-sm hover:bg-jw-blue-500 hover:text-jw-gray-50">
                                <span class="buttonIcon mdi mdi-account fa-lg"></span> Manage Profile</a>
                        </li>
                        <li>
                            <a href="#" (click)="logout()" routerLinkActive="active" i18n="Sign Out"
                                class="block py-2 px-4 text-sm hover:bg-jw-blue-500 hover:text-jw-gray-50">
                                <span class="buttonIcon mdi mdi-logout-variant fa-lg"></span> Sign out</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="hidden justify-between items-center w-full sm:flex sm:w-auto sm:order-1 px-2 sm:px-4 py-2.5"
            id="mobile-menu-2">
            <span *ngIf="isLoggedIn">
                Active Convention Year: {{currentYear}}
            </span>
        </div>
    </div>
</nav>