import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-volunteer-rating',
  templateUrl: './volunteer-rating.component.html',
  styleUrls: ['./volunteer-rating.component.scss']
})
export class VolunteerRatingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
