/* eslint-disable @typescript-eslint/no-unused-vars */
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Widgets & Components
import { NavMenuComponent } from './features/navigation/nav-menu/nav-menu.component';
import { HeaderComponent } from './features/layout/header/header.component';
import { FooterComponent } from './features/layout/footer/footer.component';
import { CurrentYearWidgetComponent } from './features/components/current-year-widget/current-year-widget.component';

// Page Components
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { HelpComponent } from './pages/help/help.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';

// Services
import { PathResolveService } from './core/services/path-resolve.service';

// Section Modules
import { CongregationsModule } from './modules/congregations/congregations.module';
import { FacilitiesModule } from './modules/facilities/facilities.module';
import { SearchModule } from './modules/search/search.module';
import { ProfileModule } from './modules/profile/profile.module';
import { CoreModule } from './core/core.module';

import { AdminComponent } from './pages/admin/admin.component';
import { MsalApplicationModule } from './modules/msal-application/msal-application.module';
import { MyWeekendsComponent } from './features/volunteers/my-weekends/my-weekends.component';

import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './modules/shared/shared.module';
import { TableModule } from 'primeng/table';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PanelModule } from 'primeng/panel';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { RatingModule } from 'primeng/rating';
import { EditorModule } from 'primeng/editor';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { AccordionModule } from 'primeng/accordion';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { SkeletonModule } from 'primeng/skeleton';
import { BlockUIModule } from 'primeng/blockui';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { MsalInterceptor, MsalRedirectComponent } from '@azure/msal-angular';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';

@NgModule({
  declarations: [
  AppComponent,
  HomeComponent,
  NavMenuComponent,
  HeaderComponent,
  HelpComponent,
  TermsComponent,
  PrivacyComponent,
  FooterComponent,
  NotFoundComponent,
  CurrentYearWidgetComponent,
  AdminComponent,
  MyWeekendsComponent
  ],
  imports: [
  SharedModule,
  FormsModule,
  TableModule,
  DataViewModule,
  DropdownModule,
  AutoCompleteModule,
  PanelModule,
  DialogModule,
  CalendarModule,
  RatingModule,
  EditorModule,
  InputTextareaModule,
  FileUploadModule,
  MultiSelectModule,
  AccordionModule,
  OverlayPanelModule,
  ConfirmPopupModule,
  SkeletonModule,
  BlockUIModule,
  ToastModule,
  HttpClientModule,
  CoreModule,
  CongregationsModule,
  FacilitiesModule,
  SearchModule,
  ProgressSpinnerModule,
  ProfileModule,
  AppRoutingModule,
  MsalApplicationModule.forRoot('config.json'),
  BrowserAnimationsModule
  ],
  providers: [
  PathResolveService,
  MessageService,
  {
  provide: HTTP_INTERCEPTORS,
  useClass: MsalInterceptor,
  multi: true
  },
  {
  provide: DynamicDialogRef,
  useValue: {}
  },
  DialogService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
  })
export class AppModule { }
