import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from 'src/app/core/services/base-api.service';
import { Volunteer } from '../../../models/volunteer';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})

export class VolunteersService 
  extends BaseApiService {

  private volunteerCache: Map<number, Volunteer> = new Map<number, Volunteer>();

  constructor(http: HttpClient, authService: MsalService, @Inject('BASE_URL') baseUrl: string) {
    super(http, authService, 'beta', 'volunteers', baseUrl);
    console.debug('VolunteerService: Creating...');
  }
 
  getVolunteers(congregationNumber: number): Promise<Volunteer[]>{
    return this.get<Volunteer[]>('list/' + congregationNumber.toString());
  }

  async getVolunteer(volunteerId: number) : Promise<Volunteer | undefined>{
    if (this.volunteerCache.has(volunteerId)) {
      return this.volunteerCache.get(volunteerId);
    }

    const data = await this.get<Volunteer>(volunteerId.toString());
    this.volunteerCache.set(volunteerId, data);
    return data;
  }

  getCurrentVolunteer() : Promise<Volunteer>{
    return this.get<Volunteer>('current');
  }

  updateVolunteer(volunteer: Volunteer) : Promise<Volunteer> {
    return this.put<Volunteer>('', volunteer);
  }

  createVolunteer(volunteer: Volunteer) : Promise<Volunteer> {
    return this.post('', volunteer);
  }

  deleteVolunteer(volunteer: Volunteer) : Promise<Volunteer> {
    return this.delete(volunteer.volunteerId.toString());
  }
}
