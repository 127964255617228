import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserAccountService } from '../../../core/services/user-account.service';
import { AccountInfo } from '@azure/msal-browser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentUser: AccountInfo;
  isLoggedIn: boolean;
  version: string = environment.version;
  environment: string = environment.environment;

  constructor(private userAccountService: UserAccountService) {}

  ngOnInit(): void {
    this.currentUser = this.userAccountService.getAccount();
    this.isLoggedIn = this.userAccountService.isLoggedIn();
    this.userAccountService.subscribeToLogin(() => {
      this.currentUser = this.userAccountService.getAccount();
      this.isLoggedIn = this.userAccountService.isLoggedIn();
    });
  }
}
