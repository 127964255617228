import { Weekend } from './weekend';
import { WeekendRoleAssignmentGrade } from './weekendRoleAssignmentGrade';
import { ConventionJob } from './conventionJob';
import { ConventionJobRole } from './conventionJobRole';
import { ConventionJobSubRole } from './conventionJobSubRole';

export class WeekendRoleAssignment {
    weekendRoleAssignmentId: number;
    weekendId: number;
    weekend: Weekend;
    volunteerId: number;
    conventionJobId: number;
    conventionJobRoleId: number;
    conventionJobSubRoleId: number;

    conventionJob: ConventionJob;
    conventionJobRole: ConventionJobRole;
    conventionJobSubRole: ConventionJobSubRole;
    recommendation: ConventionJobRole;

    grades: WeekendRoleAssignmentGrade[];
}
