import { Pipe, PipeTransform } from '@angular/core';
import { Facility } from '../../../models/facility';
import { FacilityCapacity } from '../../../models/facilityCapacity';

@Pipe({
  name: 'capacity'
})
export class CapacityPipe implements PipeTransform {

  transform(facilityCapacity: FacilityCapacity, facility: Facility): string {
    try {
      if (facility.branchOwned) {
        return `${facilityCapacity.capacityDescription} (Branch Owned)`;
      } else {
        return `${facilityCapacity.capacityDescription} (${facility.maxCapacity})`;
      }
    } catch (error) {
      return 'ERROR - ' + error.message;
    }
  }
}