import { Component, OnInit, ViewChild } from '@angular/core';
import { Volunteer } from 'src/app/models/volunteer';
import { VolunteerRatingModalComponent } from 'src/app/features/volunteers/volunteer-rating-modal/volunteer-rating-modal.component';
import { CongregationService } from 'src/app/modules/congregations/services/congregation.service';
import { Congregation } from 'src/app/models/congregation';
import { SelectItem, MessageService } from 'primeng/api';
import { SearchService } from 'src/app/modules/congregations/services/search.service';
import { FacetGroup } from 'src/app/models/facetGroup';
import { SearchRequest } from 'src/app/models/searchRequest';
import { WeekendsService } from 'src/app/modules/facilities/services/weekends.service';
import { VolunteerAssignmentComponent } from 'src/app/features/volunteers/volunteer-assignment/volunteer-assignment.component';
import { WeekendRoleAssignment } from 'src/app/models/weekendRoleAssignment';
import { VolunteerEditComponent } from 'src/app/features/volunteers/volunteer-edit/volunteer-edit.component';
import { WeekendRoleAssignmentGrade } from 'src/app/models/weekendRoleAssignmentGrade';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ButtonType } from '../../components/button/buttonType';
import { SearchResult } from 'src/app/models/searchResult';
import { Location } from '@angular/common';
import { UserAccountService } from '../../../core/services/user-account.service';
import { ConventionYearService } from '../../../core/services/convention-year.service';
import { SecurityRole } from '../../security/securityRole';
import { LabelType } from '../../components/text-label/text-label.component';
import { TableLazyLoadEvent } from 'primeng/table';
import posthog from 'posthog-js';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
  })
export class SearchComponent implements OnInit {

  @ViewChild('volunteerRatingModal') volunteerRatingModal: VolunteerRatingModalComponent;
  @ViewChild('volunteerAssignmentModal') volunteerAssignmentModal: VolunteerAssignmentComponent;
  @ViewChild('volunteerEditModal') volunteerEditModal: VolunteerEditComponent;
  @ViewChild('notes') notesOverlay: OverlayPanel;
  volunteers: Volunteer[];
  isSearching = false;
  isFirstSearch: boolean;
  totalRecords: number;
  responsibilities: FacetGroup;
  congregationFacets: FacetGroup;
  departmentFacets: FacetGroup;
  circuitFacets: FacetGroup;
  recommendationFacets: FacetGroup;
  searchRequest: SearchRequest;
  buttonTypes = ButtonType;
  labelTypes = LabelType;
  currentYear: number;
  searchTerm = '';

  selectedCongregation: Congregation;
  selectCongregationList: SelectItem[] = [];

  currentVolunteer: Volunteer;
  currentWeekendRoleAssignment: WeekendRoleAssignment;

  selectedGrades: WeekendRoleAssignmentGrade[];
  showNotesDialog: boolean;

  securityRole = SecurityRole;
  canCheckFeatureFlags = true;

  constructor(
    private messageService: MessageService,
    private searchService: SearchService,
    private weekendService: WeekendsService,
    private congregationService: CongregationService,
    private locationService: Location,
    private userAccountService: UserAccountService,
    private conventionYearService: ConventionYearService
  ) { }

  ngOnInit(): void {
    this.isFirstSearch = true;
    this.searchRequest = new SearchRequest();
    this.conventionYearService.getCurrent().forEach(year => {
      this.currentYear = year.year;
    });
    this.loadCongregations();

    //this.doSearch();
  }

  handleGradeSaved(): void {
    this.doSearch();
  }

  handleAssignmentSaved(): void {
    this.doSearch();
  }

  handlVolunteerSaved(): void {
    this.doSearch();
  }

  isFeatureEnabled(flagName: string) {
    return posthog.isFeatureEnabled(flagName);
  }

  /**
   * Return boolean based on if volunteer
   * has an assignment in the current convention.
   * @param volunteer
   * @returns hasCurrentYearAssignment
   */
  isAssignedWeekend(volunteer: Volunteer): boolean {
    let hasCurrentYearAssignment = false;
    volunteer.weekendRoleAssignments.map(wra => {
      if (wra.weekend.conventionYear.year === this.currentYear) {
        hasCurrentYearAssignment = true;
      }
    });
    return hasCurrentYearAssignment;
  }

  hasRecommendation(volunteer: Volunteer): boolean {
    return volunteer.recommendationId !== null && volunteer.recommendationId !== 0;
  }

  notRecommended(volunteer: Volunteer): boolean {
    return volunteer.recommendationId === -1;
  }

  showNotesButton(wra: WeekendRoleAssignment): boolean {
    return wra.grades.length > 0 && this.userAccountService.hasGradeAccess(wra);
  }

  updateUrl(): void {
    let queryString = '';
    queryString = this.buildFacetUrlParameter(this.responsibilities, queryString, 'responsibilities');
    queryString = this.buildFacetUrlParameter(this.congregationFacets, queryString, 'congregations');
    queryString = this.buildFacetUrlParameter(this.circuitFacets, queryString, 'circuits');
    queryString = this.buildFacetUrlParameter(this.departmentFacets, queryString, 'departments');
    queryString = this.buildFacetUrlParameter(this.recommendationFacets, queryString, 'recommendations');

    if (queryString) {
      queryString = `?${queryString}`;
    }
    this.locationService.replaceState(`/search${queryString}`);
  }

  buildFacetUrlParameter(facetGroup: FacetGroup, queryString: string, parameterName: string): string {
    let selectedFacets = '';
    if (facetGroup == null) {
      return queryString;
    }
    if (!facetGroup.facets) {
      return queryString;
    }

    facetGroup.facets.map(facet => {
      if (facet.isSelected) {
        selectedFacets += this.addParameter(facet.name, selectedFacets);
      }
    });

    if (selectedFacets && selectedFacets.length > 0) {
      queryString += this.addParameter(`${parameterName}=${selectedFacets}`, queryString, '&');
    }

    return queryString;
  }

  addParameter(value: string, selectedValues: string, joinCharacter = ','): string {
    if (selectedValues && selectedValues.length > 0) {
      return `${joinCharacter}${value}`;
    }
    return value;
  }

  doSearch(): boolean {
    this.isSearching = true;
    this.searchRequest.term = this.searchTerm;
    this.searchRequest.responsibilities = this.responsibilities;
    this.searchRequest.congregations = this.congregationFacets;
    this.searchRequest.departments = this.departmentFacets;
    this.searchRequest.circuits = this.circuitFacets;
    this.searchRequest.recommendations = this.recommendationFacets;

    this.loadVolunteers().then(() => {
      this.isSearching = false;
      this.isFirstSearch = false;
      //this.updateUrl();
    });
    return false;
  }

  onFacetChange(): void {
    this.doSearch();
  }

  lazyLoadVolunteers(event: TableLazyLoadEvent): void {
    this.isSearching = true;
    this.searchRequest.skip = event.first;
    this.searchRequest.pageSize = event.rows;
    this.doSearch();
  }

  async loadVolunteers(): Promise<void | SearchResult> {
    const data = await this.searchService.search(this.searchRequest);
    this.volunteers = data.volunteers;
    this.responsibilities = data.responsibilities;
    this.congregationFacets = data.congregations;
    this.departmentFacets = data.departments;
    this.circuitFacets = data.circuits;
    this.recommendationFacets = data.recommendations;
    this.totalRecords = data.totalRecords;
  }

  loadCongregations(): void {
    this.congregationService.getCongregations(1, 1000, 0, null, null)
      .then(data => {
        this.selectCongregationList = [];

        const allCongregations = new Congregation();
        allCongregations.congregationName = 'All Congregations';

        this.selectCongregationList.push({ label: allCongregations.congregationName, value: allCongregations });
        data.records.forEach((el) => {
          this.selectCongregationList.push({ label: el.congregationName, value: el });
        });
      });
  }

  openAddGradeModal(volunteer: Volunteer): void {
    this.weekendService.getCurrentWeekendForVolunteer(volunteer.volunteerId)
      .then(weekend => {
        this.volunteerRatingModal.show(weekend.weekendId, volunteer.weekendRoleAssignments[0]);
      });
  }

  openAddEditAssignmentModal(volunteer: Volunteer): void {
    this.currentVolunteer = volunteer;
    this.weekendService.getCurrentWeekendForVolunteer(volunteer.volunteerId)
      .then(weekend => {
        if (!weekend) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please add this persons congregation to a weekend before adding an assignment' });
          return;
        }
        this.volunteerAssignmentModal.show(weekend, volunteer);
      });
  }

  showMessage(): void {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'this is some message detail' });
  }

  openEditModal(volunteer: Volunteer): void {
    this.volunteerEditModal.show(volunteer.volunteerId, undefined);
  }

  onViewGradeNotes(wra: WeekendRoleAssignment): void {
    this.selectedGrades = wra.grades;
    this.showNotesDialog = true;
  }

  onHideNotes(): void {
    this.showNotesDialog = false;
    this.selectedGrades = [];
  }
}
