import { ErrorHandler, Inject, Injectable, Injector, NgZone } from '@angular/core';
import { ErrorDialogService } from 'src/app/modules/shared/errors/error-dialog.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    @Inject(Injector) private readonly injector: Injector
  ) { }

  handleError(error: any) : void {
    const zone = this.injector.get(NgZone);
    const errorDialogService = this.injector.get(ErrorDialogService);

    zone.run(() => {
      console.error('Error from global error handler', error);
      errorDialogService.openDialog(error.message || 'Undefined client error', error.status || 500);
    });

    console.error('Error from global error handler', error);
  }
}
