/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

import { EventSourcePolyfill } from 'event-source-polyfill';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private myApiUrl: string;
  private eventSource: EventSourcePolyfill;

  constructor(
    protected http: HttpClient,
    protected authService: MsalService,
  ) {
    this.myApiUrl = '/api/beta/admin/';
  }

  importAirtable(eventHandler?: (value: any) => void, whatIf = false): void {
    const request = {
      scopes: environment.azureAd.scopes, //["api://9ee11ebd-e096-433d-99d3-db49c881c095/access-as-user"],
    };

    this.authService.instance
      .acquireTokenSilent(request)
      .then((tokenReponse) => {
        const es = new Observable((observer) => {
          this.eventSource = new EventSourcePolyfill(
            this.myApiUrl + 'import-airtable?whatIf=' + whatIf,
            {
              headers: {
                Authorization: 'Bearer ' + tokenReponse.accessToken,
              },
            },
          );

          this.eventSource.onmessage = (event) => {
            console.debug(
              'AdminService: importAirTable: onmessage',
              event.data,
              event,
            );
            if (eventHandler) {
              eventHandler(event);
            }
            observer.next(event.data);
          };

          this.eventSource.onerror = (error) => {
            this.closeEventSource();
          };
        });

        es.subscribe();
      });
  }

  closeEventSource(): void {
    this.eventSource.close();
  }
}
