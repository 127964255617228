/* eslint-disable @typescript-eslint/no-unused-vars */
import { InjectionToken, NgModule, APP_INITIALIZER } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { ConfigService } from '../../config.service';
import { ModuleWithProviders } from '@angular/core';
import { environment } from 'src/environments/environment';

const AUTH_CONFIG_URL_TOKEN = new InjectionToken<string>('AUTH_CONFIG_URL');
const isIE =
  window.navigator.userAgent.indexOf('MSIE') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string): void {
  //console.debug(message);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function initializerFactory(env: ConfigService, configUrl: string): any {
  // APP_INITIALIZER, except a function return which will return a promise
  // APP_INITIALIZER, angular doesnt starts application untill it completes
  const promise = env.init(configUrl).then(() => {
    console.debug('ClientID: ' + env.getSettings('clientID'));
  });
  return () => promise;
}

export function MSALInstanceFactory(
  config: ConfigService,
): IPublicClientApplication {
  const protocol = window.location.protocol;
  const slashes = protocol.concat('//');
  const host = slashes.concat(window.location.host);

  console.debug(`Host: ${host}`);

  return new PublicClientApplication({
    auth: {
      clientId: environment.azureAd.clientId,
      authority: environment.azureAd.authority,
      redirectUri: host,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false,
      },
    },
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function MSALInterceptorConfigFactory(
  config: ConfigService,
): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set('/api', [
    'api://9ee11ebd-e096-433d-99d3-db49c881c095/access-as-user',
  ]);
  protectedResourceMap.set('/facilities', [
    'api://9ee11ebd-e096-433d-99d3-db49c881c095/access-as-user',
  ]);
  protectedResourceMap.set('/congregation', [
    'api://9ee11ebd-e096-433d-99d3-db49c881c095/access-as-user',
  ]);
  protectedResourceMap.set('/search', [
    'api://9ee11ebd-e096-433d-99d3-db49c881c095/access-as-user',
  ]);
  protectedResourceMap.set('/admin', [
    'api://9ee11ebd-e096-433d-99d3-db49c881c095/access-as-user',
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: { scopes: [] },
  };
}

@NgModule({
  providers: [],
  imports: [MsalModule],
})
export class MsalApplicationModule {
  static forRoot(
    configFile: string,
  ): ModuleWithProviders<MsalApplicationModule> {
    return {
      ngModule: MsalApplicationModule,
      providers: [
        ConfigService,
        { provide: AUTH_CONFIG_URL_TOKEN, useValue: configFile },
        {
          provide: APP_INITIALIZER,
          useFactory: initializerFactory,
          deps: [ConfigService, AUTH_CONFIG_URL_TOKEN],
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true,
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [ConfigService],
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [ConfigService],
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
      ],
    };
  }
}
