/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Volunteer } from '../../../models/volunteer';
import { VolunteersService } from '../../../modules/congregations/services/volunteers.service';
import { MessageService, SelectItem } from 'primeng/api';
import { Congregation } from '../../../models/congregation';
import { ButtonType } from '../../components/button/buttonType';
import { LookupsService } from '../../../core/services/lookups.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Responsibility } from 'src/app/models/responsibility';
import posthog from 'posthog-js';

@Component({
  selector: 'app-volunteer-edit',
  templateUrl: './volunteer-edit.component.html',
  styleUrls: ['./volunteer-edit.component.scss']
  })
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class VolunteerEditComponent implements OnInit {

  @Input()
  congregation: Congregation;

  @Input()
  volunteer: Volunteer;

  @Output()
  onSave: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  showForm = false;
  dateOfBirth: Date;

  submitted = false;
  form: FormGroup;

  buttonTypes = ButtonType;

  races: SelectItem[] = [];
  selectedRace: string;
  selectCongregationList: SelectItem[] = [];

  selectedResponsibilities: SelectItem[] = [];
  responsibilities: SelectItem[];
  disableSelectCongregation = false;

  yearsOfBirth: SelectItem[] = [];
  selectedYearOfBirth: number;

  constructor(
    private volunteerService: VolunteersService,
    private messageService: MessageService,
    private lookupService: LookupsService,
  ) {
    this.races.push({ label: 'Asian', value: 'Asian' });
    this.races.push({ label: 'Black', value: 'Black' });
    this.races.push({ label: 'Hispanic', value: 'Hispanic' });
    this.races.push({ label: 'White', value: 'White' });

    for(let year = (new Date).getFullYear() - 16;year >= 1940;year--) {
      this.yearsOfBirth.push({ label: year.toString(), value: year });
    }

    this.loadResponsibilities();

  }

  ngOnInit(): void {
    console.debug('edit-volunteer::ngOnInit()');

    if (!this.volunteer) {
      this.volunteer = new Volunteer();
    }

    this.initializeFormGroup();
  }

  isFlagEnabled(flagName: string) {
    return posthog.isFeatureEnabled(flagName);
  }

  initializeFormGroup(): void {
    this.form = new FormGroup({
      firstName: new FormControl(this.volunteer.firstName, [ Validators.required ]),
      lastName: new FormControl(this.volunteer.lastName, [ Validators.required ]),
      jwEmail: new FormControl(this.volunteer.jwEmail, [ Validators.required, Validators.email ]),
      personalEmail: new FormControl(this.volunteer.personalEmail, [ Validators.required, Validators.email ]),
      phoneNumber: new FormControl(this.volunteer.phoneNumber),
      dateOfBirth: new FormControl(this.volunteer.dateOfBirth),
      selectedYearOfBirth: new FormControl(this.selectedYearOfBirth, [ Validators.required, Validators.min(1940), Validators.max((new Date()).getFullYear() - 16) ]),
      selectedResponsibilities: new FormControl(this.selectedResponsibilities),
      azureAdUserId: new FormControl(this.volunteer.azureAdUserId),
      selectedRace: new FormControl(this.selectedRace),
    });
    console.debug('form', this.form);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get f(): any { return this.form.controls; }

  show(volunteerId: number, congregation: Congregation | undefined): void {
    if (!this.volunteer) {
      this.volunteer = new Volunteer();
    }

    this.selectedResponsibilities = [];

    if (congregation){
      this.selectCongregationList = [];
      this.congregation = congregation ?? this.congregation;
      this.selectCongregationList.push({ label: congregation.congregationName, value: congregation.congregationNumber });
    }

    if (volunteerId == 0) {
      this.volunteer = new Volunteer();
      if (this.congregation) {
        this.volunteer.congregation = this.congregation;
        this.volunteer.congregationNumber = this.congregation.congregationNumber;
      }
      this.showForm = true;
    } else {
      this.volunteerService.getVolunteer(volunteerId)
        .then(data => {
          if (data) {
            this.dateOfBirth = new Date(data.dateOfBirth);
            this.selectedYearOfBirth = data.yearOfBirth;
            this.volunteer = data;
            this.congregation = data.congregation;

            this.volunteer.responsibilities.forEach(res => {
              const item = this.responsibilities.find(r => r.value.responsibilityId === res.responsibilityId);
              if (item) {
                this.selectedResponsibilities.push(item);
              }
            });

            if (typeof this.volunteer.race !== 'undefined' && this.volunteer.race) {
              this.selectedRace = this.volunteer.race;
            }

            this.form.patchValue({
              'firstName': this.volunteer.firstName,
              'lastName': this.volunteer.lastName,
              'jwEmail': this.volunteer.jwEmail,
              'personalEmail': this.volunteer.personalEmail,
              'phoneNumber': this.volunteer.phoneNumber,
              'dateOfBirth': this.volunteer.dateOfBirth,
              'selectedYearOfBirth': this.selectedYearOfBirth,
              'selectedResponsibilities': this.selectedResponsibilities,
              'azureAdUserId': this.volunteer.azureAdUserId,
              'selectedRace': this.selectedRace,
            });
            this.showForm = true;
          }
        });
    }
  }

  loadResponsibilities(): void {
    this.lookupService.getCongregationResponsibilities()
      .then(data => {
        this.responsibilities = [];
        data.forEach((el) => {
          this.responsibilities.push({ label: el.responsibility, value: el });
        });
      });
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.volunteer.azureAdUserId = this.f.azureAdUserId.value ?? '';
    this.volunteer.firstName = this.f.firstName.value;
    this.volunteer.lastName = this.f.lastName.value;
    this.volunteer.jwEmail = this.f.jwEmail.value;
    this.volunteer.personalEmail = this.f.personalEmail.value;
    this.volunteer.phoneNumber = this.f.phoneNumber.value;
    this.volunteer.dateOfBirth = this.dateOfBirth;
    this.volunteer.yearOfBirth = this.f.selectedYearOfBirth.value;
    this.volunteer.congregationNumber = this.volunteer.congregation.congregationNumber;
    this.volunteer.responsibilities = [];
    this.volunteer.race = this.f.selectedRace.value;
    this.f.selectedResponsibilities.value.forEach((res: any) => {
      if (!this.volunteer.responsibilities.find((r: Responsibility) => r.responsibilityId === res.value)) {
        this.volunteer.responsibilities.push(
          {
            responsibilityId: res.value.responsibilityId,
            responsibility: res.value
          });
      }
    });

    if (this.volunteer.volunteerId === 0) {
      this.volunteerService.createVolunteer(this.volunteer)
        .then(() => {
          this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'New volunteer record saved' });
          this.onSave.emit();
        });
    } else {
      this.volunteerService.updateVolunteer(this.volunteer)
        .then(() => {
          this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Volunteer record updated' });
          this.onSave.emit();
        });
    }
  }

  cancel(event: any): void {
    event.preventDefault();
    this.onCancel.emit();
  }
}
