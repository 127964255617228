<div class="block p-6 bg-white rounded-lg mb-4 border border-jw-gray-200 shadow-md
            hover:bg-jw-gray-100 dark:bg-jw-gray-800 dark:border-jw-gray-700
            dark:hover:bg-jw-gray-700">
    <h4 class="mb-2 text-2xl font-bold tracking-tight text-jw-gray-900 dark:text-white">
        Congregation Volunteers for:
        <strong><em>{{ congregation?.congregationName }} [{{ congregation?.congregationNumber }}]</em></strong>
    </h4>
</div>
<div class="w-full" *ngIf="volunteers">
    <p-table [value]="volunteers" styleClass="w-full" dataKey="volunteerId">
        <ng-template pTemplate="header">
            <tr>
                <th scope="col" class="rounded-tl-lg"></th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone No.</th>
                <th scope="col">Overall Rating</th>
                <th scope="col" class="w-2/12 rounded-tr-lg">
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-volunteer let-expanded="expanded">
            <tr>
                <td>
                    <a href="#" [pRowToggler]="volunteer">
                        <i [ngClass]="expanded ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-right'"></i>
                    </a>
                </td>
                <td><span class="p-column-title">First Name:</span>{{ volunteer.firstName }}</td>
                <td><span class="p-column-title">Last Name:</span>{{ volunteer.lastName }}</td>
                <td><span class="p-column-title">Email:</span>{{ volunteer.jwEmail }}</td>
                <td><span class="p-column-title">Phone No.:</span>{{ volunteer.phoneNumber | phone:'US' }}</td>
                <td><span class="p-column-title">Overall Rating:</span>{{ volunteer.grade | grade:0:volunteer.weekendRoleAssignments[0] }}</td>
                <td class="text-right">
                    <app-button [hideLabel]="true" label="Edit Volunteer" [type]="buttonTypes.Edit"
                        [routerLink]="['/congregation/' + congregationNumber + '/volunteers/' + volunteer.volunteerId]"
                        ></app-button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-volunteer>
            <tr>
                <td colspan="7" class="container">
                    <div class="w-full grid grid-cols-1 md:grid-cols-3 gap-5 p-5">
                        <div class="col-span-3">
                            <span *ngFor="let res of volunteer.responsibilities"
                                class="bg-jw-blue-100 text-jw-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-jw-blue-600 dark:text-whitebg-jw-blue-100 text-jw-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-jw-blue-600 dark:text-white">
                                {{ res.responsibility.responsibility }}
                            </span>
                        </div>
                        <div class="flex items-center space-x-2 font-semibold text-jw-gray-900 leading-8 p-2">
                            <dl class="box-border">
                                <dt>
                                    <app-text-label [labelType]="labelTypes.Heading">Personal Email:</app-text-label>
                                </dt>
                                <dd>
                                    <app-text-label [labelType]="labelTypes.Label">{{ volunteer.personalEmail }}</app-text-label>
                                </dd>
                                <dt>
                                    <app-text-label [labelType]="labelTypes.Heading">Year of Birth:</app-text-label>
                                </dt>
                                <dd>
                                    <app-text-label [labelType]="labelTypes.Label">{{ volunteer.yearOfBirth }}</app-text-label>
                                </dd>
                                <dt>
                                    <app-text-label [labelType]="labelTypes.Heading">Race:</app-text-label>
                                </dt>
                                <dd>
                                    <app-text-label [labelType]="labelTypes.Label">{{ volunteer.race }}</app-text-label>
                                </dd>
                                <dt>
                                    <app-text-label [labelType]="labelTypes.Heading">Circuit:</app-text-label>
                                </dt>
                                <dd>
                                    <app-text-label [labelType]="labelTypes.Label">{{ volunteer.congregation.circuitNumber }}{{ volunteer.congregation.circuitSection }}</app-text-label>
                                </dd>
                            </dl>
                        </div>
                        <div class="w-full md:col-span-2">
                            <label class="mb-2 text-lg font-bold tracking-tight text-jw-gray-700">Grading History</label>
                            <div class="pt-3"
                                *ngFor="let wra of volunteer.weekendRoleAssignments; index as i; first as isFirst">
                                <h3 class="mb-2 font-medium tracking-tight text-jw-gray-700">Overall grade for 2020: <em>
                                    {{wra.grade | grade:0:wra }}</em> : Role: {{ wra | assignment }}</h3>
                                <div class="grid grid-cols-6 mt-2">
                                    <div class="col-span-1">Grade:</div>
                                    <div class="col-span-5">Notes:</div>
                                </div>
                                <div *ngFor="let grade of wra.grades; index as i; first as isFirst"
                                    class="grid grid-cols-6 mb-2">
                                    <div class="col-span-1"><strong>{{ grade.grade | grade:0:wra }}</strong></div>
                                    <div class="col-span-5">
                                        <div [innerHTML]="grade.gradeNotes"></div>
                                        <div>Graded by: {{ grade.graderVolunteer.lastName }}, {{
                                            grade.graderVolunteer.firstName }} | {{ grade.gradeDate |
                                            date:'mediumDate' }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="py-2">
                <app-button [type]="buttonTypes.Add" label="Add Volunteer" [routerLink]="['/congregation/' + congregationNumber + '/volunteers/add']" [requiredRoles]="[securityRole.SuperUser, securityRole.TechnicalContact, securityRole.CommitteeMember, securityRole.CommitteeAssistant]"></app-button>
            </div>
        </ng-template>
    </p-table>
</div>
