import { SelectItem } from 'primeng/api';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-namespace */
export namespace Languages {
    const map: {[index: string]: string} = {
      'ABN':'Abaknon',
      'ABB':'Abbey',
      'ABK':'Abkhaz',
      'AU':'Abua',
      'ABU':'Abui',
      'ACH':'Achi',
      'AC':'Acholi',
      'AHW':'Achuar-Shiwiar',
      'ADH':'Adhola',
      'AD':'Adyghe',
      'AF':'Afrikaans',
      'AHN':'Ahanta',
      'AJG':'Aja',
      'HO':'Ajië',
      'KAN':'Akateko',
      'AKA':'Akha',
      'AL':'Albanian',
      'ALS':'Albanian Sign Language',
      'ALQ':'Algonquin',
      'ALT':'Altai',
      'ALU':'Alur',
      'ABE':'Ambae (East)',
      'ASL':'American Sign Language',
      'AM':'Amharic',
      'AI':'Amis',
      'AMG':'Amuzgo (Guerrero)',
      'LAS':'Angolan Sign Language',
      'AYI':'Anyin (Indenie)',
      'A':'Arabic',
      'ADZ':'Arabic (Algeria)',
      'AMO':'Arabic (Morocco)',
      'JU':'Arabic (Sudanese Creole)',
      'ATN':'Arabic (Tunisia)',
      'LSA':'Argentinean Sign Language',
      'ARH':'Arhuaco',
      'REA':'Armenian',
      'KRB':'Armenian (Eastern dialect)',
      'R':'Armenian (West)',
      'ARO':'Arosi',
      'AE':'Assamese',
      'AS':'Assyrian',
      'ATY':'Atayal',
      'IE':'Ateso',
      'ATI':'Attié',
      'AKN':'Aukan',
      'AUS':'Australian Sign Language',
      'OGS':'Austrian Sign Language',
      'AV':'Avar',
      'AGR':'Awajun',
      'AWN':'Awing',
      'AYW':'Ayiwo',
      'AP':'Aymara',
      'AJR':'Azerbaijani',
      'AJA':'Azerbaijani (Arabic)',
      'AJ':'Azerbaijani (Cyrillic)',
      'BDG':'Badaga',
      'BFI':'Bafia',
      'BKK':'Bakoko',
      'BLN':'Balinese',
      'AR':'Bambara',
      'BNJ':'Banjar',
      'AO':'Baoule',
      'BH':'Bari',
      'BB':'Bariba',
      'BAK':'Bashkir',
      'BQ':'Basque',
      'BS':'Bassa (Cameroon)',
      'BA':'Bassa (Liberia)',
      'BTD':'Batak (Dairi)',
      'AK':'Batak (Karo)',
      'BTK':'Batak (Simalungun)',
      'BT':'Batak (Toba)',
      'BAU':'Bauro',
      'SBF':'Belgian French Sign Language',
      'BZK':'Belize Kriol',
      'BEL':'Belorussian',
      'BMB':'Bembe',
      'EN':'Bena',
      'BE':'Bengali',
      'BRM':'Berom',
      'ET':'Bété',
      'BTS':'Betsimisaraka (Northern)',
      'BTA':'Betsimisaraka (Southern)',
      'BHJ':'Bhojpuri',
      'IK':'Biak',
      'BI':'Bicol',
      'BDY':'Bidayuh (Bukar)',
      'BLB':'Bisa (Lebir)',
      'LM':'Bislama',
      'TCR':'Bissau Guinean Creole',
      'BLF':'Blackfoot',
      'BVL':'Bolivian Sign Language',
      'BMU':'Bomu',
      'BSN':'Bosnian',
      'BO':'Boulou',
      'LSB':'Brazilian Sign Language',
      'BRI':'Bribri',
      'BSL':'British Sign Language',
      'BDZ':'Budza',
      'BK':'Bukidnon',
      'LK':'Bukusu',
      'BL':'Bulgarian',
      'BLS':'Bulgarian Sign Language',
      'BNK':'Bunak',
      'BNN':'Bunun (South)',
      'BUR':'Bura',
      'BY':'Buryat',
      'BSH':'Bushi',
      'BSG':'Bushoong',
      'CQC':'Cakchiquel (Central)',
      'CB':'Cambodian',
      'CBL':'Cambodian Sign Language',
      'CRB':'Carib',
      'AN':'Catalan',
      'CV':'Cebuano',
      'YU':'Central Alaskan Yupik',
      'CPL':'Cha’palaa',
      'CM':'Chamorro',
      'CGM':'Changana (Mozambique)',
      'CGA':'Changana (Zimbabwe)',
      'CC':'Chavacano',
      'HE':'Chechen',
      'CKE':'Cherokee',
      'CN':'Chichewa',
      'CGG':'Chiga',
      'SCH':'Chilean Sign Language',
      'FLM':'Chin (Falam)',
      'HK':'Chin (Hakha)',
      'KUK':'Chin (Kuki)',
      'MAA':'Chin (Mara)',
      'CMT':'Chin (Matu)',
      'CI':'Chin (Tiddim)',
      'ZO':'Chin (Zotung)',
      'CNO':'Chinantec (Ojitlan)',
      'CHM':'Chinese (Mandarin)',
      'CNS':'Chinese Cantonese (Simplified)',
      'CHC':'Chinese Cantonese (Traditional)',
      'CHS':'Chinese Mandarin (Simplified)',
      'CH':'Chinese Mandarin (Traditional)',
      'CSL':'Chinese Sign Language',
      'CHQ':'Chiquitano',
      'CG':'Chitonga',
      'CT':'Chitonga (Malawi)',
      'CGW':'Chitonga (Zimbabwe)',
      'TB':'Chitumbuka',
      'YA':'Chiyao',
      'CTW':'Choctaw',
      'CK':'Chokwe',
      'CHL':'Chol',
      'CLX':'Chontal (Oaxaca)',
      'CLT':'Chontal (Tabasco)',
      'CPI':'Chopi',
      'CTI':'Chorote (Iyojwa\'ja)',
      'CO':'Chuabo',
      'CHJ':'Chuj',
      'TE':'Chuukese',
      'CU':'Chuvash',
      'CW':'Cibemba',
      'NM':'Cinamwanga',
      'CIN':'Cinyanja',
      'LSC':'Colombian Sign Language',
      'CMK':'Como Karim',
      'CMG':'Comorian (Ngazidja)',
      'CSC':'Corsican',
      'SCR':'Costa Rican Sign Language',
      'OC':'Cree Omushkego (Roman)',
      'CY':'Cree Plains (Roman)',
      'CYS':'Cree Plains (Syllabics)',
      'YC':'Cree West Swampy (Roman)',
      'YCS':'Cree West Swampy (Syllabics)',
      'WC':'Cree Woods (Roman)',
      'WCS':'Cree Woods (Syllabics)',
      'CTC':'Crimean Tatar (Cyrillic)',
      'C':'Croatian',
      'HZJ':'Croatian Sign Language',
      'CBS':'Cuban Sign Language',
      'CBE':'Cubeo',
      'CPC':'Curripaco',
      'B':'Czech',
      'CSE':'Czech Sign Language',
      'DGA':'Dagaare',
      'DGB':'Dagbani',
      'DT':'Dakota',
      'DMR':'Damara',
      'DG':'Dangme',
      'D':'Danish',
      'DSL':'Danish Sign Language',
      'DRG':'Dargwa',
      'DAR':'Dari',
      'DWW':'Dawawa',
      'DKA':'Dayak Ahe',
      'DK':'Dayak Ngaju',
      'DYT':'Dayak Tomun',
      'DNS':'Dengese',
      'DDL':'Dida (Lakota)',
      'DGR':'Digor',
      'DI':'Dinka',
      'DO':'Diola',
      'DTS':'Dogon (Toro So)',
      'DA':'Douala',
      'LF':'Drehu',
      'KAD':'Dusun',
      'O':'Dutch',
      'NGT':'Dutch Sign Language',
      // "DVA":"DVD No. 1 Multilanguage Group",
      // "DVB":"DVD No. 2 Multilanguage Group",
      // "DVC":"DVD No. 3 Multilanguage Group",
      // "DVE":"DVD No. 4 Multilanguage Group",
      // "DVF":"DVD No. 5 Multilanguage Group",
      // "DVG":"DVD No. 6 Multilanguage Group",
      'DZ':'Dzongkha (Tibetan)',
      'EBA':'Ebira',
      'SEC':'Ecuadorian Sign Language',
      'ED':'Edo',
      'EFS':'Efate (South)',
      'EF':'Efik',
      'EMB':'Emberá (Catío)',
      'EMC':'Emberá (Chamí)',
      'EMP':'Emberá (Northern)',
      'EA':'Enga',
      'E':'English',
      'EDF':'English edition for the Deaf',
      'EZ':'Erzya',
      'IH':'Esan',
      'ST':'Estonian',
      'STD':'Estonian Sign Language',
      'ETN':'Eton',
      'EW':'Ewe',
      'EWN':'Ewondo',
      'FGN':'Fang',
      'FA':'Fante',
      'FR':'Faroese',
      'FTL':'Fataluku',
      'FFE':'Fe\'fe\'',
      'FN':'Fijian',
      'FSL':'Filipino Sign Language',
      'FI':'Finnish',
      'FID':'Finnish Sign Language',
      'VGT':'Flemish Sign Language',
      'FO':'Fon',
      'FF':'Frafra',
      'F':'French',
      'LSF':'French Sign Language',
      'FS':'Frisian',
      'FD':'Fulfulde (Cameroon)',
      'FU':'Fulfulde (Maasina)',
      'FLN':'Fulfulde (Nigerian)',
      'FT':'Futuna (East)',
      'GA':'Ga',
      'GZ':'Gagauz',
      'GZR':'Gagauz (Roman)',
      'GLC':'Galician',
      'GRF':'Garifuna',
      'GAR':'Garo (Abeng)',
      'GAA':'Garo (Achik)',
      'GBA':'Gbaya',
      'GE':'Georgian',
      'X':'German',
      'DGS':'German Sign Language',
      'GHS':'Ghanaian Sign Language',
      'GHM':'Ghomálá’',
      'GY':'Giryama',
      'GTN':'Gitonga',
      'GG':'Gogo',
      'GN':'Gokana',
      'GRC':'Gourmanchéma',
      'GO':'Gouro',
      'G':'Greek',
      'GSL':'Greek Sign Language',
      'GL':'Greenlandic',
      'GCR':'Guadeloupean Creole',
      'GHB':'Guahibo',
      'GJ':'Guajiro',
      'GBM':'Guambiano',
      'GI':'Guarani',
      'GIB':'Guarani (Bolivia)',
      'GRY':'Guarayu',
      'LSG':'Guatemalan Sign Language',
      'GMA':'Guaymi (Atlantic)',
      'GMP':'Guaymi (Pacific)',
      'GUR':'Guéré',
      'GRZ':'Guerze',
      'GNC':'Guianese Creole',
      'GU':'Gujarati',
      'EG':'Gun',
      'GNB':'Gun (Benin)',
      'UN':'Guna',
      'CR':'Haitian Creole',
      'CHH':'Hakka (Taiwan)',
      'HLA':'Halia',
      'HA':'Hausa',
      'HVU':'Havu',
      'HWP':'Hawai’i Pidgin',
      'HW':'Hawaiian',
      'HWU':'Hawu',
      'HY':'Haya',
      'Q':'Hebrew',
      'HH':'Hehe',
      'HR':'Herero',
      'HV':'Hiligaynon',
      'HI':'Hindi',
      'MO':'Hiri Motu',
      'HMN':'Hmong (Green)',
      'HM':'Hmong (White)',
      'SHO':'Honduras Sign Language',
      'HSL':'Hong Kong Sign Language',
      'HPI':'Hopi',
      'HST':'Huastec (San Luis Potosi)',
      'HSV':'Huastec (Veracruz)',
      'HUV':'Huave (San Mateo del Mar)',
      'HU':'Hula',
      'H':'Hungarian',
      'HDF':'Hungarian Sign Language',
      'HSK':'Hunsrik',
      'IAA':'Iaai',
      'IBL':'Ibaloi',
      'IA':'Iban',
      'IG':'Ibanag',
      'IBI':'Ibinda',
      'IC':'Icelandic',
      'ID':'Idoma',
      'IF':'Ifugao',
      'AA':'Igala',
      'IB':'Igbo',
      'IGE':'Igede',
      'IJ':'Ijaw',
      'IL':'Iloko',
      'IKN':'Inakeanon',
      'INS':'Indian Sign Language',
      'IN':'Indonesian',
      'INI':'Indonesian Sign Language',
      'INB':'Inga',
      'ING':'Ingush',
      'IUR':'Inuktitut (Roman)',
      'IU':'Inuktitut (Syllabics)',
      'GC':'Irish',
      'ISG':'Irish Sign Language',
      'IS':'Isoko',
      'QSL':'Israeli Sign Language',
      'I':'Italian',
      'ISL':'Italian Sign Language',
      'ITW':'Itawit',
      'IT':'Itsekiri',
      'IMN':'Iu Mien',
      'IV':'Ivatan',
      'IXL':'Ixil',
      'JCR':'Jamaican Creole',
      'JML':'Jamaican Sign Language',
      'J':'Japanese',
      'JSL':'Japanese Sign Language',
      'JA':'Javanese',
      'JVN':'Javanese (Eastern)',
      'JWK':'Jiwaka',
      'JL':'Jula',
      'KBR':'Kabardin-Cherkess',
      'KAB':'Kabiye',
      'KBV':'Kabuverdianu',
      'KBY':'Kabyle',
      'AH':'Kachin',
      'KAZ':'Kadazan',
      'KGG':'Kaingang',
      'KL':'Kalanga (Botswana)',
      'KLZ':'Kalanga (Zimbabwe)',
      'KJ':'Kalenjin',
      'KLK':'Kalmyk',
      'KMY':'Kamayo',
      'KMB':'Kambera',
      'KMW':'Kamwe',
      'KNY':'Kankanaey',
      'KA':'Kannada',
      'KYK':'Kanyok',
      'KPM':'Kapingamarangi',
      'BAL':'Karachay-Balkar',
      'KPN':'Karen (Pwo Northern)',
      'PK':'Karen (Pwo Western)',
      'KR':'Karen (S\'gaw)',
      'KSH':'Kashubian',
      'KEK':'Kayan',
      'AZ':'Kazakh',
      'AZA':'Kazakh (Arabic)',
      'KEI':'Kei',
      'GK':'Kekchi',
      'KLE':'Kele',
      'KEM':'Kemak',
      'KSI':'Kenyan Sign Language',
      'KC':'Ketchi',
      'KHK':'Khakass',
      'OG':'Khana',
      'KS':'Khasi',
      'KMN':'Khmer (Northern)',
      'KB':'Kikamba',
      'KD':'Kikaonde',
      'KG':'Kikongo',
      'KGL':'Kikongo ya Leta',
      'KQ':'Kikuyu',
      'KU':'Kiluba',
      'MNN':'Kimanyanga',
      'KIM':'Kimbundu',
      'KIN':'Kinande',
      'KNR':'Kinaray-a',
      'KK':'Kinyakyusa',
      'YW':'Kinyarwanda',
      'KIP':'Kipende',
      'KZ':'Kirghiz',
      'GB':'Kiribati',
      'RU':'Kirundi',
      'KI':'Kisi',
      'II':'Kisii',
      'KSN':'Kisonge',
      'KIT':'Kituba',
      'KM':'Komi',
      'KMP':'Komi-Permyak',
      'MK':'Kongo',
      'KND':'Konkani (Devanagari)',
      'KN':'Konkani (Kannada)',
      'KT':'Konkani (Roman)',
      'KO':'Korean',
      'KOC':'Korean (China)',
      'KSL':'Korean Sign Language',
      'OS':'Kosraean',
      'KTI':'Koti',
      'KP':'Kpelle',
      'KRI':'Krio',
      'RI':'Kriol',
      'KRY':'Kryashen',
      'KUA':'Kuanua',
      'KHN':'Kuhane (Subiya)',
      'KUM':'Kuman',
      'KMK':'Kumyk',
      'KNM':'Kunama',
      'RD':'Kurdish Kurmanji',
      'RDU':'Kurdish Kurmanji (Caucasus)',
      'RDC':'Kurdish Kurmanji (Cyrillic)',
      'RDA':'Kurdish Sorani',
      'KUR':'Kuria',
      'KWA':'Kwaio',
      'KH':'Kwale',
      'WG':'Kwangali',
      'KY':'Kwanyama',
      'KW':'Kwara\'ae',
      'NKN':'Kyangonde',
      'LAD':'Ladin (Gardenese)',
      'LAH':'Lahu',
      'LAK':'Lak',
      'LKT':'Lakota',
      'LLB':'Lala-Bisa',
      'AB':'Lamba',
      'LMB':'Lambya',
      'LN':'Lango',
      'LA':'Laotian',
      'LR':'Lari',
      'LTG':'Latgalian',
      'LT':'Latvian',
      'LSL':'Latvian Sign Language',
      'LGA':'Lega',
      'LL':'Lele',
      'LND':'Lendu',
      'LJ':'Lenje',
      'LZ':'Lezgian',
      'LHK':'Lhukonzo',
      'ELI':'Liberian English',
      'LBM':'Limbum',
      'LI':'Lingala',
      'LIN':'Lingombe',
      'LIS':'Lisu',
      'L':'Lithuanian',
      'LTS':'Lithuanian Sign Language',
      'LGO':'Logo',
      'LLO':'Lolo',
      'OM':'Loma',
      'LOM':'Lomongo',
      'LE':'Lomwe',
      'LGD':'Longuda',
      'LWX':'Low German',
      'LC':'Luchazi',
      'LU':'Luganda',
      'LG':'Lugbara',
      'LY':'Luhya',
      'LD':'Lunda',
      'LO':'Luo',
      'LV':'Luvale',
      'LX':'Luxembourgish',
      'MS':'Maasai',
      'MC':'Macedonian',
      'MAC':'Macua',
      'MCS':'Macushi',
      'TTM':'Madagascar Sign Language',
      'MDI':'Madi',
      'MDR':'Madura',
      'MU':'Maguindanao',
      'OR':'Mahorian (Arabic)',
      'ORR':'Mahorian (Roman)',
      'MHL':'Maithili',
      'MKA':'Makaa',
      'MKS':'Makasae',
      'MKO':'Makasae (Ossu)',
      'MWM':'Makhuwa-Meetto',
      'MHS':'Makhuwa-Shirima',
      'MKD':'Makonde',
      'MG':'Malagasy',
      'MLG':'Malango',
      'MSL':'Malawi Sign Language',
      'ML':'Malay',
      'MY':'Malayalam',
      'BIM':'Malaysian Sign Language',
      'MT':'Maltese',
      'MZ':'Mam',
      'AMA':'Mamasa',
      'MMB':'Mambae',
      'MMA':'Mambae (Aileu)',
      'MMS':'Mambae (Ainaro and Same)',
      'MME':'Mambae (Ermera)',
      'MWL':'Mambwe-Lungu',
      'MNM':'Manado Malay',
      'MNK':'Mandinka',
      'MJK':'Mandjak',
      'MGR':'Mangareva',
      'MGB':'Mangbetu',
      'MKE':'Maninkakan (Eastern)',
      'MI':'Manipuri',
      'MKY':'Mankanya',
      'MNO':'Mano',
      'MYW':'Manyawa',
      'MA':'Maori',
      'MPD':'Mapudungun',
      'OL':'Maragoli',
      'MW':'Maranaw',
      'MR':'Marathi',
      'MRB':'Marba',
      'MAR':'Mari',
      'MQ':'Marquesian (Hiva Oa)',
      'MQN':'Marquesian (Nuku Hiva)',
      'MH':'Marshallese',
      'MTC':'Martiniquan Creole',
      'MSH':'Mashi',
      'CE':'Mauritian Creole',
      'MAY':'Maya',
      'MAP':'Maya (Mopán)',
      'MYG':'Mayangna',
      'MYO':'Mayo',
      'MZH':'Mazahua',
      'MAZ':'Mazatec (Huautla)',
      'MBL':'Mbala',
      'MBO':'Mbo',
      'MBK':'Mbukushu',
      'MBD':'Mbunda',
      'DU':'Medumba',
      'MLP':'Melpa',
      'ME':'Mende',
      'MWI':'Mentawai',
      'UU':'Meru',
      'MTA':'Meta\'',
      'LSM':'Mexican Sign Language',
      'CHW':'Min Nan (Taiwan)',
      'MGL':'Mingrelian',
      'MIS':'Miskito',
      'MX':'Mixe (North Central)',
      'MXC':'Mixtec (Costa)',
      'MXG':'Mixtec (Guerrero)',
      'MXO':'Mixtec (Huajuapan)',
      'MXN':'Mixtec (Tilantongo)',
      'MXT':'Mixtec (Tlaxiaco)',
      'LS':'Mizo',
      'MNC':'Mnong (Central)',
      'MBA':'Moba',
      'MHK':'Mohawk',
      'MKL':'Mokilese',
      'MOK':'Moksha',
      'KHA':'Mongolian',
      'MSR':'Mongolian Sign Language',
      'ON':'Montagnais',
      'MNT':'Montenegrin',
      'MM':'Moore',
      'MOR':'Moru',
      'MTU':'Motu',
      'OU':'Moundang',
      'MOU':'Moussey',
      'SLM':'Mozambican Sign Language',
      'BU':'Myanmar',
      'BUS':'Myanmar Sign Language',
      'NHC':'Nahuatl (Central)',
      'NHG':'Nahuatl (Guerrero)',
      'NHH':'Nahuatl (Huasteca)',
      'NHT':'Nahuatl (Northern Puebla)',
      'NHV':'Nahuatl (Veracruz)',
      'NA':'Nama',
      'NMK':'Namakura',
      'NB':'Nambya',
      'NNG':'Nangjere',
      'NTU':'Natügu',
      'NTE':'Nauete',
      'NR':'Nauruan',
      'NV':'Navajo',
      'NDL':'Ndali',
      'NDA':'Ndau',
      'NBL':'Ndebele',
      'NBZ':'Ndebele (Zimbabwe)',
      'OD':'Ndonga',
      'NE':'Nembe',
      'NNF':'Nenets (Forest)',
      'RE':'Nengone',
      'NP':'Nepali',
      'NSL':'Nepali Sign Language',
      'NZS':'New Zealand Sign Language',
      'NW':'Newari',
      'NGB':'Ngabere',
      'NG':'Ngambaye',
      'NGL':'Ngangela',
      'NGK':'Ngbaka',
      'NGN':'Ngbandi (Northern)',
      'NMB':'Ngiemboon',
      'NGM':'Ngigua (San Marcos Tlacoyalco)',
      'NML':'Ngombale',
      'NGU':'Nhengatu',
      'NI':'Nias',
      'LSN':'Nicaraguan Sign Language',
      'NC':'Nicobarese',
      'NGP':'Nigerian Pidgin',
      'NN':'Niuean',
      'NVC':'Nivaclé',
      'NJB':'Njebi',
      'NKY':'Nkoya',
      'NOR':'Northern Ojibwe (Roman)',
      'NOS':'Northern Ojibwe (Syllabics)',
      'N':'Norwegian',
      'NDF':'Norwegian Sign Language',
      'NSM':'Nsenga (Mozambique)',
      'NEN':'Nsenga (Zambia)',
      'UE':'Nuer',
      'NKM':'Nukumanu',
      'NKR':'Nukuoro',
      'KWN':'Numèè (Kwenyii)',
      'YM':'Nyamwezi',
      'NK':'Nyaneka',
      'NY':'Nyiha',
      'NYU':'Nyungwe',
      'NZ':'Nzema',
      'OBL':'Obolo',
      'ODW':'Odawa',
      'OI':'Odia',
      'OKP':'Okpe',
      'LB':'Olunyole',
      'OMB':'Ombamba',
      'ORK':'Orokaiva',
      'OA':'Oromo',
      'OSS':'Ossetian',
      'OT':'Otetela',
      'OTE':'Otomi (Eastern Highland)',
      'OTM':'Otomi (Mezquital Valley)',
      'OTS':'Otomi (State of Mexico)',
      'PAZ':'Páez',
      'PIC':'Paicî',
      'PWN':'Paiwan',
      'PU':'Palauan',
      'PLK':'Palikúr',
      'PMO':'Pamona',
      'PM':'Pampango',
      'PSL':'Panamanian Sign Language',
      'PN':'Pangasinan',
      'PPL':'Papel',
      'PAA':'Papiamento (Aruba)',
      'PA':'Papiamento (Curaçao)',
      'LSP':'Paraguayan Sign Language',
      'PH':'Pashto',
      'PT':'Paumotu',
      'PHN':'Pehuenche',
      'PMN':'Pemon',
      'XPA':'Pennsylvania German',
      'PR':'Persian',
      'SPE':'Peruvian Sign Language',
      'PHM':'Phimbi',
      'PRA':'Piaroa',
      'PCM':'Pidgin (Cameroon)',
      'PGW':'Pidgin (West Africa)',
      'PLG':'Pilagá',
      'PTJ':'Pitjantjatjara',
      'POK':'Pokot',
      'P':'Polish',
      'PDF':'Polish Sign Language',
      'PMR':'Pomeranian',
      'PP':'Ponapean',
      'JC':'Popti\'',
      'T':'Portuguese (Brazil)',
      'TPO':'Portuguese (Portugal)',
      'LGP':'Portuguese Sign Language',
      'PL':'Pulaar',
      'PLR':'Pular',
      'PJ':'Punjabi',
      'PJN':'Punjabi (Shahmukhi)',
      'PUN':'Punu',
      'PYM':'Puyuma',
      'QNJ':'Q\'anjob\'al',
      'LSQ':'Quebec Sign Language',
      'QUN':'Quechua (Ancash)',
      'QUA':'Quechua (Ayacucho)',
      'QUB':'Quechua (Bolivia)',
      'QU':'Quechua (Cuzco)',
      'QUL':'Quechua (Huallaga Huánuco)',
      'QUH':'Quechua (Huaylla Wanca)',
      'QC':'Quiche',
      'QI':'Quichua',
      'QCC':'Quichua (Cañar)',
      'QIB':'Quichua (Chibuleo)',
      'QIC':'Quichua (Chimborazo)',
      'QII':'Quichua (Imbabura)',
      'QIP':'Quichua (Pastaza)',
      'QCS':'Quichua (Salasaca)',
      'QIS':'Quichua (Santiago del Estero)',
      'QIT':'Quichua (Tena)',
      'RPN':'Rapa Nui',
      'RA':'Rarotongan',
      'RCR':'Réunion Creole',
      'M':'Romanian',
      'RNN':'Romanian (Vlach)',
      'LMG':'Romanian Sign Language',
      'RH':'Romansh (Ladin)',
      'RHS':'Romansh (Sursilvan)',
      'RML':'Romany (Albania)',
      'RMA':'Romany (Argentina)',
      'RMB':'Romany (Bulgaria)',
      'CRM':'Romany (Czech Republic)',
      'RMS':'Romany (Eastern Slovakia)',
      'RMX':'Romany (Germany)',
      'RMK':'Romany (Kalderash, Russia)',
      'LVA':'Romany (Lovari, Hungary)',
      'RM':'Romany (Macedonia)',
      'RMC':'Romany (Macedonia) Cyrillic',
      'RVC':'Romany (Moldova) Cyrillic',
      'RMN':'Romany (Northern Greece)',
      'RMO':'Romany (Olah)',
      'RMT':'Romany (Poland)',
      'RMR':'Romany (Romania)',
      'RME':'Romany (Serbia)',
      'RMG':'Romany (Southern Greece)',
      'RMU':'Romany (Ukraine)',
      'RMV':'Romany (Vlax, Russia)',
      'RN':'Ronga',
      'RO':'Rotuman',
      'RV':'Roviana',
      'RKI':'Rukai',
      'RNY':'Rumanyo',
      'RGS':'Rungus',
      'RR':'Runyankore',
      'U':'Russian',
      'RSL':'Russian Sign Language',
      'RT':'Rutoro',
      'LP':'Saami (North)',
      'LUC':'Saint Lucian Creole',
      'SKL':'Sakalava',
      'SAL':'Saluan',
      'PS':'Samal',
      'SBR':'Samburu',
      'LH':'Samia',
      'SM':'Samoan',
      'SGA':'Sanga',
      'SGR':'Sangir',
      'SG':'Sango',
      'STN':'Sãotomense',
      'SAR':'Sar',
      'SRM':'Saramaccan',
      'SRI':'Sarnami',
      'STM':'Sateré-Mawé',
      'GCS':'Scottish Gaelic',
      'SHW':'Sehwi',
      'SEN':'Sena',
      'SFC':'Senoufo (Cebaara)',
      'SE':'Sepedi',
      'SPL':'Sepulana',
      'SB':'Serbian (Cyrillic)',
      'SBO':'Serbian (Roman)',
      'SBS':'Serbian Sign Language',
      'ER':'Serer',
      'SU':'Sesotho (Lesotho)',
      'SSA':'Sesotho (South Africa)',
      'TN':'Setswana',
      'SC':'Seychelles Creole',
      'SHA':'Shan',
      'SHC':'Shipibo-Conibo',
      'CA':'Shona',
      'SHU':'Shuar',
      'SGH':'Shughni',
      'DM':'Sidama',
      'SSN':'Silesian',
      'SK':'Silozi',
      'ND':'Sindhi (Arabic)',
      'SN':'Sinhala',
      'V':'Slovak',
      'VSL':'Slovak Sign Language',
      'SV':'Slovenian',
      'SZJ':'Slovenian Sign Language',
      'SOL':'Soli',
      'SP':'Solomon Islands Pidgin',
      'SO':'Somali',
      'SGM':'Songomeno',
      'SAS':'South African Sign Language',
      'S':'Spanish',
      'SSP':'Spanish (Spain)',
      'LSE':'Spanish Sign Language',
      'SR':'Sranantongo',
      'SLS':'Sri Lankan Sign Language',
      'UK':'Sukuma',
      'SD':'Sunda',
      'SRG':'Surigaonon',
      'SSU':'Suriname Sign Language',
      'SUS':'Susu',
      'SVL':'Svan (Lower)',
      'SVN':'Svan (Upper)',
      'SW':'Swahili',
      'ZS':'Swahili (Congo)',
      'SWI':'Swati',
      'Z':'Swedish',
      'SSL':'Swedish Sign Language',
      'XSW':'Swiss German',
      'SGS':'Swiss German Sign Language',
      'SYL':'Sylheti (Bengali)',
      'TBW':'Taabwa',
      'TBN':'Tabasaran',
      'TG':'Tagalog',
      'TH':'Tahitian',
      'KE':'Tairuma',
      'AT':'Taita',
      'TSL':'Taiwanese Sign Language',
      'TJ':'Tajiki',
      'TAL':'Talian',
      'TLY':'Talysh',
      'TYC':'Talysh (Cyrillic)',
      'TMZ':'Tamazight',
      'TL':'Tamil',
      'TND':'Tandroy',
      'TT':'Tangkhul',
      'TNK':'Tankarana',
      'TRH':'Tarahumara (Central)',
      'TRS':'Tarascan',
      'YG':'Tarok',
      'TAT':'Tatar',
      'TA':'Tausug',
      'TEB':'Teke (Congo Brazzaville)',
      'TEK':'Teke (Congo Kinshasa)',
      'TU':'Telugu',
      'TTB':'Tetun Belu',
      'TTP':'Tetun Dili',
      'TEW':'Tewe',
      'SI':'Thai',
      'SIE':'Thai (Northeastern)',
      'SIR':'Thai (Northern)',
      'SIL':'Thai Sign Language',
      'TMN':'Themne',
      'TBT':'Tibetan',
      'TCN':'Ticuna',
      'TI':'Tigrinya',
      'TII':'Tii',
      'TV':'Tiv',
      'TLN':'Tlapanec',
      'OB':'To\'abaita',
      'TR':'Toaripi',
      'TOB':'Toba',
      'TJO':'Tojolabal',
      'MP':'Tok Pisin',
      'OE':'Tokelauan',
      'TO':'Tongan',
      'TOR':'Toraja',
      'TSC':'Torres Strait Creole',
      'TOT':'Totonac',
      'TPR':'Toupouri',
      'SH':'Tshiluba',
      'AW':'Tshwa',
      'TSM':'Tsimané',
      'TS':'Tsonga',
      'TRN':'Turkana',
      'TK':'Turkish',
      'TKC':'Turkish (Cyrillic)',
      'TKL':'Turkish Sign Language',
      'TMR':'Turkmen',
      'TM':'Turkmen (Cyrillic)',
      'VL':'Tuvaluan',
      'VI':'Tuvinian',
      'TW':'Twi',
      'TWK':'Twi (Akuapem)',
      'TWS':'Twi (Asante)',
      'TYW':'Tyrewuju',
      'TZE':'Tzeltal',
      'TZO':'Tzotzil',
      'TZU':'Tzutujil',
      'UBM':'Uab Meto',
      'UM':'Udmurt',
      'UGA':'Uighur (Arabic)',
      'UG':'Uighur (Cyrillic)',
      'K':'Ukrainian',
      'UB':'Umbundu',
      'UD':'Urdu',
      'UR':'Urhobo',
      'LSU':'Uruguayan Sign Language',
      'DR':'Uruund',
      'UZ':'Uzbek',
      'UZR':'Uzbek (Roman)',
      'VLC':'Valencian',
      'VE':'Venda',
      'LSV':'Venezuelan Sign Language',
      'VZ':'Vezo',
      'VT':'Vietnamese',
      'SLV':'Vietnamese Sign Language',
      'VRU':'Voru',
      'WAM':'Wa',
      'WMA':'Waima',
      'WMO':'Waimoa',
      'WA':'Wallisian',
      'LW':'Wanga',
      'WPH':'Wapishana',
      'WRO':'Warao',
      'SA':'Waray-Waray',
      'WY':'Wayuunaiki',
      'WJW':'Wejewa',
      'W':'Welsh',
      'WCH':'Wichi',
      'HCH':'Wixárika',
      'WL':'Wolaita',
      'WLN':'Woleaian',
      'WO':'Wolof',
      'XRC':'Xârâcùù',
      'XV':'Xavante',
      'XO':'Xhosa',
      'YCB':'Yacouba',
      'YAK':'Yaka',
      'YK':'Yakutsk',
      'YNS':'Yansi',
      'YP':'Yapese',
      'YQ':'Yaqui',
      'BM':'Yemba',
      'YMB':'Yombe',
      'YON':'Yonggom',
      'YR':'Yoruba',
      'YNG':'Yuanga',
      'YKP':'Yukpa',
      'ZAS':'Zambian Sign Language',
      'ZN':'Zande',
      'ZPD':'Zapotec (del Valle)',
      'ZPG':'Zapotec (Guevea)',
      'ZPI':'Zapotec (Isthmus)',
      'ZPX':'Zapotec (Ixtlán)',
      'ZPL':'Zapotec (Lachiguiri)',
      'ZPT':'Zapotec (Quiatoni)',
      'ZPC':'Zapotec (Quiavicuzas)',
      'ZPV':'Zapotec (Villa Alta)',
      'ZR':'Zarma',
      'ZSL':'Zimbabwe Sign Language',
      'ZU':'Zulu'
    };

    export function byName(): {[index: string]: string} {
      const results: {[index: string]: string} = {};
      Object.keys(map).forEach(k => {
        results[map[k]] = k;
      });
      return results;
    }

    export function toListValue(): SelectItem[] {
      const results: SelectItem[] = [];
      Object.keys(map).forEach(k => {
        results.push({ label: map[k], value: k });
      });
      return results;
    }

    export function getName(key: string): string {
      return map[key];
    }
}