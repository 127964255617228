export const paths = {
  circuits: 'circuits',
  congregation: 'congregation',
  addCongregation: 'congregation/add',
  editCongregation: 'congregation/:congregationNumber',
  volunteers: 'congregation/:congregationNumber/volunteers',
  volunteer: 'volunteer/:volunteerId',
  addVolunteer: 'congregation/:congregationNumber/volunteers/add',
  editVolunteer: 'congregation/:congregationNumber/volunteers/:volunteerId',
};
