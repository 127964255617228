<ul class="space-y-2">
    <li>
        <a href="#" (click)="hideMenu()" class="flex items-center p-4 text-base font-normal rounded-lg text-gray-800 dark:text-gray-100 hover:bg-primary"
           routerLink="/" routerLinkActive="active">
            <span class="mdi mdi-home-variant-outline fa-lg"></span>
            <span class="ml-2">Home</span>
         </a>
    </li>
   <li *ngFor="let navItem of menuItems">
        <a routerLinkActive="active" (click)="hideMenu()" [routerLink]="[navItem.path]" class="flex items-center p-4 text-base font-normal rounded-lg text-gray-800 dark:text-gray-100 hover:bg-primary">
            <span class="mdi mdi-{{ navItem.icon }} mr-2 fa-lg"></span>
            {{navItem.title}}
        </a>
    </li>
    <li *ngIf="isLoggedIn">
        <a routerLink="/settings" (click)="hideMenu()" routerLinkActive="active" class="flex items-center p-4 text-base font-normal rounded-lg text-gray-800 dark:text-gray-100 hover:bg-primary">
            <span class="mdi mdi-cog-sync-outline mr-2 fa-lg"></span> Settings
        </a>
    </li>
    <li *ngIf="isLoggedIn">
        <a routerLink="/help" (click)="hideMenu()" routerLinkActive="active" class="flex items-center p-4 text-base font-normal rounded-lg text-gray-800 dark:text-gray-100 hover:bg-primary">
            <span class="mdi mdi-face-agent mr-2 fa-lg"></span> Support
        </a>
    </li>
</ul>
