import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Circuit } from '../../../models/circuit';
import { BaseApiService } from 'src/app/core/services/base-api.service';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class CircuitService
  extends BaseApiService {

  constructor(http: HttpClient, authService: MsalService, @Inject('BASE_URL') baseUrl: string) {
    super(http, authService, 'beta', 'circuits', baseUrl);
  }

  getCircuits(): Promise<Circuit[]> {
    return this.get<Circuit[]>('');
  }

  getCircuitsByState(stateCode: string): Promise<Circuit[]> {
    return this.get<Circuit[]>(stateCode);
  }

  getCircuit(circuitId: number): Promise<Circuit> {
    return this.get<Circuit>(circuitId.toString());
  }

  updateCircuit(entity: Circuit): Promise<Circuit> {
    return this.put<Circuit>('', entity);
  }

  createCircuit(entity: Circuit): Promise<Circuit> {
    return this.post('', entity);
  }

  deleteCircuit(entity: Circuit): Promise<Circuit> {
    return this.delete(entity.circuitId.toString());
  }
}
