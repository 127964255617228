import { Injectable, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Injectable()
export class ErrorDialogService implements OnDestroy {

  ref: DynamicDialogRef;
  dialogCount: number;

  constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
  ) {
    this.dialogCount = 0;
  }

  openDialog(message: string, status?: number): void {
    if (this.dialogCount > 0) {
      console.debug('supressing multiple error dialogs');
      return;
    }

    this.dialogCount++;
    console.debug(`dialog count [${this.dialogCount}]`);
    console.debug(`status [${status}]`);

    let title = 'Something went wrong!';
    switch(status) {
    case 400:
      // This is a bad request from the API
      title = 'Validation Error';
      break;
    case 401:
      title = 'Authorization Error';
      break;
    case 500:
      if (message.includes('VolunteerNotRegisteredException')) {
        title = 'Volunteer Not Registered';
        message = 'You are not registered as a volunteer. Please contact your administrator.';
      }
      break;
    }

    console.debug('Adding message');
    this.ref = this.dialogService.open(ErrorDialogComponent, {
      header: title,
      width: '640px',
      data: { message, title, status },
    });

    this.ref.onClose.subscribe(() => {
      this.dialogCount--;
    });
  }

  ngOnDestroy(): void {
    if (this.ref) {
      this.ref.close();
    }

    this.dialogCount--;
  }
}
