import { SelectItem } from 'primeng/api';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-namespace */
export namespace States {
    const map: {[index: string]: string} = {
      '': 'All States',
      'AL': 'Alabama',
      'AK': 'Alaska',
      'AS': 'American Samoa',
      'AZ': 'Arizona',
      'AR': 'Arkansas',
      'CA': 'California',
      'CO': 'Colorado',
      'CT': 'Connecticut',
      'DE': 'Delaware',
      'DC': 'District Of Columbia',
      //"FM": "Federated States Of Micronesia",
      'FL': 'Florida',
      'GA': 'Georgia',
      //"GU": "Guam",
      'HI': 'Hawaii',
      'ID': 'Idaho',
      'IL': 'Illinois',
      'IN': 'Indiana',
      'IA': 'Iowa',
      'KS': 'Kansas',
      'KY': 'Kentucky',
      'LA': 'Louisiana',
      'ME': 'Maine',
      //"MH": "Marshall Islands",
      'MD': 'Maryland',
      'MA': 'Massachusetts',
      'MI': 'Michigan',
      'MN': 'Minnesota',
      'MS': 'Mississippi',
      'MO': 'Missouri',
      'MT': 'Montana',
      'NE': 'Nebraska',
      'NV': 'Nevada',
      'NH': 'New Hampshire',
      'NJ': 'New Jersey',
      'NM': 'New Mexico',
      'NY': 'New York',
      'NC': 'North Carolina',
      'ND': 'North Dakota',
      //"MP": "Northern Mariana Islands",
      'OH': 'Ohio',
      'OK': 'Oklahoma',
      'OR': 'Oregon',
      //"PW": "Palau",
      'PA': 'Pennsylvania',
      //"PR": "Puerto Rico",
      'RI': 'Rhode Island',
      'SC': 'South Carolina',
      'SD': 'South Dakota',
      'TN': 'Tennessee',
      'TX': 'Texas',
      'UT': 'Utah',
      'VT': 'Vermont',
      'VI': 'Virgin Islands',
      'VA': 'Virginia',
      'WA': 'Washington',
      'WV': 'West Virginia',
      'WI': 'Wisconsin',
      'WY': 'Wyoming'
    };

    export function byAbbreviation(): {[index: string]: string} {
      return map;
    }

    export function byName(): {[index: string]: string} {
      const results: {[index: string]: string} = {};
      Object.keys(map).forEach(k => {
        results[map[k]] = k;
      });
      return results;
    }

    export function toListValue(): SelectItem[] {
      const results: SelectItem[] = [];
      Object.keys(map).forEach(k => {
        results.push({ label: map[k], value: k });
      });
      return results;
    }

    export function getName(key: string): string {
      return map[key];
    }
}