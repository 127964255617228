/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  MsalBroadcastService,
  MsalCustomNavigationClient,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { roles } from 'src/app/features/security/roles';
import { JobRole } from 'src/app/features/volunteers/assignments/jobRoles';
import { Job } from 'src/app/features/volunteers/assignments/jobs';
import { WeekendRoleAssignment } from '../../models/weekendRoleAssignment';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserAccountService implements OnInit {
  loggedIn = false;
  usersName = '';
  groups: string[];

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    }),
  };

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private router: Router,
    private location: Location,
    private msalBroadcastService: MsalBroadcastService,
    protected http: HttpClient,
  ) {
    // Custom navigation set for client-side navigation.
    // See performance doc for details: https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-angular/docs/v2-docs/performance.md
    const customNavigationClient = new MsalCustomNavigationClient(
      authService,
      this.router,
      this.location,
    );
    this.authService.instance.setNavigationClient(customNavigationClient);
  }

  ngOnInit(): void {
    this.checkAccount();
  }
  getAccount(): any {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      activeAccount = accounts[0];
    }

    return activeAccount;
  }

  isAccountInGroup(group: string): boolean {
    this.checkAccount();
    if (!this.loggedIn) {
      return false;
    }

    const groups = this.getGroups();
    if (groups) {
      return groups.includes(group);
    } else {
      console.error('Unable to get claims for the current user');
      return false;
    }
  }
  private getGroups(): string[] {
    if (!this.groups || this.groups === null) {
      const currentUser = this.getAccount();
      if (currentUser.idTokenClaims) {
        this.groups = currentUser.idTokenClaims['groups'];
      } else {
        this.groups = [];
      }
    }
    return this.groups;
  }
  hasGradeAccess(weekendRoleAssignment: WeekendRoleAssignment): boolean {
    if (this.isAccountInGroup(roles.superUser)) {
      return true;
    }
    if (!weekendRoleAssignment) {
      // If there is no assignment, assume true and let the formatting
      // show the right value.
      return true;
    }
    const jobId = weekendRoleAssignment.conventionJob?.conventionJobId ?? 0;
    const roleId =
      weekendRoleAssignment.conventionJobRole?.conventionJobRoleId ?? 0;

    if (this.isAccountInGroup(roles.committeeMember)) {
      return (
        jobId >= Job.CommitteeCoordination && roleId > JobRole.CommitteeMember
      );
    }
    if (this.isAccountInGroup(roles.committeeAssistant)) {
      return jobId > Job.CommitteeCoordination;
    }
    if (this.isAccountInGroup(roles.technicalContact)) {
      return (
        jobId >= Job.CommitteeCoordination && roleId > JobRole.CommitteeMember
      );
    }

    return false;
  }

  checkAccount(): void {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  /**
   * If logged in, gets and sets the account full name.
   * @returns string
   */
  userName(): string {
    this.usersName = this.loggedIn
      ? this.authService.instance.getActiveAccount().name
      : '';
    return this.usersName;
  }

  subscribeToLogin(next?: (value: any) => void): Subscription {
    return this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS,
        ),
      )
      .subscribe(next);
  }

  login(): void {
    this.loginPopup();
  }

  loginRedirect(): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  loginPopup(): void {
    this.authService.instance.initialize();
    if (this.msalGuardConfig.authRequest) {
      this.authService
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService
        .loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }

  logout(popup?: boolean): void {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }
}
