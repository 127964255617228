<div class="flex flex-col h-screen justify-between">
    <app-header class="wrapper" targetId="sidebar"></app-header>
    <div class="flex flex-grow">
        <aside id="sidebar"
            class="flex-none w-72 bg-jw-gray-100 dark:bg-jw-gray-900 duration-300 absolute lg:ml-0 md:relative z-50"
            aria-label="Sitebar">
            <div class="flex-none w-72 overflow-y-auto py-4 px-3">
                <app-nav-menu></app-nav-menu>
                <app-current-year-widget></app-current-year-widget>
            </div>
        </aside>
        <div id="content" class="bg-jw-gray-10 dark:bg-black text-black dark:text-white w-full h-full">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-footer></app-footer>
    <p-toast></p-toast>
</div>