import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { paths } from './search-paths';
import { roles } from '../../features/security/roles';
import { MsalGuardGroupService } from 'src/app/core/guards/msal-guard-group.service';
import { PageSearchComponent } from './pages/page-search/page-search.component';

const routes: Routes = [
  {
    path: paths.search,
    component: PageSearchComponent,
    canActivate: [MsalGuardGroupService],
    data: {
      title: 'Search',
      icon: 'account-search-outline', sortOrder: 100,
      expectedRoles: [ roles.technicalContact, roles.committeeMember, roles.committeeAssistant ],
      includeInMenu: true
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
  })
export class SearchRoutingModule { }
