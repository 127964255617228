import { SelectItem } from 'primeng/api';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-namespace */
export namespace WeekendTypes{
    const map: {[index: number]: string} = {
      1: 'Regional',
      2: 'Special',
      3: 'International',
    };

    export function byName(): {[index: number]: string} {
      const results: {[index: number]: string} = {};
      Object.keys(map).forEach(k => {
        results[map[k]] = k;
      });
      return results;
    }

    export function toListValue(): SelectItem[] {
      const results: SelectItem[] = [];
      Object.keys(map).forEach(k => {
        results.push({ label: map[k], value: parseInt(k) });
      });
      return results;
    }

    export function getName(key: string): string {
      return map[key];
    }
}
