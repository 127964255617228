import { Component, Input } from '@angular/core';
import { ValidationService } from '../../../core/services/validation.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-control-messages',
  template: `
    <div *ngIf="errorMessage !== null" class="invalid-feedback">
     <sup>*</sup>{{errorMessage}}
    </div>
    <div *ngIf="errorMessage == null" class="valid-feedback">
     <sup>*</sup> Looks Good!
    </div>
  `
})
export class ControlMessagesComponent {
  @Input() control: FormControl;

  get errorMessage(): string {
    for (let propertyName in this.control.errors){
      if (
        this.control.errors.hasOwnProperty(propertyName) &&
        this.control.touched
      ) {
        return ValidationService.getValidatorErrorMessage(
          propertyName,
          this.control.errors[propertyName]
        );
      }
    }

    return null;
  }
}
