<footer class="text-center lg:text-left bg-jw-gray-100 dark:bg-jw-gray-900 text-gray-600 dark:text-white">
    <div class="flex justify-center items-center lg:justify-between p-6 border-b border-gray-300">
        <div class="mr-12 hidden lg:block">
            <span>version [{{version}}.0][{{environment}}]</span>
        </div>
        <div class="flex justify-center text-sm">
            <a class="px-2 text-sm hover:underline text-jw-blue-700 dark:text-jw-blue-100" routerLink="/" *ngIf="isLoggedIn">{{currentUser.name}}</a><span *ngIf="isLoggedIn">|</span>
            <a class="px-2 text-sm hover:underline text-jw-blue-700 dark:text-jw-blue-100" routerLink="/help">Help</a>|
            <a class="px-2 text-sm hover:underline text-jw-blue-700 dark:text-jw-blue-100" routerLink="/terms-and-conditions">Terms & Conditions</a>|
            <a class="px-2 text-sm hover:underline text-jw-blue-700 dark:text-jw-blue-100" routerLink="/privacy-policy">Privacy Policy</a>
        </div>
    </div>
</footer>
