import { Pipe, PipeTransform } from '@angular/core';
import { WeekendRoleAssignment } from '../../../models/weekendRoleAssignment';
import { UserAccountService } from '../../../core/services/user-account.service';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'grade'
})
export class GradePipe implements PipeTransform {

  constructor(
    private userAccountService: UserAccountService,
    private numberPipe: DecimalPipe
  ) { }

  transform(gradeValue: number, assignmentCount: number, weekendRoleAssignment: WeekendRoleAssignment): string {
    try {
      let returnValue: string;
      if (!this.userAccountService.hasGradeAccess(weekendRoleAssignment)) {
        returnValue = 'Grade Not Available';
      } else {
        if (gradeValue < 1) {
          returnValue = 'Ungraded';
        } else if (gradeValue <= 1.5) {
          returnValue = 'D';
        } else if (gradeValue <= 2.5) {
          returnValue = 'C';
        } else if (gradeValue <= 3.5) {
          returnValue = 'B';
        } else if (gradeValue <= 4) {
          returnValue = 'A';
        } else {
          if (assignmentCount > 0) {
            returnValue = 'Ungraded';
          }
          returnValue = 'New';
        }

        returnValue = `${returnValue} [${this.numberPipe.transform(gradeValue, '1.1-1')}]`;
      }

      return returnValue;

    } catch (error) {
      console.log(error);
      return 'Ungraded';
    }
  }
}