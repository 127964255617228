export enum Job {
    CommitteeCoordination = 1,
    Program = 2,
    Rooming = 3,
    ChairmansOffice = 4,
    Cleaning = 5,
    Attendants = 6,
    Accounting = 7,
    Parking = 8,
    FirstAid = 9,
    AudioVideo = 10,
    Baptism = 11,
    Installation = 12,
    TruckingAndEquipment = 13,
    LostAndFound = 14,
    InformatoinAndVolunteerServices = 15,
    Safety = 16,
}