import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VolunteersService } from '../../../modules/congregations/services/volunteers.service';
import { Volunteer } from '../../../models/volunteer';
import { CongregationService } from '../../../modules/congregations/services/congregation.service';
import { Congregation } from '../../../models/congregation';
import { ButtonType } from 'src/app/features/components/button/buttonType';
import { SecurityRole } from '../../security/securityRole';
import { LabelType } from '../../components/text-label/text-label.component';

@Component({
  selector: 'app-volunteer-list',
  templateUrl: './volunteer-list.component.html'
})
export class VolunteerListComponent implements OnInit {

  congregationNumber: number;
  congregation: Congregation;
  volunteers: Volunteer[];
  buttonTypes = ButtonType;
  securityRole = SecurityRole;
  labelTypes = LabelType;

  constructor(
    private volunteerService: VolunteersService,
    private congregationService: CongregationService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.congregationNumber = Number(this.route.snapshot.paramMap.get('congregationNumber'));
    if (!isNaN(this.congregationNumber)) {
      this.loadCongregation();
    }
  }

  loadCongregation(): void {
    this.congregationService.getCongregation(this.congregationNumber)
      .then(cong => {
        this.congregation = cong;
        this.loadVolunteers();
      });
  }

  loadVolunteers(): void {
    this.volunteerService.getVolunteers(this.congregationNumber)
      .then(volunteers => this.volunteers = volunteers);
  }

  // addVolunteer(): void {
  //   this.volunteerEditModal.show(0, this.congregation);
  // }

  handleVolunteerSaved(): void {
    console.debug('handleVolunteerSaved');
    this.loadVolunteers();
  }
}
