<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="showForm">
    <div *ngIf="volunteer" class="w-full">
        <div class="mb-6">
            <label for="firstName"
                class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">First name</label>
            <div class="col-8">
                <input
                    class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex w-full p-2.5
                    dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    [ngClass]="{ 'focus:ring-red-500 focus:border-red-500 bg-red-100 border-red-400': submitted && f.firstName.errors }"
                    id="firstName" name="firstName" formControlName="firstName" type="text"/>
                <div *ngIf="submitted && f.firstName.errors" class="mt-2 text-red-700 dark:text-red-500">
                    The volunteers first name is required.
                </div>
            </div>
        </div>
        <div class="mb-6">
            <label for="lastName" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Last
                name</label>
            <div class="col-8">
                <input
                    class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex w-full p-2.5
                    dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    [ngClass]="{ 'focus:ring-red-500 focus:border-red-500 bg-red-100 border-red-400': submitted && f.lastName.errors }"
                    id="lastName" name="lastName" formControlName="lastName" type="text" />
                    <div *ngIf="submitted && f.lastName.errors" class="mt-2 text-red-700 dark:text-red-500">
                        The volunteers last name is required.
                    </div>
            </div>
        </div>
        <div class="mb-6">
            <label for="jwEmail" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">JW
                Email</label>
            <div class="col-8">
                <input
                    class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex w-full p-2.5
                    dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    [ngClass]="{ 'focus:ring-red-500 focus:border-red-500 bg-red-100 border-red-400': submitted && f.jwEmail.errors }"
                    id="jwEmail" name="jwEmail" formControlName="jwEmail" type="email" />
                    <div *ngIf="submitted && f.jwEmail.errors" class="mt-2 text-red-700 dark:text-red-500">
                        The JW Email should is required and needs to be a valid email.
                    </div>
            </div>
        </div>
        <div class="mb-6">
            <label for="personalEmail"
                class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Personal Email</label>
            <div class="col-8">
                <input
                    class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex w-full p-2.5
                    dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    [ngClass]="{ 'focus:ring-red-500 focus:border-red-500 bg-red-100 border-red-400': submitted && f.personalEmail.errors }"
                    id="personalEmail" formControlName="personalEmail" name="personalEmail" type="email" />
                    <div *ngIf="submitted && f.personalEmail.errors" class="mt-2 text-red-700 dark:text-red-500">
                        The personal email is required and needs to be a valid email.
                    </div>
            </div>
        </div>
        <div class="mb-6">
            <label for="phoneNumber"
                class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Phone #</label>
            <div class="col-8">
                <input
                    class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex w-full p-2.5
                    dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    formControlName="phoneNumber" id="phoneNumber" type="text" />
            </div>
        </div>
        <div class="mb-6">
            <label for="selectedYearOfBirth"
                class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Year of Birth</label>
            <div class="col-8">
                <p-dropdown id="yearOfBirth" appendTo="body" placeholder="Select a year of birth" [options]="yearsOfBirth"
                    formControlName="selectedYearOfBirth" inputStyleClass="p-2.5" filter="true">
                </p-dropdown>
                <div *ngIf="submitted && f.selectedYearOfBirth.errors" class="mt-2 text-red-700 dark:text-red-500">
                   Please select a year of birth.
                </div>
            </div>
        </div>
        <div class="mb-6">
            <label for="race"
                class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Race</label>
            <div class="col-8">
                <p-dropdown id="race" appendTo="body" placeholder="Select a race" [options]="races"
                    formControlName="selectedRace" inputStyleClass="p-2.5">
                </p-dropdown>
            </div>
        </div>
        <div class="mb-6">
            <label for="selectedResponsibilities"
                class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Cong.
                Responsibilities</label>
            <div class="col-8">
                <p-multiSelect id="selectedResponsibilities" name="selectedResponsibilities" [options]="responsibilities" inputStyleClass="p-2.5"
                    formControlName="selectedResponsibilities" defaultLabel="Select..." optionLabel="label">
                </p-multiSelect>
            </div>
        </div>
        <div class="form-group">
            <label for="azureAdUserId">Azure User Id</label>
            <input
                class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex w-full p-2.5
                    dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                formControlName="azureAdUserId" id="azureAdUserId" type="text" />
        </div>
    </div>
    <div class="mt-5 align-right">
        <app-button [type]="buttonTypes.Save" label="Save"></app-button>
        <app-button [type]="buttonTypes.Cancel" label="Cancel" (click)="cancel($event)"></app-button>
    </div>
</form>
