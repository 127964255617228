import { Component, OnInit, ViewChild } from '@angular/core';
import { Congregation } from '../../../../models/congregation';
import { ActivatedRoute, Router } from '@angular/router';
import { CongregationService } from '../../services/congregation.service';
import { VolunteerEditComponent } from 'src/app/features/volunteers/volunteer-edit/volunteer-edit.component';
import { Volunteer } from '../../../../models/volunteer';

@Component({
  selector: 'app-page-add-volunteer',
  templateUrl: './page-add-volunteer.component.html',
})
export class PageAddVolunteerComponent implements OnInit {
  @ViewChild('volunteerEditModal') volunteerEditModal: VolunteerEditComponent;

  congregation: Congregation;
  congregationNumber: number;

  volunteer: Volunteer;
  volunteerId: number;

  title: string;

  constructor(
    private congregationService: CongregationService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.volunteerId = Number(this.route.snapshot.paramMap.get('volunteerId'));
    this.congregationNumber = Number(
      this.route.snapshot.paramMap.get('congregationNumber'),
    );

    if (this.volunteerId === 0) {
      this.loadCongregation();
    } else {
      setTimeout(
        () => this.volunteerEditModal.show(this.volunteerId, undefined),
        50,
      );
    }

    this.title = isNaN(this.volunteerId) ? 'Add Volunteer' : 'Edit Volunteer';
  }

  loadCongregation(): void {
    this.congregationService
      .getCongregation(this.congregationNumber)
      .then((cong) => {
        this.congregation = cong;
        this.volunteerEditModal.show(this.volunteerId, cong);
      });
  }

  onSaved(): void {
    this.router.navigate([
      `//congregation/${this.congregationNumber}/volunteers`,
    ]);
  }

  onCancel(): void {
    const route = `//congregation/${this.congregationNumber}/volunteers`;
    this.router.navigate([route]);
  }
}
