import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from 'src/app/core/services/base-api.service';
import { Weekend } from '../../../models/weekend';
import { MsalService } from '@azure/msal-angular';
import { Congregation } from 'src/app/models/congregation';
import { CongregationWeekend } from '../../../models/congregationWeekend';
import { WeekendRoleAssignment } from 'src/app/models/weekendRoleAssignment';
import { WeekendRoleAssignmentGrade } from 'src/app/models/weekendRoleAssignmentGrade';

@Injectable({
  providedIn: 'root'
})
export class WeekendsService
  extends BaseApiService {

  constructor(http: HttpClient, authService: MsalService, @Inject('BASE_URL') baseUrl: string) {
    super(http, authService, 'beta', 'Weekends', baseUrl);
  }

  getWeekends(): Promise<Weekend[]> {
    return this.get<Weekend[]>('');
  }

  getWeekendsForFacility(facilityId: number, conventionYear: number): Promise<Weekend[]> {
    return this.get<Weekend[]>(`facility/${facilityId}/${conventionYear}`);
  }

  getCurrentWeekendForVolunteer(volunteerId: number) : Promise<Weekend> {
    return this.get<Weekend>(`current/volunteer/${volunteerId}`);
  }

  getCurrentWeekendsForVolunteer(volunteerId: number) : Promise<Weekend[]> {
    return this.get<Weekend[]>(`current/volunteer/${volunteerId}/all`);
  }

  getWeekendRoleAssignmentForVolunteerWeekend(weekendId: number, volunteerId: number) : Promise<WeekendRoleAssignment> {
    return this.get<WeekendRoleAssignment>(`${weekendId}/volunteer/${volunteerId}/assignment`);
  }

  getCongregationsForWeekend(weekendId: number): Promise<Congregation[]> {
    return this.get<Congregation[]>(`${weekendId}/congregations`);
  }

  addCongregationToWeekend(congregationWeekend: CongregationWeekend): Promise<CongregationWeekend> {
    return this.post<CongregationWeekend>(`${congregationWeekend.weekendId}/add-congregation`, congregationWeekend);
  }

  removeCongregationFromWeekend(congregationWeekend: CongregationWeekend): Promise<CongregationWeekend> {
    return this.delete<CongregationWeekend>(`${congregationWeekend.weekendId}/remove-congregation/${congregationWeekend.congregationNumber}`);
  }

  addRoleAssignment(weekendRoleAssignment: WeekendRoleAssignment): Promise<WeekendRoleAssignment> {
    return this.post<WeekendRoleAssignment>(`${weekendRoleAssignment.weekendId}/add-role-assignment`, weekendRoleAssignment);
  }

  removeRoleAssignment(weekendId: number, weekendRoleAssignmentId: number): Promise<WeekendRoleAssignment> {
    return this.delete<WeekendRoleAssignment>(`${weekendId}/remove-role-assignment/${weekendRoleAssignmentId}`);
  }

  addRoleAssignmentGrade(weekendId: number, weekendRoleAssignmentGrade: WeekendRoleAssignmentGrade): Promise<WeekendRoleAssignmentGrade> {
    return this.post<WeekendRoleAssignmentGrade>(`${weekendId}/add-role-assignment-grade`, weekendRoleAssignmentGrade);
  }

  getWeekend(weekendId: number): Promise<Weekend> {
    return this.get<Weekend>(weekendId.toString());
  }

  getAssignmentsByWeekendId(weekendId: number, page: number) : Promise<WeekendRoleAssignment[]> {
    return this.get<WeekendRoleAssignment[]>(`${weekendId}/assignments/${page}`);
  }

  updateWeekend(weekend: Weekend): Promise<Weekend> {
    weekend.conventionYearNumber = weekend.conventionYear.year;
    return this.put<Weekend>('', weekend);
  }

  createWeekend(weekend: Weekend): Promise<Weekend> {
    weekend.conventionYearNumber = weekend.conventionYear.year;
    return this.post('', weekend);
  }

  deleteWeekend(Weekend: Weekend): Promise<Weekend> {
    return this.delete(Weekend.weekendId.toString());
  }
}
