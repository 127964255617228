import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { SelectItem, MessageService } from 'primeng/api';
import { Grades } from 'src/app/modules/shared/grades';
import { WeekendsService } from '../../../modules/facilities/services/weekends.service';
import { WeekendRoleAssignmentGrade } from 'src/app/models/weekendRoleAssignmentGrade';
import { WeekendRoleAssignment } from 'src/app/models/weekendRoleAssignment';
import { ButtonType } from '../../components/button/buttonType';
import { LookupsService } from '../../../core/services/lookups.service';

@Component({
  selector: 'app-volunteer-rating-modal',
  templateUrl: './volunteer-rating-modal.component.html',
  styleUrls: ['./volunteer-rating-modal.component.scss']
  })
export class VolunteerRatingModalComponent implements OnInit {

  @Output()
  onGradeSaved: EventEmitter<void> = new EventEmitter<void>();

  buttonTypes = ButtonType;
  saveButtonClicked = false;

  gradeList: SelectItem[];
  showModal: boolean;
  newGrade: WeekendRoleAssignmentGrade;
  selectedGrade: string;
  gradeNotes: string;
  weekendId: number;
  weekendRoleAssignment: WeekendRoleAssignment;
  recommendationList: SelectItem[];
  selectedRecommendation: number;

  constructor(
    private weekendService: WeekendsService,
    private messageService: MessageService,
    private lookupsService: LookupsService,
  ) { }

  ngOnInit(): void {
    this.gradeList = Grades.toListValue();
    this.lookupsService.getConventionJobRoles().then((data) => {
      this.recommendationList = [];
      this.recommendationList.push({ label: '[No recommendation given at this time]', value: 0 });
      data.filter(role => role.useForRecommendation).forEach(role => {
        this.recommendationList.push({ label: role.roleTitle, value: role.conventionJobRoleId });
      });
      this.recommendationList.push({ label: 'Not Recommended', value: -1 });
    });
  }

  public show(weekendId: number, weekendRoleAssignment: WeekendRoleAssignment): void {
    this.selectedGrade = '';
    this.weekendRoleAssignment = weekendRoleAssignment;
    this.weekendId = weekendId;
    this.newGrade = new WeekendRoleAssignmentGrade();
    this.newGrade.weekendRoleAssignmentId = weekendRoleAssignment.weekendRoleAssignmentId;
    this.newGrade.gradeDate = new Date();
    this.gradeNotes = '';
    this.showModal = true;
  }

  cancel(): void {
    this.newGrade = new WeekendRoleAssignmentGrade();
    this.showModal = false;
  }

  save() : void {
    console.debug('save button clicked');
    this.saveButtonClicked = true;
    this.newGrade.grade = Grades.getGradeValue(this.selectedGrade);
    this.newGrade.gradeNotes = this.gradeNotes;
    this.newGrade.recommendation = this.selectedRecommendation;
    this.weekendService
      .addRoleAssignmentGrade(this.weekendId, this.newGrade)
      .then(() => {
        this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Rating and comments added to volunteer grade' });
        this.newGrade = new WeekendRoleAssignmentGrade();
        this.showModal = false;
        this.onGradeSaved.emit();
      });
  }
}