<div class="p-content">
    <div
        class="block p-6 bg-white rounded-t-lg border border-jw-gray-200 hover:bg-jw-gray-100 dark:bg-jw-gray-800 dark:border-jw-gray-700 dark:hover:bg-jw-gray-700">
        <h4 class="mb-2 text-2xl font-bold tracking-tight text-jw-gray-900 dark:text-white">
            {{title}} <span *ngIf="congregation">to [{{congregation.congregationName}}]</span>
        </h4>
    </div>
    <div class="block p-6 bg-white rounded-b-lg border border-jw-gray-200 dark:bg-jw-gray-800 dark:border-jw-gray-700">
        <app-volunteer-edit #volunteerEditModal title="Add Volunteer" (onSave)="onSaved()" (onCancel)="onCancel()"
            (congregation)="congregation" (volunteer)="volunteer"></app-volunteer-edit>
    </div>
</div>
