<div class="home dark:home-dark">
    <div class="bg-cpt-green-500 dark:bg-cpt-purple-500">
        <div class="px-6 py-4">
            <h1 class="text-3xl text-gray-100">Welcome to JW Convention Personel Tool</h1>
        </div>
    </div>
    <div class="container mx-auto p-content">
        <div class="px-0 mx-0">
            <div *ngIf="!isLoggedIn">
                <p><a href="#" (click)="login()"><strong>Log In</strong></a> to continue to CPTool</p>
            </div>
            <div *ngIf="isLoggedIn">
                <h2 class="text-3xl mb-3 font-bold">Overview</h2>
                <div class="p-3 bg-jw-gray-50 dark:bg-jw-gray-900">
                    <app-my-weekends></app-my-weekends>
                </div>
                <div class="p-3 mb-6 bg-cpt-green-500 dark:bg-cpt-purple-500 text-white">
                    <h3 class="text-xl font-bold">Documents</h3>
                </div>
                <div class="px-0 mx-0 columns-1 md:columns-2 ">
                    <div class="mb-3">
                        <a routerLinkActive="active" routerLink="/search" title="Volunteer Search">
                            <img class="shadow-lg dark:invert" src="assets/images/search-icon.jpg">
                        </a>
                    </div>
                    <div class="mb-3" *ngIf="canViewFacilities">
                        <a routerLinkActive="active" routerLink="/facilities" title="Facilities">
                            <img class="shadow-lg dark:invert" src="assets/images/facilities-icon.jpg" />
                        </a>
                    </div>
                    <div class="mb-3" *ngIf="canViewCircuits">
                        <a routerLinkActive="active" routerLink="/circuits" title="Circuits">
                            <img class="shadow-lg dark:invert" src="assets/images/circuits-icon.jpg" />
                        </a>
                    </div>
                    <div class="mb-3">
                        <a routerLinkActive="active" routerLink="/congregation" title="Congregations">
                            <img class="shadow-lg dark:invert" src="assets/images/congregations-icon.jpg" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>