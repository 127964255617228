import { Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: "cptInput",
  templateUrl: "./input.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ],
})
export class InputComponent implements ControlValueAccessor {

  @Input() id: string;
  @Input() label: string;
  @Input() submitted = false;
  @Input() validationMessage = "";
  @Input() hasErrors = false;
  @Input() formControlName: string;
  @Input() type: string;

  value: string;

  onChange = event => {};
  onTouched = () => {};

  touched = false;
  disabled = false;

  writeValue(value: string): void {
    this.value = value;
    this.onChange(value);
    this.onTouched();
  }

  registerOnChange(fn: any): void {
      this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get() {
    return this.value;
  }

  set(value: string) {
    this.value = value;
    this.onChange(value);
  }

  public onBlur() {
    this.onTouched();
  }
}
