import { Facility } from './facility';
import { ConventionYear } from 'src/app/models/conventionYear';
import { WeekendRoleAssignment } from './weekendRoleAssignment';
import { WeekendType } from './weekendType';

export class Weekend {
    weekendId: number;
    conventionYear: ConventionYear;
    conventionYearNumber: number;
    facilityId: number;
    weekendNumber: number;
    weekendLanguage: string;
    facility: Facility;
    weekendRoleAssignments: WeekendRoleAssignment[];
    weekendDate: string;
    weekendTypeId: number;
    weekendType: WeekendType;
}
