<p-confirmPopup
    styleClass="bg-jw-gray-100 dark:bg-jw-gray-900 text-jw-gray-900 dark:text-jw-gray-100 rounded-lg"
></p-confirmPopup>

<div class="block p-6 rounded-lg mb-4 bg-jw-gray-50 border border-jw-gray-50 dark:border-jw-gray-800 shadow-md dark:bg-jw-gray-800">
    <h4 class="mb-2 text-2xl font-bold tracking-tight text-jw-gray-900 dark:text-white">Circuit List:</h4>
    <p class="font-normal text-jw-gray-700 dark:text-jw-gray-400">
        Add/Edit Circuits. For the Circuit number, please use the Branch assigned Circuit number.
    </p>
    <p class="font-normal text-jw-gray-700 dark:text-jw-gray-400" *ngIf="!(circuitList)"><em>Loading...</em></p>
</div>
<div class="w-full">
    <p-table #dt [value]="circuitList" [paginator]="true" [rows]="10"
        styleClass="w-full" responsiveLayout="stack" dataKey="circuitId" editMode="row">
        <ng-template pTemplate="caption">
            <div class="text-jw-gray-800 dark:text-jw-gray-100">
                <label for="stateFilter" class="mr-4">Filter By</label>
                <p-dropdown id="stateFilter" [options]="stateCodes" (onChange)="onStateCodeChange($event, dt)"
                    styleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full
                                dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                </p-dropdown>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th scope="col" class="rounded-tl-lg">Number</th>
                <th scope="col">State</th>
                <th scope="col">Language</th>
                <th scope="col">Circuit Code</th>
                <th scope="col" class="w-2/12 rounded-tr-lg"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="rowData">
                <td width="20%" >
                    <span class="p-column-title">Number:</span>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                            dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            pInputText type="number" [(ngModel)]="rowData.number" required>
                        </ng-template>
                        <ng-template pTemplate="output">{{ rowData.number }}</ng-template>
                    </p-cellEditor>
                </td>
                <td width="20%" >
                    <span class="p-column-title">State:</span>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-dropdown styleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-1
                            dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            [options]="stateCodes" [(ngModel)]="rowData.stateCode" [required]="true" aria-required="true"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">{{ getState(rowData.stateCode) }}</ng-template>
                    </p-cellEditor>
                </td>
                <td width="20%" >
                    <span class="p-column-title">Language:</span>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-dropdown styleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-1
                            dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            [options]="languages" [(ngModel)]="rowData.language" [filter]="true" [virtualScroll]="true" [itemSize]="60" [required]="true" aria-required="true"></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">{{ rowData.language | language }}</ng-template>
                    </p-cellEditor>
                </td>
                <td width="20%" >
                    <span class="p-column-title">Code:</span>
                    {{ rowData | circuit }}
                </td>
                <td width="20%" class="text-right">
                    <span *ngIf="!editing">
                        <app-button pInitEditableRow [initRowEdit]="true" [hideLabel]="true"
                            label="Edit" [type]="buttonTypes.Edit" (click)="onRowEditInit(rowData)"></app-button>
                        <app-button *ifRoles='["SuperUser"]' pInitEditableRow [hideLabel]="true"
                            [type]="buttonTypes.Delete"
                            label="Delete" (click)="onRowDelete($event, rowData)"></app-button>
                    </span>
                    <app-button *ngIf="editing" pSaveEditableRow [hideLabel]="true"
                        label="Save Circuit" [type]="buttonTypes.Save" (click)="onRowEditSave(rowData)"></app-button>
                    <app-button *ngIf="editing" pCancelEditableRow [hideLabel]="true"
                        label="Cancel" [type]="buttonTypes.Cancel" (click)="onRowEditCancel(rowData, ri)"></app-button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary" let-rowData>
            <app-button [type]="buttonTypes.Add" label="Add Circuit" (click)="showDialogToAdd()"></app-button>
        </ng-template>
    </p-table>
</div>

<p-dialog header="Add Circuit" [(visible)]="showAddCircuitDialog" [focusOnShow]="true"
    [style]="{ width: '50vw' }" styleClass="bg-jw-gray-100 text-jw-gray-900 dark:bg-jw-gray-800 dark:text-white"
    showEffect="fade" [modal]="true" [contentStyle]="{'overflow':'visible'}">
    <div *ngIf="circuit">
        <div class="mb-4">
            <label for="circuitStateCode"
                class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">State:</label>
            <p-dropdown id="stateCode" appendTo="body" [options]="stateCodes" [(ngModel)]="circuit.stateCode"
                styleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-1
                       dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                [required]="true" aria-required [autofocus]="true" [focus]="true" id="circuitStateCode" name="circuitStateCode"></p-dropdown>
        </div>
        <div class="mb-4">
            <label for="circuitLanguage"
                class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Language:</label>
            <p-dropdown id="stateCode" appendTo="body" [options]="languages" [(ngModel)]="circuit.language"
                [filter]="true" [virtualScroll]="true" [itemSize]="5"
                styleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-1
                       dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                [required]="true" aria-required id="circuitLanguage" name="circuitLanguage"></p-dropdown>
        </div>
        <div class="mb-4">
            <label for="circuitNumber"
                class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Circuit Code:</label>
            <input
                class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                      dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                [(ngModel)]="circuit.number" autofocus [focus]="true" pInputText type="text" id="circuitNumber"
                name="circuitNumber" />
        </div>
    </div>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <app-button [type]="buttonTypes.Save" label="Save" (click)="save()"></app-button>
            <app-button [type]="buttonTypes.Cancel" label="Cancel" (click)="cancel()"></app-button>
        </div>
    </p-footer>
</p-dialog>
