import { Component, OnInit } from "@angular/core";
import { AccountInfo } from "@azure/msal-browser";
import posthog from "posthog-js";
import { UserAccountService } from "src/app/core/services/user-account.service";
import { SearchService } from "src/app/modules/congregations/services/search.service";
import { AdminService } from "src/app/modules/shared/services/admin.service";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent implements OnInit {
  currentUser: AccountInfo;
  isLoggedIn: boolean;
  airtableLog: string;
  airtableProgress: number;
  airtableImportInProgress: boolean;
  whatIf: boolean;

  constructor(
    private userAccountService: UserAccountService,
    private searchService: SearchService,
    private adminService: AdminService,
  ) {
    this.whatIf = false;
  }

  reindexClick(): void {
    console.debug(
      "AdminComponent: reindexClick: calling searchService.reindexVolunteers()...",
    );
    this.airtableImportInProgress = true;
    this.airtableLog = "Reindexing volunteers.";
    this.searchService.reindexVolunteers((event) => {
      this.airtableLog += "<br />" + event.data;
      if (event.data === "Reindex Complete") {
        this.airtableImportInProgress = false;
      }
    });
  }

  isFeatureEnabled(feature: string): boolean {
    return posthog.isFeatureEnabled(feature);
  }

  importAirtableClick(): void {
    console.debug(
      "AdminComponent: importAirtableClick: calling adminService.importAirtable()...",
    );
    this.airtableImportInProgress = true;
    this.airtableLog = "Importing Airtable volunteer records.";
    this.adminService.importAirtable((event) => {
      this.airtableLog += "<br />" + event.data;
      if (event.data === "Import Complete") {
        this.airtableImportInProgress = false;
      }
    });
  }

  importAirtablePreviewClick(): void {
    console.debug(
      "AdminComponent: importAirtablePreviewClick: calling adminService.importAirtable()...",
    );
    this.airtableImportInProgress = true;
    this.airtableLog = "Importing Airtable volunteer records.";
    this.adminService.importAirtable((event) => {
      this.airtableLog += "<br />" + event.data;
      if (event.data === "Import Complete") {
        this.airtableImportInProgress = false;
      }
    }, true);
  }

  ngOnInit(): void {
    this.currentUser = this.userAccountService.getAccount();
    this.isLoggedIn = this.userAccountService.isLoggedIn();

    this.isLoggedIn = this.userAccountService.isLoggedIn();
    this.userAccountService.subscribeToLogin(() => {
      this.currentUser = this.userAccountService.getAccount();
      this.isLoggedIn = this.userAccountService.isLoggedIn();
    });
  }
}
