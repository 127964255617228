<label [for]="id"
       class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">{{ label }}</label>
<input
    [id]="id"
    [name]="id"
    [type]="type"
    [value]="value"
    class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    [(ngModel)]="value"
    (blur)="onBlur()"
    (change)="onChange($event)"
    [ngClass]="{ 'focus:ring-red-500 focus:border-red-500 bg-red-100 border-red-400 dark:border-red-400 dark:focus:border-red-500 ': submitted && hasErrors }"
/>
<div
    *ngIf="submitted && hasErrors"
    class="mt-2 text-red-700 dark:text-red-500"
>
 {{ validationMessage}}
</div>
