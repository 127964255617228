/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit, ViewChild } from '@angular/core';
import { CongregationService } from '../../services/congregation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Congregation } from 'src/app/models/congregation';
import { CongregationAddComponent } from 'src/app/features/congregations/add/congregation-add/congregation-add.component';

@Component({
  selector: 'app-page-add-congregations-component',
  templateUrl: './page-add-congregations-component.component.html',
})
export class PageAddCongregationsComponent implements OnInit {
  @ViewChild('congregationEdit') congregationEdit: CongregationAddComponent;

  congregationNumber: number;

  title: string;

  constructor(
    private congregationService: CongregationService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.congregationNumber = Number(
      this.route.snapshot.paramMap.get('congregationNumber'),
    );

    if (this.congregationNumber !== 0) {
      setTimeout(() => this.congregationEdit.show(this.congregationNumber));
    }

    this.title = isNaN(this.congregationNumber)
      ? 'Add Congregation'
      : 'Edit Congregation';
  }

  onSaved(): void {
    this.router.navigate([`//congregation`]);
  }

  onCancel(): void {
    const route = `//congregation`;
    this.router.navigate([route]);
  }
}
