<p-table [value]="weekends" dataKey="weekendId" responsiveLayout="stack">
    <ng-template pTemplate="header">
        <tr>
            <th scope="col">Year</th>
            <th scope="col">Weekend #</th>
            <th scope="col">Date</th>
            <th scope="col">Language</th>
            <th scope="col" *ngIf="isFeatureEnabled('v8')">Convention Type</th>
            <th scope="col" class="w-2/12"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-weekend let-ri="rowIndex">
        <tr>
            <td>
                <span class="p-column-title">Year:</span>
                {{ weekend.conventionYear.year }}
            </td>
            <td>
                <span class="p-column-title">Weekend #:</span>
                {{ weekend.weekendNumber }}
            </td>
            <td>
                <span class="p-column-title">Date:</span>
                {{ weekend.weekendDate }}
            </td>
            <td>
                <span class="p-column-title">Language:</span>
                {{ weekend.weekendLanguage | language }}
            </td>
            <td *ngIf="isFeatureEnabled('v8')">
                <span class="p-column-title">Convention Type:</span>
                {{ weekend.weekendType?.weekendTypeName ?? 'Unknown' }}
            </td>
            <td class="text-right">
                <app-button [hideLabel]="true"
                    label="Edit Weekend" [type]="buttonTypes.Edit" (click)="editWeekend(weekend)"></app-button>
                <app-button [type]="buttonTypes.Assignments"
                    [routerLink]="['/facilities', facility.facilityId, 'weekend', weekend.weekendId]"
                    [hideLabel]="true" rel="tooltip" label="Assignment Details"></app-button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary" let-rowData>
        <app-button [type]="buttonTypes.Add" label="Add Weekend" (click)="addWeekend($event)"></app-button>
    </ng-template>
</p-table>
