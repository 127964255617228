/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConventionYear } from '../../models/conventionYear';

@Injectable({
  providedIn: 'root'
  })
export class ConventionYearService {

  currentYear: Observable<ConventionYear>;
  myAppUrl: string;
  myApiUrl: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(private http: HttpClient) {
    this.myApiUrl = '/api/beta/conventionyear/';
  }

  getAll(): Observable<ConventionYear[]> {
    return this.http.get<ConventionYear[]>(this.myApiUrl)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  getCurrent(): Observable<ConventionYear> {
    if (!this.currentYear) {
      this.currentYear = this.http.get<ConventionYear>(this.myApiUrl + 'current')
        .pipe(
          catchError(this.errorHandler)
        );
    }
    return this.currentYear;
  }

  getConventionYear(year: number): Observable<ConventionYear> {
    return this.http.get<ConventionYear>(this.myApiUrl + year.toString())
      .pipe(
        catchError(this.errorHandler)
      );
  }

  updateConventionYear(ConventionYear: ConventionYear): Promise<ConventionYear | undefined> {
    const promise = this.http.put<ConventionYear>(this.myApiUrl, ConventionYear).toPromise();
    promise.catch((error) => this.errorHandler(error));
    return promise;
  }

  createConventionYear(ConventionYear: ConventionYear): Promise<ConventionYear | undefined> {
    const promise = this.http.post<ConventionYear>(this.myApiUrl, ConventionYear).toPromise();
    promise.catch((error) => this.errorHandler(error));
    return promise;
  }

  deleteConventionYear(ConventionYear: ConventionYear): Promise<ConventionYear | undefined> {
    const promise = this.http.delete<ConventionYear>(this.myApiUrl + ConventionYear.year).toPromise();
    promise.catch((error) => this.errorHandler(error));
    return promise;
  }

  errorHandler(error: any) {
    console.log('Current convention year errored');

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
