/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Volunteer } from 'src/app/models/volunteer';
import { paths } from '../profile-paths';
import { ButtonType } from '../../../features/components/button/buttonType';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})
export class PersonalInformationComponent {

  editPassportLink: string = `/${paths.passportPhoto}`;
  buttonTypes = ButtonType;

  @Output()
  editProfilePhoto: EventEmitter<string> = new EventEmitter<string>();

  @Input() currentUser: Volunteer;

  editPhotoPath: string = paths.passportPhoto;
}