<div class="p-content">
    <section *ngIf="currentUser">
        <header>
            <h1 class="mb-2 text-2xl font-bold tracking-tight text-jw-gray-900 dark:text-white">Overview</h1>
        </header>
        <main>
            <app-personal-information *ngIf="!isEditingProfileImage" [currentUser]="currentUser"
                (editProfilePhoto)="onEditProfilePhoto()">
            </app-personal-information>
        </main>
    </section>
</div>