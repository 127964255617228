import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toId'
})
export class IdPipe implements PipeTransform {
  transform(value: string): string {
    try {
      return value.replace(/^[^a-z]+|[^\w:.-]+/gi, '').toLowerCase();
    } catch (error) {
      console.log(error);
      return 'invalid_id';
    }
  }
}