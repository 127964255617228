<div class="search-box__filters">
    <form (submit)="doSearch()">
        <h2 class="mb-2 text-2xl font-bold tracking-tight text-jw-gray-900 dark:text-white">Volunteer Search:</h2>
        <div class="active-cyan-2 mb-4">
            <input type="text" aria-label="Search" pInputText
                    class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                            dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                [(ngModel)]="searchTerm" name="searchTerm" placeholder="Type a name/email to search">
        </div>
        <app-button [type]="buttonTypes.Search" label="Search" (click)="doSearch()"></app-button>
    </form>
</div>
<div class="grid grid-cols-12">
    <div class="col-span-12 xl:col-span-3 xl:pr-2 pb-2">
        <h3 class="mb-2">Search Filters</h3>
        <div class="card" *ngIf="isFirstSearch">
            <div class="custom-skeleton p-4">
                <div class="m-0 p-0">
                    <div class="mb-3">
                        <p-skeleton width="100%" height="2rem"></p-skeleton>
                    </div>
                    <div class="mb-2">
                        <div class="d-flex">
                            <p-skeleton shape="square" size="1rem" styleClass="mr-2"></p-skeleton>
                            <div style="flex: 1">
                                <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                            </div>
                        </div>
                    </div>
                    <div class="mb-2">
                        <div class="d-flex">
                            <p-skeleton shape="square" size="1rem" styleClass="mr-2"></p-skeleton>
                            <div style="flex: 1">
                                <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                            </div>
                        </div>
                    </div>
                    <div class="mb-2">
                        <div class="d-flex">
                            <p-skeleton shape="square" size="1rem" styleClass="mr-2"></p-skeleton>
                            <div style="flex: 1">
                                <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                            </div>
                        </div>
                    </div>
                    <div class="mb-2">
                        <div class="d-flex">
                            <p-skeleton shape="square" size="1rem" styleClass="mr-2"></p-skeleton>
                            <div style="flex: 1">
                                <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                            </div>
                        </div>
                    </div>
                    <div class="mb-2">
                        <div class="d-flex">
                            <p-skeleton shape="square" size="1rem" styleClass="mr-2"></p-skeleton>
                            <div style="flex: 1">
                                <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <p-accordion [multiple]="true" *ngIf="!isFirstSearch">
            <p-accordionTab header="Responsibilities" *ngIf="responsibilities" [selected]="true">
                <div *ngFor="let facet of responsibilities.facets" class="form-group form-check">
                    <input name={{facet.name|toId}} id={{facet.name|toId}} type="checkbox"
                        class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                        [(ngModel)]="facet.isSelected" (change)="onFacetChange()" />
                    <label class="font-medium text-gray-900 dark:text-gray-100" htmlFor={{facet.name|toId}}>
                        {{facet.name}} <span>({{facet.count}})</span>
                    </label>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Congregations" *ngIf="congregationFacets">
                <div *ngFor="let facet of congregationFacets.facets" class="form-group form-check">
                    <input name={{facet.name|toId}} id={{facet.name|toId}} type="checkbox"
                        class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                        [(ngModel)]="facet.isSelected" (change)="onFacetChange()" />
                    <label class="font-medium text-gray-900 dark:text-gray-100" htmlFor={{facet.name|toId}}>
                        {{facet.name}} <span>({{facet.count}})</span>
                    </label>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Circuits" *ngIf="circuitFacets">
                <div *ngFor="let facet of circuitFacets.facets" class="form-group form-check">
                    <input name={{facet.name|toId}} id={{facet.name|toId}} type="checkbox"
                        class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                        [(ngModel)]="facet.isSelected" (change)="onFacetChange()" />
                    <label class="font-medium text-gray-900 dark:text-gray-100" htmlFor={{facet.name|toId}}>
                        {{facet.name}} <span>({{facet.count}})</span>
                    </label>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Assignments" *ngIf="departmentFacets">
                <div *ngFor="let facet of departmentFacets.facets" class="form-group form-check">
                    <input name={{facet.name|toId}} id={{facet.name|toId}} type="checkbox"
                        class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                        [(ngModel)]="facet.isSelected" (change)="onFacetChange()" />
                    <label class="font-medium text-gray-900 dark:text-gray-100" htmlFor={{facet.name|toId}}>
                        {{facet.name}} <span>({{facet.count}})</span>
                    </label>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Is Recommended?" *ngIf="recommendationFacets">
                <div *ngFor="let facet of recommendationFacets.facets" class="form-group form-check">
                    <input name={{facet.name|toId}} id={{facet.name|toId}} type="checkbox"
                        class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                        [(ngModel)]="facet.isSelected" (change)="onFacetChange()" />
                    <label class="font-medium text-gray-900 dark:text-gray-100" htmlFor={{facet.name|toId}}>
                        {{facet.name}} <span>({{facet.count}})</span>
                    </label>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>

    <div class="col-span-12 xl:col-span-9">
        <p-table [value]="volunteers" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
            [lazy]="true" (onLazyLoad)="lazyLoadVolunteers($event)" responsiveLayout="stack"
            [rowsPerPageOptions]="[10, 20, 40, 60]" [totalRecords]="totalRecords"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            [autoLayout]="true"
            class="table-volunteer-search" dataKey="volunteerId">
            <ng-template pTemplate="header">
                <tr>
                    <th scope="col" class="rounded-tl-lg" width="5%"></th>
                    <th scope="col">Contact Info.</th>
                    <th scope="col">Race</th>
                    <th scope="col">Cong./Circuit</th>
                    <th scope="col">Overall Rating</th>
                    <th scope="col">Assignment Status</th>
                    <th scope="col" *ngIf="isFeatureEnabled('v6')">Recommended For Committee?</th>
                    <th scope="col" class="w-2/12 rounded-tr-lg"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-volunteer let-expanded="expanded">
                <tr>
                    <td>
                        <a href="#" [pRowToggler]="volunteer">
                            <i class="duration-300" [ngClass]="expanded ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-right'"></i>
                        </a>
                    </td>
                    <td><span class="p-column-title">Contact Information:</span>{{ volunteer.lastName }}, {{ volunteer.firstName }} <br />{{ volunteer.jwEmail }}</td>
                    <td><span class="p-column-title">Race:</span>{{ volunteer.race }}</td>
                    <td><span class="p-column-title">Cong:</span>
                        {{ volunteer.congregation.congregationName }} <br />
                        {{ volunteer.congregation.circuit | circuit: volunteer.congregation.circuitSection }}
                    </td>
                    <td><span class="p-column-title">Rating:</span>{{ volunteer.grade | grade:0:volunteer.weekendRoleAssignments[0] }}</td>
                    <td>
                        <span class="p-column-title">Status:</span>
                        <span class="mr-2 p-3 mb-2 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" *ngIf="!isAssignedWeekend(volunteer)">Available</span>
                        <span class="mr-2 p-3 mb-2 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" *ngIf="isAssignedWeekend(volunteer)">Has&nbsp;Assignment</span>
                    </td>
                    <td *ngIf="isFeatureEnabled('v6')">
                        <span *ngIf="hasRecommendation(volunteer)" class="mr-2 p-3 mb-2 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800 whitespace-nowrap">
                            {{ volunteer?.recommendation?.roleTitle }}
                        </span>
                        <span *ngIf="notRecommended(volunteer)" class="mr-2 p-3 mb-2 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 whitespace-nowrap">
                            {{ volunteer?.recommendation?.roleTitle }}
                        </span>
                    </td>
                    <td>
                        <app-button [type]="buttonTypes.Edit" [routerLink]="['/congregation/' + volunteer.congregation.congregationNumber + '/volunteers/' + volunteer.volunteerId ]" ></app-button>
                        <app-button [type]="buttonTypes.AddGrade" (click)="openAddGradeModal(volunteer)"rel="tooltip" title="Add Grading"
                                    [requiredRoles]="[securityRole.SuperUser, securityRole.CommitteeMember, securityRole.CommitteeAssistant]" *ngIf="isAssignedWeekend(volunteer)"></app-button>
                        <app-button [type]="buttonTypes.VolunteerEdit" (click)="openAddEditAssignmentModal(volunteer)"></app-button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-volunteer>
                <tr>
                    <td colspan="8" class="container">
                        <div class="w-full flex flex-nowrap">
                            <div class="basis-full md:basis-1/6">
                                <div class="w-full flex flex-wrap">
                                    <ng-container *ngFor="let res of volunteer.responsibilities">
                                        <app-pill>{{ res.responsibility.responsibility }}</app-pill>
                                    </ng-container>
                                </div>
                                <div class="block mt-2">
                                    <span class="mb-2 text-md font-bold tracking-tight text-jw-gray-700 dark:text-cpt-purple-10">Year of Birth:</span>
                                    <app-text-label [labelType]="labelTypes.Label">{{ volunteer.yearOfBirth | age }}</app-text-label>
                                </div>
                            </div>
                            <div class="basis-full md:basis-5/6">
                                <span class="mb-2 text-md font-bold tracking-tight text-jw-gray-700 dark:text-cpt-purple-10">Grading History</span>

                                <p-table [value]="volunteer.weekendRoleAssignments"
                                    styleClass="w-full"
                                    dataKey="weekendRoleAssignmentId">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th scope="col">Year</th>
                                            <th scope="col">Grade</th>
                                            <th scope="col">Role</th>
                                            <th scope="col" *nfif="isFeatureEnabled('v8')">Convention Type</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-wra let-expanded="expanded">
                                        <tr>
                                            <td>{{ wra.weekend.conventionYear.year }}</td>
                                            <td>{{ wra.grade | grade:0:wra }}</td>
                                            <td>{{ wra | assignment }} </td>
                                            <td *ngif="isFeatureEnabled('v8')">{{ wra.weekend.weekendType?.weekendTypeName }}</td>
                                            <td>
                                                <button title="View grade notes" [disabled]="!showNotesButton(wra)"
                                                    class="btn btn-secondary" (click)="onViewGradeNotes(wra)">
                                                    <span class="mdi mdi-note"></span>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<app-volunteer-rating-modal #volunteerRatingModal (onGradeSaved)="handleGradeSaved()"></app-volunteer-rating-modal>
<app-volunteer-assignment #volunteerAssignmentModal (onAssignmentSaved)="handleAssignmentSaved()"></app-volunteer-assignment>

<p-dialog header="Notes:" [(visible)]="showNotesDialog" [focusOnShow]="true" showEffect="fade" [modal]="true">
    <p-table [value]="selectedGrades" styleClass="container" dataKey="weekendRoleAssignmentId">
        <ng-template pTemplate="header">
            <tr>
                <th scope="col">Grade</th>
                <th scope="col">Notes</th>
                <th scope="col">By</th>
                <th scope="col">Date</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-grade let-expanded="expanded">
            <tr>
                <td>{{ grade.grade | grade:0:grade.weekendRoleAssignment }}</td>
                <td [innerHTML]="grade.gradeNotes"></td>
                <td>{{ grade.graderVolunteer.lastName }}, {{ grade.graderVolunteer.firstName }}</td>
                <td>{{ grade.gradeDate | date:'mediumDate' }}</td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>
