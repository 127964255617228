import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

// 3rd Party Components
import { TableModule } from 'primeng/table';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { EditorModule } from 'primeng/editor';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { PanelModule } from 'primeng/panel';
import { FileUploadModule } from 'primeng/fileupload';
import { SkeletonModule } from 'primeng/skeleton';

// Routing
import { FacilitiesRoutingModule } from './facilities-routing.module';

// Components
import { FacilitiesListComponent } from '../../features/factilities/facilities-list/facilities-list.component';
import { SharedModule } from '../shared/shared.module';
import { AddAssignmentComponent } from 'src/app/features/factilities/add-assignment/add-assignment.component';
import { FacilityWeekendsComponent } from '../../features/factilities/facility-weekends/facility-weekends.component';
import { FacilityWeekendComponent } from '../../features/factilities/facility-weekend/facility-weekend.component';
import { VolunteerRatingComponent } from '../../features/volunteers/volunteer-rating/volunteer-rating.component';
import { AddWeekendModalComponent } from './add-weekend-modal/add-weekend-modal.component';
import { PageFacilitiesComponent } from './pages/page-facilities/page-facilities.component';
import { PageFacilityweekendComponent } from './pages/page-facilityweekend/page-facilityweekend.component';
import { PageAddAssignmentComponent } from './pages/page-add-assignment/page-add-assignment.component';

// Services

// Custom Pipes


@NgModule({
  declarations: [
    FacilitiesListComponent,
    FacilityWeekendsComponent,
    FacilityWeekendComponent,
    VolunteerRatingComponent,
    AddAssignmentComponent,
    AddWeekendModalComponent,
    PageFacilitiesComponent,
    PageFacilityweekendComponent,
    PageAddAssignmentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    DialogModule,
    DynamicDialogModule,
    DataViewModule,
    DropdownModule,
    CheckboxModule,
    EditorModule,
    AutoCompleteModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FacilitiesRoutingModule,
    SharedModule,
    PanelModule,
    FileUploadModule,
    SkeletonModule
  ],
  providers: []
})
export class FacilitiesModule { }
