import { Component, OnInit, Input } from '@angular/core';
import { Volunteer } from 'src/app/models/volunteer';
import { VolunteersService } from 'src/app/modules/congregations/services/volunteers.service';
import { UserAccountService } from '../../../core/services/user-account.service';
import { ConventionYearService } from 'src/app/core/services/convention-year.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  @Input('targetId') targetId: string;

  currentUser: Volunteer;
  currentYear: number;
  isLoggedIn = false;
  userName: string;
  profileImage = 'example-photo.png'; //Profile image from currentUser
  userEmail: string;

  constructor(
    private userAccountService: UserAccountService,
    private volunteerService: VolunteersService,
    private conventionYearService: ConventionYearService,
    private router: Router,
  ) {
    // Need to force the account check in the header as it 
    // seems to run into a race condition where the user is always logged out...
    this.userAccountService.checkAccount();
    this.isLoggedIn = this.userAccountService.isLoggedIn();
    this.userName = this.userAccountService.userName();

    this.userAccountService.subscribeToLogin(() => {
      this.userAccountService.checkAccount();
      this.isLoggedIn = this.userAccountService.isLoggedIn();
      const account = this.userAccountService.getAccount();
      this.userName = account?.name ?? '';
      this.loadUserDetails();
    });
  }

  ngOnInit(): void {
    this.loadUserDetails();
    this.updateSelectedTheme(localStorage.selectedTheme);

    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        const sidebar = document.getElementById(this.targetId);
        if(screen.width < 1024){ //At tailwind lg breakpoint.
          sidebar.classList.remove('lg:ml-0');
          sidebar.classList.add('-ml-72');
        }
      }
    });
  }

  loadUserDetails(): void {
    if (this.isLoggedIn) {
      this.conventionYearService.getCurrent().toPromise()
        .then(
          (result) => {
            this.currentYear = result.year;
          });
      this.volunteerService.getCurrentVolunteer()
        .then(
          (result) => {
            this.currentUser = result;
            this.userEmail = this.currentUser.userProfile.email;

            //Set this profile image if available.
            if (this.currentUser.profileImage) {
              this.profileImage = this.currentUser.profileImage;
            }
          });
    }
  }

  toggle(): void {
    console.log('targetId[' + this.targetId + ']');
    const sidebar = document.getElementById(this.targetId);
    sidebar.classList.toggle('lg:ml-0');
    sidebar.classList.toggle('-ml-72');
  }

  toggleUserMenu(): void {
    document.getElementById('dropdownUser').classList.toggle('hidden');
  }

  closeUserMenu(): void {
    document.getElementById('dropdownUser').classList.add('hidden');
  }

  setDefaultProfileImage(): void {
    this.profileImage = 'example-photo.png';
  }

  toggleThemeMenu(): void {
    document.getElementById('dropdownTheme').classList.toggle('hidden');
  }

  closeThemeMenu(): void {
    const menu = document.getElementById('dropdownTheme');
    const button = document.getElementById('userThemeButton');

    button.setAttribute('aria-expanded', 'false');
    button.removeAttribute('aria-controls');
    menu.classList.add('hidden');
  }

  setUserTheme(theme: string): void {
    localStorage.selectedTheme = theme;
    switch (theme) {
    case 'light':
      localStorage.theme = 'light';
      document.documentElement.classList.remove('dark');
      break;
    case 'dark':
      localStorage.theme = 'dark';
      document.documentElement.classList.add('dark');
      break;
    case 'system':
      localStorage.removeItem('theme');
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.documentElement.classList.add('dark');
      }
      break;
    }
    this.updateSelectedTheme(theme);
    this.toggleThemeMenu();
  }

  updateSelectedTheme(theme: string) : void {
    const light = document.getElementById('userThemeOptionsLight');
    const dark = document.getElementById('userThemeOptionsDark');
    const system = document.getElementById('userThemeOptionsSystem');

    switch (theme) {
    case 'light':
      light.classList.add('selected-theme');
      dark.classList.remove('selected-theme');
      system.classList.remove('selected-theme');
      break;
    case 'dark':
      light.classList.remove('selected-theme');
      dark.classList.add('selected-theme');
      system.classList.remove('selected-theme');
      break;
    case 'system':
      light.classList.remove('selected-theme');
      dark.classList.remove('selected-theme');
      system.classList.add('selected-theme');
    }
  }

  hideUserMenu(): void {
    const userMenu = document.getElementById('usermenu');
    if (!userMenu.classList.contains('hidden')) {
      userMenu.classList.add('hidden');
    }
  }

  login(): void {
    this.userAccountService.login();
  }

  logout(): void {
    console.debug('Signing out');
    this.userAccountService.logout();
  }
}