import { Component, OnInit } from '@angular/core';
import { Weekend } from 'src/app/models/weekend';
import { WeekendsService } from '../../../modules/facilities/services/weekends.service';

@Component({
  selector: 'app-my-weekends',
  templateUrl: './my-weekends.component.html',
  styleUrls: ['./my-weekends.component.scss']
  })

export class MyWeekendsComponent implements OnInit {

  hasLoaded = false;
  currentWeekends: Weekend[] = [];

  constructor(
    private weekendsService: WeekendsService,
  ) {
  }

  ngOnInit(): void {
    this.weekendsService.getCurrentWeekendsForVolunteer(0)
      .then(data =>  {
        this.currentWeekends = data;
        this.hasLoaded = true;
      });
  }
}
