<div class="p-content">
    <div *ngIf="isLoggedIn">
        <header>
            <h1 class="text-2xl mb-2">
                Convention Personel Tool: Administration
            </h1>
            <main>
                <div class="mb-4">
                    <button
                        type="button"
                        (click)="reindexClick()"
                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                        Re-Index Volunteers
                    </button>
                </div>
                <div class="mb-4">
                    <button
                        type="button"
                        (click)="importAirtableClick()"
                        class="align-middle text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                        Import Airtable Data
                    </button>
                    <button
                        type="button"
                        (click)="importAirtablePreviewClick()"
                        class="align-middle text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                        PREVIEW: Import Airtable Data
                    </button>
                </div>
                <div
                    class="align-top block p-6 mb-4 text-align-left bg-white rounded-lg border border-jw-gray-200 shadow-md hover:bg-jw-gray-100 dark:bg-jw-gray-800 dark:border-jw-gray-700 dark:hover:bg-jw-gray-700"
                >
                    <div>
                        <output *ngIf="airtableImportInProgress" class="inline-block align-top pr-4">
                            <svg
                                aria-hidden="true"
                                class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                />
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </output>
                        <div class="inline-block align-top">
                            <div class="overflow-auto flex flex-col-reverse max-h-96">
                                <div class="inline-block align-top" [innerHTML]="airtableLog"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="block p-6 max-w-sm bg-white rounded-lg border border-jw-gray-200 shadow-md hover:bg-jw-gray-100 dark:bg-jw-gray-800 dark:border-jw-gray-700 dark:hover:bg-jw-gray-700"
                >
                    <h5
                        class="mb-2 text-lg font-bold tracking-tight text-jw-gray-900 dark:text-white"
                    >
                        Features enabled:
                    </h5>
                    <ul>
                        <li>Version 6: <span class="fa fa-{{ isFeatureEnabled('v6') ? 'check' : 'times' }}"></span></li>
                        <li>Version 7: <span class="fa fa-{{ isFeatureEnabled('v7') ? 'check' : 'times' }}"></span></li>
                        <li>Version 8: <span class="fa fa-{{ isFeatureEnabled('v8') ? 'check' : 'times' }}"></span></li>
                    </ul>
                </div>
            </main>
        </header>
    </div>
    <div *ngIf="!isLoggedIn">
        <p>
            Welcome to the Convention Personnel Tool. Please login to gain
            access to your convention.
        </p>
    </div>
</div>
