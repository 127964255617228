<h1>{{ title }}</h1>
<div>
    <p *ngIf="status">
        <b>Status Code: {{ status }}</b>
    </p>
    <p class="bg-jw-gray-800 dark:bg-jw-gray-200 p-4 rounded">
        {{ message }}
    </p>
</div>
<div class="mt-4">
    <app-button [type]="buttonTypes.Primary" label="Close" (click)="closeButtonOnClick()">
    </app-button>
</div>