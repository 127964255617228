import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from 'src/app/core/services/base-api.service';
import { Facility } from '../../../models/facility';
import { MsalService } from '@azure/msal-angular';
import { FacilityCapacity } from '../../../models/facilityCapacity';

@Injectable({
  providedIn: 'root'
})
export class FacilitiesService 
  extends BaseApiService {

  constructor(http: HttpClient, authService: MsalService, @Inject('BASE_URL') baseUrl: string) { 
    super(http, authService, 'beta', 'facilities', baseUrl);
  }

  getFacilityCapacities(): Promise<FacilityCapacity[]>{
    return this.get<FacilityCapacity[]>('capacities');
  }
   
  getFacilities(): Promise<Facility[]>{
    return this.get<Facility[]>('');
  }

  getFacility(factilityId: number): Promise<Facility>{
    return this.get<Facility>(factilityId.toString());
  }

  updateFacility(factility: Facility): Promise<Facility> {
    return this.put<Facility>('', factility);
  }

  createFacility(factility: Facility): Promise<Facility> {
    return this.post('', factility);
  }

  deleteFacility(facility: Facility): Promise<Facility> {
    return this.delete(facility.facilityId.toString());
  }
}