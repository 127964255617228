/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';
import { Languages } from '../languages';

@Pipe({
  name: 'language'
})
export class LanguagePipe implements PipeTransform {

  transform(languageCode: string): string {
    try {
      return `${Languages.getName(languageCode)} (${languageCode})`;
    } catch (error: any) {
      return 'ERROR - ' + error?.message;
    }
  }
}