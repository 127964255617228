import { Congregation } from './congregation';
import { UserProfile } from './userProfile';
import { WeekendRoleAssignment } from './weekendRoleAssignment';
import { Responsibility } from './responsibility';
import { ConventionJobRole } from './conventionJobRole';

export class Volunteer {
    volunteerId  = 0;
    jwEmail: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    dateOfBirth: Date;
    yearOfBirth: number;
    personalEmail: string;
    congregation: Congregation;
    congregationNumber: number;
    userProfile: UserProfile;
    azureAdUserId: string;
    profileImage: string;
    grade: number;
    recommendationId: number;
    recommendation: ConventionJobRole;
    responsibilities: Responsibility[] = [];
    race: string;

    createdOn: Date;
    createdBy: string;
    updatedOn: Date;
    updatedBy: string;

    displayName: string;
    weekendRoleAssignments: WeekendRoleAssignment[]
}
