import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-venue-size-key',
  templateUrl: './venue-size-key.component.html',
  styleUrls: ['./venue-size-key.component.scss']
})
export class VenueSizeKeyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
