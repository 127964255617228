import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { paths } from './app-paths';

import { NotFoundComponent } from './pages/not-found/not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { HelpComponent } from './pages/help/help.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';

import { PathResolveService } from './core/services/path-resolve.service';
import { roles } from './features/security/roles';
import { AdminComponent } from './pages/admin/admin.component';
import { MsalGuardGroupService } from './core/guards/msal-guard-group.service';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalRedirectComponent } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent, 
    data: { title: 'Home', sortOrder: 50 }
  },
  {
    /**
     * Needed for login on page load for PathLocationStrategy. 
     * See FAQ for details: 
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-angular/docs/FAQ.md
     */
    path: 'auth',
    component: MsalRedirectComponent
  },
  { path: paths.admin, component: AdminComponent, data: { title: 'Administration', sortOrder: 1000, icon: 'tools', expectedRoles: [ roles.superUser ], includeInMenu: true }, canActivate: [MsalGuardGroupService], },
  { path: paths.help, component: HelpComponent, data: { title: 'Help', icon: 'support', includeInMenu: false } },
  { path: paths.terms, component: TermsComponent, data: { title: 'Terms & Conditions', includeInMenu: false } },
  { path: paths.privacyPolicy, component: PrivacyComponent, data: { title: 'Privacy Policy', includeInMenu: false } },
  { path: '**', resolve: { path: PathResolveService }, component: NotFoundComponent, data: { title: 'Page Not Found', includeInMenu: false } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledBlocking' : 'disabled',
    //relativeLinkResolution: 'legacy'
    })],
  exports: [RouterModule]
  })
export class AppRoutingModule { }
