import { Component, OnInit } from '@angular/core';
import { UserAccountService } from 'src/app/core/services/user-account.service';
import { SecurityRole } from '../../features/security/securityRole';
import { AccountInfo } from '@azure/msal-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
  })
export class HomeComponent implements OnInit {
  currentUser: AccountInfo;
  isLoggedIn: boolean;
  canViewCircuits: boolean;
  canViewFacilities: boolean;

  constructor(
    private userAccountService: UserAccountService,) {
  }

  login(): void {
    this.userAccountService.login();
  }

  ngOnInit(): void {
    this.currentUser = this.userAccountService.getAccount();
    this.isLoggedIn = this.userAccountService.isLoggedIn();
    this.canViewCircuits = this.userAccountService.isAccountInGroup(SecurityRole.SuperUser);
    this.canViewFacilities = this.userAccountService.isAccountInGroup(SecurityRole.SuperUser) || this.userAccountService.isAccountInGroup(SecurityRole.TechnicalContact);

    this.userAccountService.subscribeToLogin(() => {
      this.currentUser = this.userAccountService.getAccount();
      this.isLoggedIn = this.userAccountService.isLoggedIn();
      this.canViewCircuits = this.userAccountService.isAccountInGroup(SecurityRole.SuperUser);
      this.canViewFacilities = this.userAccountService.isAccountInGroup(SecurityRole.SuperUser) || this.userAccountService.isAccountInGroup(SecurityRole.TechnicalContact);
    });
  }
}
