import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FocusDirective } from '../../focus.directive';

// 3rd Party Components
import { TableModule } from 'primeng/table';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { RatingModule } from 'primeng/rating';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MessagesModule } from 'primeng/messages';
import { SkeletonModule } from 'primeng/skeleton';

// Routing
import { CongregationsRoutingModule } from './congregations-routing.module';

// Shared Components
import { ControlMessagesComponent } from '../../features/components/control-messages/control-messages.component';

// Components
import { CongregationListComponent } from '../../features/congregations/congregation-list/congregation-list.component';
import { VolunteerListComponent } from '../../features/volunteers/volunteer-list/volunteer-list.component';
import { CircuitListComponent } from '../../features/circuits/circuit-list/circuit-list.component';

// Services
import { CongregationService } from './services/congregation.service';
import { VolunteersService } from './services/volunteers.service';
import { SearchService } from './services/search.service';
import { SharedModule } from '../shared/shared.module';

// Page components
import { PageCircuitsComponent } from './pages/page-circuits/page-circuits.component';
import { PageCongregationsComponent } from './pages/page-congregations/page-congregations.component';
import { PageVolunteersComponent } from './pages/page-volunteers/page-volunteers.component';
import { CongregationAddComponent } from 'src/app/features/congregations/add/congregation-add/congregation-add.component';
import { PageAddCongregationsComponent } from './pages/page-add-congregations-component/page-add-congregations-component.component';
import { PageAddVolunteerComponent } from './pages/page-add-volunteer/page-add-volunteer.component';

@NgModule({
  declarations: [
    CircuitListComponent,
    CongregationListComponent,
    CongregationAddComponent,
    VolunteerListComponent,
    ControlMessagesComponent,
    FocusDirective,
    PageCircuitsComponent,
    PageCongregationsComponent,
    PageVolunteersComponent,
    PageAddCongregationsComponent,
    PageAddVolunteerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    DataViewModule,
    DropdownModule,
    DialogModule,
    CalendarModule,
    RatingModule,
    AutoCompleteModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CongregationsRoutingModule,
    SharedModule,
    ConfirmPopupModule,
    MessagesModule,
    SkeletonModule,
  ],
  providers: [CongregationService, VolunteersService, SearchService],
})
export class CongregationsModule {}
