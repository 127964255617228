import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonType } from 'src/app/features/components/button/buttonType';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
  })
export class ErrorDialogComponent  {

  buttonTypes = ButtonType;
  status: string;
  message: string;
  title: string;
  
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.status = this.config.data.status;
    this.message = this.config.data.message;
    this.title = this.config.data.title;
  }

  closeButtonOnClick(): void {
    this.ref.close();
  }
}