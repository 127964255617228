/* eslint-disable @typescript-eslint/no-unused-vars */
// Custom Pipes
import { PhonePipe } from './pipe/phone.pipe';
import { AgePipe } from './pipe/age.pipe';
import { CircuitPipe } from './pipe/circuit.pipe';
import { LanguagePipe } from './pipe/language.pipe';
import { CapacityPipe } from './pipe/capacity.pipe';
import { GradePipe } from './pipe/grade.pipe';
import { IdPipe } from './pipe/id.pipe';
import { AssignmentPipe } from './pipe/assignment.pipe';

// Local components
import { IconComponent } from '../../features/components/icon/icon.component';
import { VolunteerRatingModalComponent } from '../../features/volunteers/volunteer-rating-modal/volunteer-rating-modal.component';
import { VolunteerAssignmentComponent } from '../../features/volunteers/volunteer-assignment/volunteer-assignment.component';
import { VenueSizeKeyComponent } from '../../features/factilities/venue-size-key/venue-size-key.component';
import { VolunteerEditComponent } from '../../features/volunteers/volunteer-edit/volunteer-edit.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { ButtonComponent } from '../../features/components/button/button.component';

// Local services
import { ErrorDialogService } from './errors/error-dialog.service';

import { IfRolesDirective } from '../../if-roles.directive';
import { HeadingComponent } from '../../features/components/heading/heading.component';
import { ClickOutsideDirective } from './click-outside/clickOutside';
import { PreventDoubleClickDirective } from '../../preventDoubleClick.directive';
import { TextLabelComponent } from '../../features/components/text-label/text-label.component';
import { PillComponent } from '../../features/components/pill/pill.component';
import { BlockUIModule } from 'primeng/blockui';
import { NgModule, forwardRef } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { RatingModule } from 'primeng/rating';
import { EditorModule } from 'primeng/editor';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { InputComponent } from 'src/app/features/components/form/input.component';

const sharedComponents = [
  IconComponent,
  VolunteerRatingModalComponent,
  VolunteerAssignmentComponent,
  VenueSizeKeyComponent,
  VolunteerEditComponent,
  ErrorDialogComponent,
  ButtonComponent,
  HeadingComponent,
  TextLabelComponent,
  PillComponent,
  InputComponent
];

const sharedServices = [
  PhonePipe,
  AgePipe,
  CircuitPipe,
  LanguagePipe,
  CapacityPipe,
  GradePipe,
  IdPipe,
  IfRolesDirective,
  AssignmentPipe,
  ClickOutsideDirective,
  PreventDoubleClickDirective,
];

@NgModule({
  declarations: [...sharedServices, ...sharedComponents],
  imports: [
  CommonModule,
  BrowserModule,
  BlockUIModule,
  FormsModule,
  ReactiveFormsModule,
  TableModule,
  DataViewModule,
  DropdownModule,
  AutoCompleteModule,
  DialogModule,
  CalendarModule,
  RatingModule,
  EditorModule,
  InputTextareaModule,
  FileUploadModule,
  ProgressSpinnerModule,
  MultiSelectModule,
  PanelModule,
  ],
  exports: [...sharedServices, ...sharedComponents],
  providers: [
    ErrorDialogService,
    DecimalPipe
  ],
  })
export class SharedModule {}
