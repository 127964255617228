export const paths = {
  home: 'home',
  search: 'search',
  about: 'about',
  admin: 'admin',
  convention: 'conventions',
  help: 'help',
  terms: 'terms-and-conditions',
  privacyPolicy: 'privacy-policy'
};
