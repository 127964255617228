export enum SecurityRole {
  StandardUser = 'ecd0b445-024b-4a16-b41b-0d465eac6bfb',
  SuperUser = '3456cdb7-e613-431c-87ce-72d2add73638',
  TechnicalContact = '89cdad59-2cee-42bb-96ce-c827c1c57096',
  CommitteeMember = 'ba7719f4-bf84-4df2-85e0-e0635538b822',
  CommitteeAssistant = 'a4146a65-5358-457c-8043-5761d40fc804',
}

export const getSecurityRole = (roleName: string): SecurityRole => {
  return SecurityRole[roleName as keyof typeof SecurityRole];
};
