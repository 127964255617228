import { SelectItem } from 'primeng/api';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-namespace */
export namespace Grades {
    const map: {[index: string]: string} = {
      '1': 'A',
      '2': 'B',
      '3': 'C',
      '4': 'D',
      '5': 'Ungraded',
    };

    export function byAbbreviation() {
      return map;
    }

    export function byName() {
      const results: {[index: string]: string} = {};
      Object.keys(map).forEach(k => {
        results[map[k]] = k;
      });
      return results;
    }

    export function toListValue(): SelectItem[] {
      const results: SelectItem[] = [];
      Object.keys(map).forEach(k => {
        results.push({ label: map[k], value: k });
      });
      return results;
    }

    export function getName(key: string) {
      return map[key];
    }

    export function getGradeValue(key: string): number {
      let value = 0;
      switch (key) {
      case '1':
        value = 4;
        break;
      case '2':
        value = 3;
        break;
      case '3':
        value = 2;
        break;
      case '4':
        value = 1;
        break;
      }

      return value;
    }
}