import { Pipe, PipeTransform } from '@angular/core';
import { Circuit } from '../../../models/circuit';

@Pipe({
  name: 'circuit',
})
export class CircuitPipe implements PipeTransform {
  transform(circuitValue: Circuit, sectionCode?: string): string {
    let debugLog = '';
    debugLog += '\r\n';
    try {
      if (circuitValue) {
        debugLog += '\r\ncircuitValue is not null';
        if (sectionCode) {
          debugLog += '\r\nsectionCode is not null';
          if (circuitValue.language.toUpperCase() === 'E') {
            debugLog += '\r\ncircuitValue.language is E';
            return `${circuitValue.stateCode}-${circuitValue.number}-${sectionCode}`;
          }
          debugLog += '\r\ncircuitValue.language is not E';
          return `${circuitValue.stateCode}-${circuitValue.number}-${sectionCode}-${circuitValue.language.toUpperCase()}`;
        }
        debugLog += '\r\nsectionCode is null';
        debugLog += `\r\ncircuitValue.language is [${circuitValue.language}]`;
        if (circuitValue.language.toUpperCase() === 'E') {
          debugLog += '\r\ncircuitValue.language is E';
          return `${circuitValue.stateCode}-${circuitValue.number}`;
        }
        debugLog += '\r\ncircuitValue.language is not E';
        return `${circuitValue.stateCode}-${circuitValue.number}-${circuitValue.language.toUpperCase()}`;
      } else {
        console.log('No circuit', circuitValue);
        return 'No Circuit';
      }
    } catch (error) {
      console.error(
        'Error in circuit pipe',
        circuitValue,
        sectionCode,
        debugLog,
      );
      return 'ERROR - ' + error.message;
    }
  }
}
