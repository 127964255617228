import { Component, OnInit } from '@angular/core';
import { Volunteer } from 'src/app/models/volunteer';
import { UserAccountService } from 'src/app/core/services/user-account.service';
import { VolunteersService } from 'src/app/modules/congregations/services/volunteers.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
  })
export class OverviewComponent implements OnInit {

  currentUser: Volunteer;
  isEditingProfileImage: boolean;

  constructor(
    private userAccountService: UserAccountService,
    private volunteerService: VolunteersService
  ) { }

  ngOnInit(): void {
    if (!this.userAccountService.isLoggedIn()){
      return;
    }

    this.volunteerService.getCurrentVolunteer()
      .then(result => this.currentUser = result);
  }
  
  onEditProfilePhoto(): void {
    this.isEditingProfileImage = true;
  }

  editProfileImageCancel(): void  {
    console.log('cancelling the profile image update');
    this.isEditingProfileImage = false;
  }

  editProfileImageSave(): void {
    this.volunteerService.getCurrentVolunteer()
      .then(result => {
        this.currentUser = result;
        this.isEditingProfileImage = false;
      });
  }
}