import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Congregation } from '../../../models/congregation';
import { BaseApiService } from 'src/app/core/services/base-api.service';
import { MsalService } from '@azure/msal-angular';
import { PagedResponse } from 'src/app/models/pagedResponse';

@Injectable({
  providedIn: 'root'
})
export class CongregationService
  extends BaseApiService {

  constructor(http: HttpClient, authService: MsalService, @Inject('BASE_URL') baseUrl: string) {
    super(http, authService, 'beta', 'congregations', baseUrl);
  }

  getCongregations(page: number, pageSize: number, congregationNumber: number, congregationName: string, circuit: string): Promise<PagedResponse<Congregation>>{
    if (!page) {
      page = 1;
    }
    if (!pageSize) {
      pageSize = 20;
    }
    congregationName = congregationName ? congregationName : '';
    circuit = circuit ? circuit : '';
    congregationNumber = congregationNumber ? congregationNumber : 0;
    return this.get<PagedResponse<Congregation>>(`?page=${page}&pageSize=${pageSize}&congregationNumber=${congregationNumber}&congregationName=${congregationName}&circuit=${circuit}`);
  }

  search(searchTerm: string) : Promise<Congregation[]> {
    return this.get<Congregation[]>(`search/${searchTerm}`);
  }

  getCongregation(congregationNumber: number) : Promise<Congregation>{
    return this.get<Congregation>(congregationNumber.toString());
  }

  updateCongregation(congregation: Congregation) : Promise<Congregation> {
    congregation.circuitId = congregation.circuit.circuitId;
    return this.put<Congregation>(congregation.congregationNumber.toString(), congregation);
  }

  createCongregation(congregation: Congregation) : Promise<Congregation> {
    congregation.circuitId = congregation.circuit.circuitId;
    return this.post('', congregation);
  }

  deleteCongregation(congregation: Congregation) : Promise<Congregation> {
    return this.get(congregation.congregationNumber.toString() + '/delete');
  }
}
