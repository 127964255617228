import { Component, OnInit } from '@angular/core';
import { CongregationService } from '../../../modules/congregations/services/congregation.service';
import { Congregation } from '../../../models/congregation';
import {
  MessageService,
  SelectItem,
  LazyLoadEvent,
  FilterMetadata,
} from 'primeng/api';
import { ClonerService } from 'src/app/core/services/cloner.service';
import { CircuitPipe } from 'src/app/modules/shared/pipe/circuit.pipe';
import { ButtonType } from '../../components/button/buttonType';
import { SecurityRole } from '../../security/securityRole';
import { CircuitService } from 'src/app/modules/congregations/services/circuit.service';
import { TableLazyLoadEvent } from 'primeng/table';

@Component({
  selector: 'app-congregation-list',
  templateUrl: './congregation-list.component.html',
})
export class CongregationListComponent implements OnInit {
  securityRole = SecurityRole;

  buttonTypes = ButtonType;
  page = 1;
  pageSize = 10;
  congregationName: string;
  congregationNumber: number;
  circuit: string;
  totalRecords: number = 0;
  isSearching = false;

  // Fill the array with dummy data to show the skeleton
  // indicatiors for the loading data.
  congregationList: Congregation[] = [
    new Congregation(),
    new Congregation(),
    new Congregation(),
    new Congregation(),
    new Congregation(),
    new Congregation(),
    new Congregation(),
    new Congregation(),
    new Congregation(),
    new Congregation(),
  ];

  constructor(private congregationService: CongregationService) {}

  ngOnInit(): void {
    this.page = 1;
    this.pageSize = 10;
  }

  lazyLoadCongregations(event: TableLazyLoadEvent): void {
    console.debug(event);
    this.isSearching = true;
    this.page = event.first;
    this.pageSize = event.rows === undefined ? 10 : event.rows;
    this.congregationNumber = event.filters.congregationNumber
      ? (event.filters.congregationNumber as FilterMetadata).value
      : 0;
    this.congregationName = event.filters.congregationName
      ? (event.filters.congregationName as FilterMetadata).value
      : '';
    this.loadCongregations();
  }

  loadCongregations(): void {
    console.debug('loadCongregations');
    console.debug({
      page: this.page,
      pageSize: this.pageSize,
      congregationNUmber: this.congregationNumber,
    });
    this.congregationService
      .getCongregations(
        this.page,
        this.pageSize,
        this.congregationNumber,
        this.congregationName,
        '',
      )
      .then((congregations) => {
        this.congregationList = congregations.records;
        this.totalRecords = congregations.totalRecords;
        this.isSearching = false;
      });
  }
}
