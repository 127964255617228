import { Component, ViewChild } from '@angular/core';
import { Volunteer } from 'src/app/models/volunteer';
import { FileUpload } from 'primeng/fileupload';
import { UserAccountService } from 'src/app/core/services/user-account.service';
import { VolunteersService } from 'src/app/modules/congregations/services/volunteers.service';
import { paths } from '../profile-paths';
import { Router } from '@angular/router';
import { ButtonType } from 'src/app/features/components/button/buttonType';

@Component({
  selector: 'app-passport-photo-edit',
  templateUrl: './passport-photo-edit.component.html',
  styleUrls: ['./passport-photo-edit.component.scss']
  })
export class PassportPhotoEditComponent {

  @ViewChild(FileUpload) profileImageInput: FileUpload;

  currentUser: Volunteer;
  isEditingProfileImage: boolean;
  buttonTypes = ButtonType;

  constructor(
    private userAccountService: UserAccountService,
    private volunteerService: VolunteersService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (!this.userAccountService.isLoggedIn()){
      return;
    }

    this.volunteerService.getCurrentVolunteer()
      .then(result => this.currentUser = result);
  }

  cancel(): void {
    this.router.navigate([paths.overview]);
  }

  save(): void {
    this.profileImageInput.upload();
  }

  onUpload(): void {
    this.router.navigate([paths.overview]);
  }
}