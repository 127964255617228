<div class="p-content">
    <div
        class="block p-6 bg-white rounded-t-lg border border-jw-gray-200 hover:bg-jw-gray-100 dark:bg-jw-gray-800 dark:border-jw-gray-700 dark:hover:bg-jw-gray-700">
        <h4 class="mb-2 text-2xl font-bold tracking-tight text-jw-gray-900 dark:text-white">
            {{ title }}
        </h4>
    </div>
    <div class="block p-6 bg-white rounded-b-lg border border-jw-gray-200 dark:bg-jw-gray-800 dark:border-jw-gray-700">
        <app-congregation-add #congregationEdit (onSave)="onSaved()" (onCancel)="onCancel()"></app-congregation-add>
    </div>
</div>
