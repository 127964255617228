<div class='my-legend'>
    <div class='text-left mb-4 font-bold text-sm text-jw-gray-900 dark:text-jw-gray-100'>Venue Classes - Max Capacity</div>
    <div class='text-left mb-4 font-bold text-xs text-jw-gray-900 dark:text-jw-gray-100'>
        <ul class='legend-labels'>
            <li><span class="w-6 inline-block text-jw-gray-700 dark:text-white">01:</span> 0 - 5000</li>
            <li><span class="w-6 inline-block text-jw-gray-700 dark:text-white">02:</span> 5001 - 12,500</li>
            <li><span class="w-6 inline-block text-jw-gray-700 dark:text-white">03:</span> 12,500 - 25,000</li>
            <li><span class="w-6 inline-block text-jw-gray-700 dark:text-white">04:</span> 25000+</li>
            <li>Assembly Hall</li>
        </ul>
    </div>
</div>