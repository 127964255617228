/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { CircuitService } from '../../../modules/congregations/services/circuit.service';
import { Circuit } from '../../../models/circuit';
import { SelectItem } from 'primeng/api';
import { ConfirmationService, MessageService } from 'primeng/api';
import { States } from '../../../modules/shared/us-states';
import { Languages } from '../../../modules/shared/languages';
import { ClonerService } from 'src/app/core/services/cloner.service';
import { ButtonType } from '../../components/button/buttonType';
import { FilterUtils } from '../../components/utils/filterutils';

@Component({
  selector: 'app-circuit-list',
  templateUrl: './circuit-list.component.html',
  providers: [ConfirmationService]
  })
export class CircuitListComponent implements OnInit {
  circuitList: Circuit[];
  clonedCircuits: { [s: string]: Circuit; } = {};

  buttonTypes = ButtonType;

  showAddCircuitDialog: boolean;
  circuit: Circuit;

  stateCodes: SelectItem[];
  languages: SelectItem[];
  filterTimeout: any;

  first = 0;
  rows = 10;

  constructor(
    private circuitService: CircuitService,
    private messageService: MessageService,
    private clonerService: ClonerService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.loadCircuits();

    this.stateCodes = States.toListValue();
    this.languages = Languages.toListValue();

    FilterUtils['custom'] = (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      return parseInt(filter, 10) > value;
    };
  }

  next(): void {
    this.first = this.first + this.rows;
  }

  prev(): void {
    this.first = this.first - this.rows;
  }

  reset(): void {
    this.first = 0;
  }

  getState(stateCode: string): string {
    return States.getName(stateCode);
  }

  loadCircuits(): void {
    this.circuitService.getCircuits()
      .then(Circuits => {
        this.circuitList = Circuits;
      });
  }

  showDialogToAdd(): void {
    this.circuit = new Circuit();
    this.circuit.circuitId = 0;
    this.showAddCircuitDialog = true;
  }

  save(): void {
    this.circuitService.createCircuit(this.circuit)
      .then(() => {
        this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Circuit created' });
        this.loadCircuits();
        this.showAddCircuitDialog = false;
      });
  }

  cancel(): void {
    this.showAddCircuitDialog = false;
  }

  onRowEditInit(circuit: Circuit): void {
    this.clonedCircuits[circuit.circuitId] = this.clonerService.deepClone<Circuit>(circuit);
  }

  onRowEditSave(circuit: Circuit): void {
    if (circuit.circuitId > 0) {
      delete this.clonedCircuits[circuit.circuitId];
      this.circuitService.updateCircuit(circuit)
        .then(() => {
          this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Circuit is updated' });
          this.loadCircuits();
        });
    } else {
      this.circuitService.createCircuit(circuit)
        .then(() => {
          this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Circuit created' });
          this.loadCircuits();
        });
    }
  }

  onRowDelete(event: any, circuit: Circuit) : void {
    console.debug('Showing confirmation');

    this.clonedCircuits[circuit.circuitId] = this.clonerService.deepClone<Circuit>(circuit);
    this.confirmationService.confirm({
      target: event.target,
      message: `Are you sure that you want to delete circuit no ${circuit.number}? This action cannot be undone!`,
      icon: 'fa-lg mdi mdi-alert',
      accept: () => {
        this.clonedCircuits[circuit.circuitId] = this.clonerService.deepClone<Circuit>(circuit);
        this.circuitService.deleteCircuit(circuit)
          .then(() => {
            this.messageService.add({ severity: 'success', summary: 'Circuit Deleted', detail: 'Circuit succesfully deleted' });
            this.loadCircuits();
          });
      },
    });
  }

  onRowEditCancel(circuit: Circuit, index: number): void {
    this.circuitList[index] = this.clonedCircuits[circuit.circuitId];
    delete this.clonedCircuits[circuit.circuitId];
  }

  onStateCodeChange(event, dt): void {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }

    this.filterTimeout = setTimeout(() => {
      dt.filter(event.value, 'stateCode', 'equals');
    });
  }
}
