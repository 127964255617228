import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseApiService } from "src/app/core/services/base-api.service";
import { MsalService } from "@azure/msal-angular";
import { SearchResult } from "src/app/models/searchResult";
import { SearchRequest } from "src/app/models/searchRequest";
import { EventSourcePolyfill } from "event-source-polyfill";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SearchService extends BaseApiService {
  private eventSource: EventSourcePolyfill;

  constructor(
    http: HttpClient,
    authService: MsalService,
    @Inject("BASE_URL") baseUrl: string,
  ) {
    super(http, authService, "beta", "search", baseUrl);
  }

  searchByName(name: string): Promise<SearchResult> {
    return this.get<SearchResult>("list-by-name/" + name);
  }

  search(request: SearchRequest): Promise<SearchResult> {
    if (request.term === "") {
      request.term = "*";
    }
    return this.post<SearchResult>("search", request);
  }

  reindexVolunteers(eventHandler?: (value: any) => void): void {
    const request = {
      scopes: ["api://9ee11ebd-e096-433d-99d3-db49c881c095/access-as-user"],
    };

    this.authService.instance
      .acquireTokenSilent(request)
      .then((tokenReponse) => {
        const es = new Observable((observer) => {
          this.eventSource = new EventSourcePolyfill(
            this.myApiUrl + "reindex",
            {
              headers: {
                Authorization: "Bearer " + tokenReponse.accessToken,
              },
            },
          );

          this.eventSource.onmessage = (event) => {
            console.debug(
              "AdminService: reindexVolunteers: onmessage",
              event.data,
              event,
            );
            if (eventHandler) {
              eventHandler(event);
            }
            observer.next(event.data);
          };

          this.eventSource.onerror = (error) => {
            this.closeEventSource();
          };
        });

        es.subscribe();
      });
  }

  closeEventSource(): void {
    this.eventSource.close();
  }
}
