<section>
    <div class="w-full">
        <div class="bg-white dark:bg-jw-gray-900 p-3 shadow-md rounded-md flex">
            <div class="flex-shrink mr-2 p-2">
                <div class="w-40">
                    <img *ngIf="currentUser.profileImage" class="mb-4 rounded-full"
                        src="https://storageconpertool.blob.core.windows.net/images/{{ currentUser.profileImage }}"
                        alt="{{ currentUser.firstName }} {{ currentUser.lastName }}" />
                </div>
                <app-button styleClass="w-full" [routerLink]="[editPassportLink]" [type]="buttonTypes.Edit"
                    label="Edit Image">
                    <span class="fa fa-file-upload fa-lg"></span> Edit Image
                </app-button>
            </div>
            <div>
                <div class="flex items-center space-x-2 font-semibold text-jw-gray-900 dark:text-jw-gray-50 leading-8 p-2">
                    <dl class="box-border">
                        <dt class="box-border text-sm text-jw-gray-700 dark:text-jw-gray-100">Legal name</dt>
                        <dd class="pt-1 mb-3 text-md font-semibold">
                            {{ currentUser.lastName }}, {{currentUser.firstName }}
                        </dd>
                        <dt class="box-border text-sm text-jw-gray-700 dark:text-jw-gray-100">Congregation</dt>
                        <dd class="pt-1 mb-3 text-md font-semibold">
                            {{ currentUser.congregation.congregationName }} ({{
                            currentUser.congregation.congregationNumber }})
                        </dd>
                        <dt class="box-border text-sm text-jw-gray-700 dark:text-jw-gray-100">Year of Birth</dt>
                        <dd class="pt-1 mb-3 text-md font-semibold">{{ currentUser.yearOfBirth | age }}</dd>
                    </dl>
                </div>
                <div class="flex items-center space-x-2 font-semibold text-jw-gray-900 dark:text-jw-gray-50 leading-8 p-2">
                    <h3 class="mb-2 text-lg font-bold tracking-tight text-jw-gray-900 dark:text-white">Responsibilities</h3>
                    <span *ngFor="let res of currentUser.responsibilities" class="badge badge-info m-1">
                        {{ res.responsibility.responsibility }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="mt-4">
    <div class="w-full">
        <div class="bg-white dark:bg-jw-gray-900 p-3 shadow-md rounded-md">
            <h2 class="mb-2 text-xl font-bold tracking-tight text-jw-gray-900 dark:text-white">Contact Details</h2>
            <dl class="box-border">
                <dt class="box-border text-sm text-jw-gray-700 dark:text-jw-gray-100">JW Email:</dt>
                <dd class="pt-1 mb-3 text-md font-semibold">{{ currentUser.jwEmail }}</dd>
                <dt class="box-border text-sm text-jw-gray-700 dark:text-jw-gray-100">Personal Email</dt>
                <dd class="pt-1 mb-3 text-md font-semibold">{{ currentUser.personalEmail }}</dd>
                <dt class="box-border text-sm text-jw-gray-700 dark:text-jw-gray-100">Contact Phone Number</dt>
                <dd class="pt-1 mb-3 text-md font-semibold">{{ currentUser.phoneNumber }}</dd>
                <dt class="box-border text-sm text-jw-gray-700 dark:text-jw-gray-100">Azure Id</dt>
                <dd class="pt-1 mb-3 text-md font-semibold">{{ currentUser.userProfile.userId }}</dd>
            </dl>
        </div>
    </div>
</section>
<!-- <section>
    <div class="card-body">
        <h3>TODO List:</h3>
        <ul>
            <li>
                <a routerLink="/facilities/1/weekend/1">Grading for 3 Volunteers</a>
            </li>
        </ul>
    </div>
</section> -->
