import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewComponent } from './overview/overview.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { PassportPhotoEditComponent } from './passport-photo-edit/passport-photo-edit.component';

import { FileUploadModule } from 'primeng/fileupload';
import { ProfileRoutingModule } from './profile-routing.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
  OverviewComponent, 
  PersonalInformationComponent, 
  PassportPhotoEditComponent,
  ],
  imports: [
  CommonModule,
  SharedModule,
  FileUploadModule,
  ProfileRoutingModule
  ],
  exports: [
  OverviewComponent,
  PersonalInformationComponent,
  PassportPhotoEditComponent,
  ]
  })
export class ProfileModule { }
