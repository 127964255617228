import { Component, Input, OnInit } from '@angular/core';
import { ButtonComponent } from '../button/button.component';

export enum LabelType {
  Heading,
  Label
}

@Component({
  selector: 'app-text-label',
  templateUrl: './text-label.component.html',
  styleUrls: ['./text-label.component.scss']
  })
export class TextLabelComponent implements OnInit {
  @Input() labelType: LabelType;

  cssClass: string;
  public labelTypes = LabelType;

  ngOnInit(): void {
    if (!this.labelType) {
      this.labelType = this.labelTypes.Heading;
    }

    switch(this.labelType) {
    case LabelType.Heading:
      this.cssClass  = 'box-border text-sm text-jw-gray-700 dark:text-cpt-purple-10 font-medium';
      break;
    default:
      this.cssClass = 'pt-1 mb-3 text-md font-semibold dark:text-white';
      break;
    }
  }
}
