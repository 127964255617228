import { Weekend } from './weekend';
import { FacilityCapacity } from './facilityCapacity';

export class Facility {
    facilityId: number;
    name: string;
    city: string;
    state: string;
    maxCapacity: number;
    branchOwned: boolean;
    weekends: Weekend[];
    facilityCapacity: FacilityCapacity;
    technicalContactVolunteerId: number;
}