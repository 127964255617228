import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'age'
  })
export class AgePipe implements PipeTransform {

  transform(yearOfBirthValue: number | string, hideDoB = false): string {
    try {
      const today = new Date();
      const yearOfBirth = Number.parseInt(yearOfBirthValue.toString());
      if (yearOfBirth === 1) {
        return '(n/a)';
      }

      const age = today.getFullYear() - yearOfBirth;

      const datePipe = new DatePipe('en-US');
      if (hideDoB) {
        return `${age}`;
      }
      return `${yearOfBirth} (${age})`;

    } catch (error) {
      console.log(error);
      return yearOfBirthValue.toString();
    }
  }
}
