<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="mb-6">
        <label for="congregationNumber"
            class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Congregation Number:</label>
        <p-skeleton *ngIf="isLoading" height="3rem" />
        <input *ngIf="!isLoading"
            class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required minlength="5"
            [ngClass]="{ 'focus:ring-red-500 focus:border-red-500 dark:bg-red-100 dark:border-red-400': submitted && f.congregationNumber.errors }"
            pInputText type="number" id="congregationNumber" name="congregationNumber"
            formControlName="congregationNumber" />
        <div *ngIf="submitted && f.congregationNumber.errors" class="mt-2 text-red-700 dark:text-red-500">
            The congregation number is required.
        </div>
    </div>
    <div class="mb-6">
        <label for="congregationName"
            class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Name</label>
        <p-skeleton *ngIf="isLoading" height="3rem" />
        <input *ngIf="!isLoading"
            class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required pInputText id="congregationName"
            [ngClass]="{ 'focus:ring-red-500 focus:border-red-500 dark:bg-red-100 dark:border-red-400': submitted && f.congregationName.errors }"
            required formControlName="congregationName" name="congregationName" />
        <div *ngIf="submitted && f.congregationName.errors" class="mt-2 text-red-700 dark:text-red-500">
            The congregation name is required.
        </div>
    </div>
    <div class="mb-6">
        <label id="congregationCircuitLabel"
            class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Circuit</label>
        <p-skeleton *ngIf="isLoading" height="3rem" />
        <p-dropdown ariaLabelledBy="congregationCircuitLabel" appendTo="body" [options]="circuits" *ngIf="!isLoading"
            formControlName="congregationCircuit" required
            styleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 flex w-full px-1 dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            [ngClass]="{ 'focus:ring-red-500 focus:border-red-500 dark:bg-red-100 dark:border-red-400': submitted && f.congregationCircuit.errors }"
            [filter]="true" placeholder="Select a circuit" [showClear]="true">
            <ng-template pTemplate="selectedItem">
                {{ f.congregationCircuit.value | circuit }}
            </ng-template>
            <ng-template let-item pTemplate="item">
                {{ item.label }}
            </ng-template>
        </p-dropdown>
        <div *ngIf="submitted && f.congregationCircuit.errors" class="mt-2 text-red-700 dark:text-red-500">
            The congregations circuit is required.
        </div>

    </div>
    <div class="mb-6">
        <label for="congregationCircuitSection"
            class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Circuit
            Section</label>
        <p-skeleton *ngIf="isLoading" height="3rem" />
        <input id="congregationCircuitSection" name="congregationCircuitSection" *ngIf="!isLoading"
            class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            formControlName="congregationCircuitSection" pInputText type="text">
    </div>

    <app-button [type]="buttonTypes.Save" label="Save"></app-button>
    <app-button [type]="buttonTypes.Cancel" label="Cancel" [routerLink]="['/congregation']"></app-button>
</form>
