<div class="w-full" *ngIf="newWeekend">
    <div class="mb-6">
        <label for="conventionYear" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Convention Year</label>
        <input class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                      dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            pInputText id="conventionYear" name="conventionYear" [(ngModel)]="newWeekend.conventionYear.year"
            autofocus focus />
    </div>
    <div class="mb-6">
        <label for="weekendNumber" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Weekend Number</label>
        <input class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                      dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            pInputText type="number" id="weekendNumber" name="weekendNumber"
            [(ngModel)]="newWeekend.weekendNumber" />
    </div>
    <div class="mb-6">
        <label for="weekendDates" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Weekend Dates</label>
        <input class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                      dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            pInputText type="text" id="weekendDates" name="weekendDates"
            [(ngModel)]="newWeekend.weekendDate" />
    </div>
    <div class="mb-6">
        <label for="language" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Language</label>
        <p-dropdown id="language" name="language" [options]="languages" appendTo="body" [(ngModel)]="newWeekend.weekendLanguage" [filter]="true"
            [virtualScroll]="true" [itemSize]="60" [required]="true" aria-required="true" [autofocus]="true" focus
            ></p-dropdown>
    </div>
    <div class="mb-6" *ngIf="isFeatureEnabled('v8')">
        <label for="weekendType" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Weekend Type</label>
        <p-dropdown id="weekendType" name="weekendType" [options]="weekendTypes" appendTo="body" [(ngModel)]="newWeekend.weekendTypeId" [filter]="true"
            [virtualScroll]="true" [itemSize]="60" [required]="true" aria-required="true" [autofocus]="true" focus
            ></p-dropdown>
    </div>

</div>
<p-footer>
    <div class="w-full text-right">
        <app-button [type]="buttonTypes.Save" label="Save" (click)="save()"></app-button>
        <app-button [type]="buttonTypes.Cancel" label="Cancel" (click)="cancel()" styleClass="mr-0"></app-button>
    </div>
</p-footer>
