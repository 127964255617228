import { Component, OnInit } from '@angular/core';
import { Facility } from '../../../models/facility';
import { Weekend } from '../../../models/weekend';
import { SelectItem, MessageService } from 'primeng/api';
import { Languages } from 'src/app/modules/shared/languages';
import { ClonerService } from 'src/app/core/services/cloner.service';
import { WeekendsService } from '../../../modules/facilities/services/weekends.service';
import { ButtonType } from '../../components/button/buttonType';
import { DialogService } from 'primeng/dynamicdialog';
import { AddWeekendModalComponent } from '../../../modules/facilities/add-weekend-modal/add-weekend-modal.component';
import { WeekendTypes } from 'src/app/modules/shared/weekendTypes';
import posthog from 'posthog-js';

@Component({
  selector: 'app-facility-weekends',
  templateUrl: './facility-weekends.component.html',
  inputs: [
  'facility'
  ],
  providers: [DialogService]
  })
export class FacilityWeekendsComponent implements OnInit {

  facility: Facility;
  weekends: Weekend[];
  clonedWeekends: { [s: string]: Weekend; } = {}
  languages: SelectItem[];
  weekendTypes: SelectItem[] = [];

  newWeekend: Weekend;
  showNewWeekendDialog: boolean;

  buttonTypes = ButtonType;

  constructor(
    private weekendsService: WeekendsService,
    private messageService: MessageService,
    private clonerService: ClonerService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.languages = Languages.toListValue();
    this.weekends = this.facility.weekends;
    this.weekendTypes = WeekendTypes.toListValue();
  }

  isFeatureEnabled(flagName: string) {
    return posthog.isFeatureEnabled(flagName);
  }

  loadWeekends(): void {
    this.weekendsService.getWeekendsForFacility(this.facility.facilityId, 0)
      .then(weekends => this.weekends = weekends);
  }

  addWeekend(event): void {
    event.preventDefault();
    console.debug('Add weekend click');

    const ref = this.dialogService.open(AddWeekendModalComponent, {
      header: 'Add New Weekend',
      baseZIndex: 0,
      width: '50vw',
      styleClass: 'bg-jw-gray-100 text-jw-gray-900 dark:bg-jw-gray-800 dark:text-white',
      data: {
        facility: this.facility
      }
    });

    ref.onClose.subscribe((result: boolean) => {
      if (result) {
        this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'New weekend added' });
        this.loadWeekends();
      }
    });
  }

  editWeekend(weekend: Weekend): void {
    console.debug('Edit weekend click');

    const ref = this.dialogService.open(AddWeekendModalComponent, {
      header: 'Edit Weekend',
      baseZIndex: 0,
      width: '50vw',
      styleClass: 'bg-jw-gray-100 text-jw-gray-900 dark:bg-jw-gray-800 dark:text-white',
      data: {
        facility: this.facility,
        weekend: weekend
      }
    });

    ref.onClose.subscribe((result: boolean) => {
      if (result) {
        this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'New weekend added' });
        this.loadWeekends();
      }
    });
  }
}
