/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { roles } from '../../features/security/roles';
import { UserAccountService } from '../services/user-account.service';

@Injectable({
  providedIn: 'root'
  })
export class MsalGuardGroupService  {

  constructor(
    private userAccountService: UserAccountService,
    public router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    console.debug('Checking if user has access...');
    const expectedRoles = route.data.expectedRoles;

    let hasAccess = false;
    expectedRoles.forEach(role => {
      if (this.userAccountService.isAccountInGroup(role)){
        hasAccess  = true;
      }
    });

    if (hasAccess) {
      return true;
    }

    // Super users can see everything...
    console.log('Checking if we are a super user!!');
    if (this.userAccountService.isAccountInGroup(roles.superUser)){
      return true;
    }

    this.userAccountService.login();
    return false;
  }
}
