export enum ButtonType {
    Primary,
    Secondary,
    Edit,
    Delete,
    Create,
    Add,
    Cancel,
    Save,
    Volunteers,
    VolunteerEdit,
    Assignments,
    Search,
    AddGrade,
}
