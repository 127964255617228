import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { paths } from './profile-paths';

import { OverviewComponent } from './overview/overview.component';
import { PassportPhotoEditComponent } from './passport-photo-edit/passport-photo-edit.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { MsalGuardGroupService } from 'src/app/core/guards/msal-guard-group.service';

const routes: Routes = [
  {
    path: paths.overview,
    component: OverviewComponent,
    data: { title: 'My Profile', icon: 'user', includeInMenu: false }
  },
  {
    path: paths.passportPhoto,
    component: PassportPhotoEditComponent,
    data: { title: 'Edit Passport-Style Photo', icon: 'user', includeInMenu: false }
  },
  {
    path: paths.personalInformation,
    component: PersonalInformationComponent,
    data: { title: 'Personal Information', icon: '', includeInMenu: false }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
  })
export class ProfileRoutingModule { }
