/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit } from '@angular/core';
import { Facility } from '../../../models/facility';
import { FacilitiesService } from '../../../modules/facilities/services/facilities.service';
import { MessageService } from 'primeng/api';
import { ClonerService } from 'src/app/core/services/cloner.service';
import { SelectItem } from 'primeng/api/selectitem';
import { States } from 'src/app/modules/shared/us-states';
import { ButtonType } from '../../components/button/buttonType';
import { SecurityRole } from '../../security/securityRole';
import { UserAccountService } from 'src/app/core/services/user-account.service';

@Component({
  selector: 'app-facilities-list',
  templateUrl: './facilities-list.component.html'
  })
export class FacilitiesListComponent implements OnInit {
  isLoading = false;
  showNewFacilityDialog = false;
  facility: Facility | undefined;
  facilitiesList: Facility[] | undefined;
  clonedFacilities: { [s: string]: Facility; } = {};
  stateCodes: SelectItem[] | undefined;
  buttonTypes = ButtonType;
  securityRole = SecurityRole;
  canAddFacilities = false;

  constructor(
    private facilitiesService: FacilitiesService,
    private messageService: MessageService,
    private clonerService: ClonerService,
    private userAccountService: UserAccountService
  ) { }

  ngOnInit() : void {
    this.canAddFacilities = this.userAccountService.isAccountInGroup(SecurityRole.SuperUser);
    this.stateCodes = States.toListValue();
    this.loadFacilities();
  }

  showDialogToAdd() : void {
    this.facility = new Facility();
    this.facility.facilityId = 0;
    this.showNewFacilityDialog = true;
  }

  save() : void {
    if (this.facility) {
      this.facilitiesService.createFacility(this.facility)
        .then(() => {
          this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'New facility created' });
          this.loadFacilities();
          this.showNewFacilityDialog = false;
        });
    }
  }

  cancel() : void {
    this.showNewFacilityDialog = false;
  }

  getState(stateCode: string) : string {
    return States.getName(stateCode);
  }

  loadFacilities() : void {
    this.isLoading = true;
    this.facilitiesService.getFacilities()
      .then(facilities => {
        this.isLoading = false;
        this.facilitiesList = facilities;
      });
  }

  onNewRow(facility: Facility) : void {
    this.clonedFacilities[facility.facilityId] = this.clonerService.deepClone<Facility>(facility);
  }

  onRowEditInit(facility: Facility) : void {
    this.clonedFacilities[facility.facilityId] = this.clonerService.deepClone<Facility>(facility);
  }

  onRowEditSave(facility: Facility) : void {
    if (facility.facilityId > 0) {
      delete this.clonedFacilities[facility.facilityId];
      this.facilitiesService.updateFacility(facility)
        .then(() => {
          this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Facility is updated' });
          this.loadFacilities();
        });
    } else {
      this.facilitiesService.createFacility(facility)
        .then(() => {
          this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Facility created' });
          this.loadFacilities();
        });
    }
  }

  onRowEditCancel(facility: Facility, index: number) : void {
    if (this.facilitiesList) {
      this.facilitiesList[index] = this.clonedFacilities[facility.facilityId];
      delete this.clonedFacilities[facility.facilityId];
    }
  }
}
