/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem, MessageService } from 'primeng/api';

import { ConventionJob } from 'src/app/models/conventionJob';
import { ConventionJobRole } from 'src/app/models/conventionJobRole';
import { ConventionJobSubRole } from 'src/app/models/conventionJobSubRole';
import { Facility } from '../../../models/facility';
import { Volunteer } from 'src/app/models/volunteer';
import { Weekend } from '../../../models/weekend';
import { WeekendRoleAssignment } from 'src/app/models/weekendRoleAssignment';

import { FacilitiesService } from '../../../modules/facilities/services/facilities.service';
import { WeekendsService } from '../../../modules/facilities/services/weekends.service';
import { LookupsService } from 'src/app/core/services/lookups.service';
import { VolunteerRatingModalComponent } from '../../volunteers/volunteer-rating-modal/volunteer-rating-modal.component';

import { ButtonType } from '../../components/button/buttonType';
import { SecurityRole } from '../../security/securityRole';
import { UserAccountService } from 'src/app/core/services/user-account.service';
import { VolunteersService } from 'src/app/modules/congregations/services/volunteers.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SearchService } from '../../../modules/congregations/services/search.service';
import { SearchRequest } from '../../../models/searchRequest';

@Component({
  selector: 'app-add-assignment',
  templateUrl: './add-assignment.component.html',
  styleUrls: ['./add-assignment.component.scss']
})
export class AddAssignmentComponent implements OnInit {

  submitted = false;
  form: FormGroup;

  buttonTypes = ButtonType;
  securityRole = SecurityRole;

  technicalContact: Volunteer;
  facility: Facility;
  weekend: Weekend;
  isLoaded: boolean;
  isSuperUser = false;
  isInitialGradeImport = false;

  weekendId: number;
  facilityId: number;

  conventionJobs: SelectItem[] = [];
  conventionJobRoles: SelectItem[] = [];
  conventionJobSubRoles: ConventionJobSubRole[] = [];
  filteredJobSubRoles: SelectItem[] = [];
  selectedJob: ConventionJob;
  selectedJobRole: ConventionJobRole;
  selectedJobSubRole: ConventionJobSubRole;
  selectedVolunteer: Volunteer;
  volunteerSearchList: Volunteer[];

  @ViewChild('volunteerRatingModal')
  volunteerRatingModal: VolunteerRatingModalComponent;

  constructor(
    private searchService: SearchService,
    private volunteerService: VolunteersService,
    private facilitiesService: FacilitiesService,
    private weekendsService: WeekendsService,
    private lookupsService: LookupsService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private userAccountService: UserAccountService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.facilityId = Number(this.route.snapshot.paramMap.get('facilityId'));
    this.weekendId = Number(this.route.snapshot.paramMap.get('weekendId'));

    this.isSuperUser = this.userAccountService.isAccountInGroup(SecurityRole.SuperUser);
    this.loadData();

    this.form = new FormGroup({
      selectedJob: new FormControl(this.selectedJob, [ Validators.required ]),
      selectedJobRole: new FormControl(this.selectedJobRole, [ Validators.required ]),
      selectedJobSubRole: new FormControl(this.selectedJobSubRole),
      selectedVolunteer: new FormControl(this.selectedVolunteer, [ Validators.required ])
    });
  }

  get f(): any { return this.form.controls; };

  async loadData(): Promise<void> {
    await this.facilitiesService.getFacility(this.facilityId)
      .then(async result => {
        this.facility = result;
        await this.loadTechnicalContact();
        await this.loadWeekend();

        this.isLoaded = true;
      });

    await this.loadConventionJobs();
    await this.loadConventionJobRoles();
    await this.loadConventionJobSubroles();
  }

  async loadConventionJobs(): Promise<void> {
    this.lookupsService.getConventionJobs()
      .then(result => {
        result.forEach((job) => {
          this.conventionJobs.push({ label: job.jobTitle, value: job });
        });
      });
  }

  async loadConventionJobRoles(): Promise<void> {
    this.lookupsService.getConventionJobRoles()
      .then(result => {
        result.forEach((role) => {
          this.conventionJobRoles.push({ label: role.roleTitle, value: role });
        });
      });
  }

  async loadConventionJobSubroles(): Promise<void> {
    this.lookupsService.getConventionJobSubRoles()
      .then(result => {
        this.conventionJobSubRoles = result;
      });
  }

  async loadTechnicalContact(): Promise<void> {
    if (this.facility.technicalContactVolunteerId) {
      await this.volunteerService.getVolunteer(this.facility.technicalContactVolunteerId)
        .then(result => this.technicalContact = result);
    }
  }

  async loadWeekend(): Promise<void> {
    await this.weekendsService.getWeekend(this.weekendId)
      .then(async result => {
        this.weekend = result;
      });
  }

  async searchVolunteers(event: any): Promise<void> {
    const searchRequest = new SearchRequest();
    searchRequest.term = event.query;
    searchRequest.pageSize = 50;
    searchRequest.skip = 0;

    const data = await this.searchService.search(searchRequest);
    this.volunteerSearchList = data.volunteers;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    console.log('Saving role assignment');

    const newAssignment = new WeekendRoleAssignment();
    newAssignment.conventionJobId = this.f.selectedJob.value.conventionJobId;
    newAssignment.conventionJobRoleId = this.f.selectedJobRole.value.conventionJobRoleId;
    newAssignment.conventionJobSubRoleId = this.f.selectedJobSubRole.value?.conventionJobSubRoleId ?? 0;
    newAssignment.volunteerId = this.f.selectedVolunteer.value.volunteerId;
    newAssignment.weekendId = this.weekendId;
    this.weekendsService.addRoleAssignment(newAssignment)
      .then(() => {
        this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Role assignment added to weekend' });
        this.router.navigate([`/facilities/${this.facilityId}/weekend/${this.weekendId}`]);
      })
      .catch((error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error
        });
      });
  }

  populateSubRoles(): void {
    this.filteredJobSubRoles = [];
    this.conventionJobSubRoles.map(role => {
      if (role.conventionJobId === this.f.selectedJob.value.conventionJobId) {
        this.filteredJobSubRoles.push({ label: role.subRoleTitle, value: role });
      }
    });
  }
}
