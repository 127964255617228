import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-volunteers',
  templateUrl: './page-volunteers.component.html',
  styleUrls: ['./page-volunteers.component.scss']
})
export class PageVolunteersComponent implements OnInit {
  ngOnInit(): void {
  }
}
