<div class="flex flex-row p-6 rounded-lg mb-4 bg-jw-gray-50 border border-jw-gray-50 dark:border-jw-gray-800 shadow-md dark:bg-jw-gray-800">
    <div class="basis-1/2">
        <h4 class="mb-2 text-2xl font-bold tracking-tight text-jw-gray-900 dark:text-white">Facilities List:</h4>
        <p class="font-normal text-jw-gray-700 dark:text-jw-gray-100">
            Facilities Count: {{ facilitiesList?.length }}
        </p>
        <p class="font-normal text-jw-gray-700 dark:text-jw-gray-100" *ngIf="isLoading"><em>Loading...</em></p>
    </div>
    <div class="hidden md:block lg:block xl:block basis-1/2">
        <app-venue-size-key></app-venue-size-key>
    </div>
</div>
<div class="w-full" *ngIf="!isLoading">
    <p-table [value]="facilitiesList" [paginator]="true" [rows]="10" dataKey="facilityId" editMode="row"
        responsiveLayout="stack">
        <ng-template pTemplate="header">
            <tr>
                <th scope="col" class="rounded-tl-lg"></th>
                <th scope="col">Facility Name</th>
                <th scope="col">City</th>
                <th scopt="col">State</th>
                <th scope="col">Venue Size (Max Cap.)</th>
                <th scope="col"></th>
                <th scope="col" class="w-2/12 rounded-tr-lg"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-facility let-editing="editing" let-ri="rowIndex" let-expanded="expanded">
            <tr [pEditableRow]="facility">
                <td>
                    <a href="#" [pRowToggler]="facility">
                        <i [ngClass]="expanded ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-right'"></i>
                    </a>
                </td>
                <td>
                    <span class="p-column-title">Name:</span>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="facility.name" [required]="true"
                                class="form-control"><br />
                            Technical Contact Id: <input pInputText type="number"
                                [(ngModel)]="facility.technicalContactVolunteerId" required class="form-control">
                        </ng-template>
                        <ng-template pTemplate="output">{{ facility.name }}</ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <span class="p-column-title">City:</span>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="facility.city" [required]="true"
                                class="form-control" />
                        </ng-template>
                        <ng-template pTemplate="output">{{ facility.city }}</ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <span class="p-column-title">State:</span>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-dropdown [options]="stateCodes" [(ngModel)]="facility.state" [required]="true" aria-required>
                            </p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">{{ getState(facility.state) }}</ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <span class="p-column-title">Venue size:</span>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="number" [(ngModel)]="facility.maxCapacity" required
                                class="form-control" />
                            <p-checkbox [(ngModel)]="facility.branchOwned" [binary]="true" label="Branch Owned">
                            </p-checkbox>
                        </ng-template>
                        <ng-template pTemplate="output">{{ facility.facilityCapacity | capacity : facility }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    ({{ facility.weekends.length }}) current weekends
                </td>
                <td>
                    <app-button *ngIf="!editing" pInitEditableRow [initRowEdit]="true" [hideLabel]="true"
                        label="Edit Facility" [type]="buttonTypes.Edit" (click)="onRowEditInit(facility)">
                    </app-button>

                    <app-button *ngIf="editing" pSaveEditableRow [hideLabel]="true" label="Save Facility"
                        [type]="buttonTypes.Save" (click)="onRowEditSave(facility)"></app-button>
                    <app-button *ngIf="editing" pCancelEditableRow [hideLabel]="true" label="Cancel"
                        [type]="buttonTypes.Cancel" (click)="onRowEditCancel(facility, ri)"></app-button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-facility>
            <tr>
                <td colspan="7">
                    <app-facility-weekends [facility]="facility"></app-facility-weekends>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary" *ngIf="canAddFacilities">
            <app-button [type]="buttonTypes.Add" [requiredRoles]="[securityRole.SuperUser]" label="Add Facility"
                (click)="showDialogToAdd()"></app-button>
        </ng-template>
    </p-table>
</div>

<p-dialog header="Add Facility" [(visible)]="showNewFacilityDialog" [focusOnShow]="true" showEffect="fade"
    [modal]="true" [style]="{ width: '50vw' }" styleClass="bg-jw-gray-100 text-jw-gray-900 dark:bg-jw-gray-800 dark:text-white">
    <div *ngIf="facility">
        <div class="mb-4">
            <label for="facilityName"
                class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Name:</label>
            <input
                class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                      dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                [(ngModel)]="facility.name" autofocus focus pInputText type="text" id="facilityName"
                name="facilityName" />
        </div>
        <div class="mb-4">
            <label for="facilityCity" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">City:</label>
            <input
                class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                      dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                [(ngModel)]="facility.city" autofocus focus pInputText type="text" id="facilityCity"
                name="facilityCity" />
        </div>
        <div class="mb-4">
            <label for="facilityState" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">State:</label>
            <p-dropdown id="state" appendTo="body" [options]="stateCodes" [(ngModel)]="facility.state" [required]="true" id="facilityState" name="facilityState"
                aria-required [autofocus]="true" focus></p-dropdown>
        </div>
        <div class="mb-4">
            <label for="facilityMaxCapacity" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Max Capacity:</label>
            <input
                class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                      dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                [(ngModel)]="facility.maxCapacity" autofocus focus pInputText type="number" id="facilityMaxCapacity"
                name="facilityMaxCapacity" />
            <p-checkbox [(ngModel)]="facility.branchOwned" [binary]="true" label="Is Branch Owned"></p-checkbox>
        </div>
        <div class="mb-4">
            <label for="facilityContactId" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Technical Contact Id</label>
            <input pInputText type="number" [(ngModel)]="facility.technicalContactVolunteerId" required id="facilityContactId" name="facilityContactId"
                  class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                         dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
        </div>
    </div>
    <p-footer>
        <div class="w-full text-right">
            <app-button [type]="buttonTypes.Save" label="Save" (click)="save()"></app-button>
            <app-button [type]="buttonTypes.Cancel" label="Cancel" (click)="cancel()" styleClass="mr-0"></app-button>
        </div>
    </p-footer>
</p-dialog>
