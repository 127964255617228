import { FacetGroup } from './facetGroup';

export class SearchRequest {
    skip: number;
    pageSize: number;
    term: string;
    responsibilities: FacetGroup;
    congregations: FacetGroup;
    roles: FacetGroup;
    departments: FacetGroup;
    circuits: FacetGroup;
    recommendations: FacetGroup;
    minGrade: number;
    maxGrade: number;
}