  import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { WeekendRoleAssignment } from '../../../models/weekendRoleAssignment';
import { SelectItem, MessageService } from 'primeng/api';
import { ConventionJob } from '../../../models/conventionJob';
import { ConventionJobRole } from '../../../models/conventionJobRole';
import { ConventionJobSubRole } from '../../../models/conventionJobSubRole';
import { Volunteer } from '../../../models/volunteer';
import { Weekend } from '../../../models/weekend';
import { WeekendsService } from '../../../modules/facilities/services/weekends.service';
import { LookupsService } from '../../../core/services/lookups.service';
import { ButtonType } from '../../components/button/buttonType';

@Component({
  selector: 'app-volunteer-assignment',
  templateUrl: './volunteer-assignment.component.html',
  styleUrls: ['./volunteer-assignment.component.scss']
  })
export class VolunteerAssignmentComponent implements OnInit {

  @Output()
  onAssignmentSaved: EventEmitter<WeekendRoleAssignment> = new EventEmitter<WeekendRoleAssignment>();

  showAddRoleAssignmentDialog: boolean;

  conventionJobs: SelectItem[] = [];
  conventionJobRoles: SelectItem[] = [];
  conventionJobSubRoles: ConventionJobSubRole[] = [];
  filteredJobSubRoles: SelectItem[] = [];

  selectedJob: ConventionJob;
  selectedJobRole: ConventionJobRole;
  selectedJobSubRole: ConventionJobSubRole;
  selectedVolunteer: Volunteer;
  volunteerSearchList: Volunteer[];

  assignment: WeekendRoleAssignment;

  saveButtonText = 'Add Assignment';
  buttonTypes = ButtonType;
  weekend: Weekend;

  constructor(
    private weekendsService: WeekendsService,
    private messageService: MessageService,
    private lookupsService: LookupsService,
  ) { }

  ngOnInit(): void {
    this.lookupsService.getConventionJobs()
      .then(result => {
        result.forEach((job) => {
          this.conventionJobs.push({ label: job.jobTitle, value: job });
        });
      });
    this.lookupsService.getConventionJobRoles()
      .then(result => {
        result.forEach((role) => {
          this.conventionJobRoles.push({ label: role.roleTitle, value: role });
        });
      });
    this.lookupsService.getConventionJobSubRoles()
      .then(result => {
        this.conventionJobSubRoles = result;
      });
  }

  show(weekend: Weekend, volunteer: Volunteer): void {
    this.weekend = weekend;
    this.selectedVolunteer = volunteer;

    if (!this.weekend || this.weekend === null) {
      this.messageService.add(
        {
          severity: 'warn',
          summary: 'Cannot Add Assignment',
          detail: `Please add ${volunteer.congregation.congregationName} to a Facility Weekend before assigning roles to volunteers in that congregation`
        });
      return;
    }

    this.weekendsService.getWeekendRoleAssignmentForVolunteerWeekend(weekend.weekendId, volunteer.volunteerId)
      .then(data => {
        if (data) {
          this.assignment = data;
          this.saveButtonText = 'Save Assignment';
          const job = this.conventionJobs.filter(job => job.value.conventionJobId === this.assignment.conventionJobId);
          if (job != null && job.length > 0) {
            this.selectedJob = job[0].value;
          }
          const role = this.conventionJobRoles.filter(role => role.value.conventionJobRoleId === this.assignment.conventionJobRoleId);
          if (role != null && role.length > 0) {
            this.selectedJobRole = role[0].value;
          }
        } else {
          this.saveButtonText = 'Add Assignment';
          this.assignment = new WeekendRoleAssignment();
          this.assignment.volunteerId = this.selectedVolunteer.volunteerId;
          this.assignment.weekendId = this.weekend.weekendId;
        }
      });

    this.showAddRoleAssignmentDialog = true;
  }

  populateSubRoles(): void {
    this.filteredJobSubRoles = [];
    this.filteredJobSubRoles.push({ label: '', value: null });
    this.conventionJobSubRoles.map(role => {
      if (role.conventionJobId === this.selectedJob.conventionJobId) {
        this.filteredJobSubRoles.push({ label: role.subRoleTitle, value: role });
      }
    });
  }

  searchVolunteers(event): void {
    // todo:
  }

  save(): void {
    console.debug('Saving role assignment');

    this.assignment.conventionJobId = this.selectedJob.conventionJobId;
    this.assignment.conventionJobRoleId = this.selectedJobRole.conventionJobRoleId;
    if (this.selectedJobSubRole) {
      this.assignment.conventionJobSubRoleId = this.selectedJobSubRole.conventionJobSubRoleId;
    }
    this.assignment.volunteerId = this.selectedVolunteer.volunteerId;
    this.assignment.weekendId = this.weekend.weekendId;
    this.weekendsService.addRoleAssignment(this.assignment)
      .then(() => {
        this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Role assignment added to weekend' });
        this.showAddRoleAssignmentDialog = false;
        if (this.onAssignmentSaved){
          this.onAssignmentSaved.emit();
        }
      });
  }

  cancel(): void {
    this.selectedVolunteer = null;
    this.weekend = null;
    this.showAddRoleAssignmentDialog = false;
  }
}
