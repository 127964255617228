import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-circuits',
  templateUrl: './page-circuits.component.html',
  styleUrls: ['./page-circuits.component.scss']
})
export class PageCircuitsComponent implements OnInit {
  ngOnInit(): void {
  }
}
